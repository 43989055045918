var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "tariff-outsourcer" }, [
    _c("div", { staticClass: "tariff-outsourcer__title" }, [
      _vm._v("\n    " + _vm._s(_vm.organization.outsourcer) + "\n    "),
      _c(
        "div",
        { staticClass: "outsourcer-details" },
        _vm._l(_vm.infoOrg, function (item, name, index) {
          return _c(
            "div",
            { key: name + index, staticClass: "outsourcer-details__block" },
            [
              _c("p", { staticClass: "outsourcer-details__block__header" }, [
                _vm._v(_vm._s(item)),
              ]),
              _vm._v(" "),
              name !== "email"
                ? _c("p", { staticClass: "outsourcer-details__block__body" }, [
                    _vm._v(_vm._s(_vm.organization.adinfo[name])),
                  ])
                : _c(
                    "a",
                    {
                      staticClass: "outsourcer-details__block__body email",
                      attrs: {
                        href: `mailto:${_vm.organization.adinfo[name]}`,
                      },
                    },
                    [_vm._v(_vm._s(_vm.organization.adinfo[name]))]
                  ),
            ]
          )
        }),
        0
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "tariff-outsourcer__members" },
      [
        _vm.isLoading
          ? _c("div", { staticClass: "contract-history__loader-overlay" }, [
              _c("img", {
                staticClass: "contract-history__loader-icon",
                attrs: {
                  src: require("@icons/bubble-loader.svg"),
                  width: "40",
                },
              }),
            ])
          : _vm._l(_vm.responsibles, function (responsible) {
              return _c(
                "div",
                {
                  key: responsible.user_id,
                  staticClass: "tariff-outsourcer__member outsourcer-member",
                },
                [
                  _c("div", { staticClass: "outsourcer-member__avatar" }, [
                    responsible.avatar
                      ? _c("img", {
                          staticClass: "outsourcer-member__photo",
                          attrs: {
                            src: responsible.avatar.thumb.url,
                            alt: responsible.avatar.name,
                          },
                        })
                      : _c(
                          "div",
                          { staticClass: "outsourcer-member__initials" },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm._f("nameInitials")(responsible.name)
                                ) +
                                "\n          "
                            ),
                          ]
                        ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "outsourcer-member__name" }, [
                    _vm._v(_vm._s(responsible.name)),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "outsourcer-member__role" }, [
                    _vm._v(_vm._s(responsible.type)),
                  ]),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "outsourcer-member__email",
                      attrs: { href: `mailto:${responsible.email}` },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(responsible.email) +
                          "\n        "
                      ),
                    ]
                  ),
                ]
              )
            }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }