<template>
  <div v-if="downloads.length != 0" class="reference-downloads">
    <div class="reference-downloads__group">
      <div class="reference-downloads__head-of-group">
        <img
          src="@icons/reference/windows.svg"
          class="reference-downloads__os-logo"
        />
        Windows
      </div>

      <div class="reference-downloads__links">
        <template v-for="download in downloads">
          <DownloadsLink
            v-if="download.type == 'windows'"
            :key="download.url_download"
            v-bind="download"
          />
        </template>
      </div>

      <div class="reference-downloads__head-of-group">
        <img
          src="@icons/reference/windows10.svg"
          class="reference-downloads__os-logo"
        />
        Приложение для Windows 10
      </div>

      <div class="reference-downloads__links">
        <template v-for="download in downloads">
          <DownloadsLink
            v-if="download.type == 'wp'"
            :key="download.url_download"
            v-bind="download"
          />
        </template>
      </div>

      <div class="reference-downloads__head-of-group">
        <img
          src="@icons/reference/linux.svg"
          class="reference-downloads__os-logo"
        />
        Linux
      </div>

      <div class="reference-downloads__links">
        <template v-for="download in downloads">
          <DownloadsLink
            v-if="download.type == 'linux'"
            :key="download.url_download"
            v-bind="download"
          />
        </template>
      </div>
    </div>

    <div class="reference-downloads__group">
      <div class="reference-downloads__head-of-group">
        <img
          src="@icons/reference/ios.svg"
          class="reference-downloads__os-logo"
        />
        Mac OS, IOS
      </div>

      <div class="reference-downloads__links">
        <template v-for="download in downloads">
          <DownloadsLink
            v-if="download.type == 'ios' || download.type == 'mac_os'"
            :key="download.url_download"
            v-bind="download"
          />
        </template>
      </div>

      <div class="reference-downloads__head-of-group">
        <img
          src="@icons/reference/android.svg"
          class="reference-downloads__os-logo"
        />
        Android
      </div>

      <div class="reference-downloads__links">
        <template v-for="download in downloads">
          <DownloadsLink
            v-if="download.type == 'android'"
            :key="download.url_download"
            v-bind="download"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import DownloadsLink from "../../components/Reference/DownloadsLink.vue";

export default {
  name: "ReferenceDownloads",

  components: {
    DownloadsLink,
  },

  computed: {
    ...mapGetters({
      downloads: "Info/getDownloads",
    }),
  },
};
</script>

<style scoped lang="scss">
.reference-downloads {
  display: flex;
  min-height: 500px;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 24px;

  &__group {
    width: 46%;
  }

  &__head-of-group {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    font-size: 22px;
    font-weight: 700;
  }

  &__os-logo {
    margin-right: 16px;
  }

  &__links {
    margin-bottom: 56px;
  }

  @media (max-width: 960px) {
    &__group {
      width: 100%;
    }
  }
}
</style>
