import axiosClient from "@/services/axiosClient";
import {AuthService} from "@/services/auth.service";

export const ThreadService = {
    async fetchGetThread({orgId, topicId, archived, id, before, amount}) {
        const url = `/organization/${orgId}/feed/thread`;

        const params = {
            topic: topicId,
            archived: archived || false,
            id: id,
            before,
            amount,
        };

        const response = await axiosClient.get(url, { params });

        return response.data
    },

    async fetchUpdateEvaluation(data) {
        const url = `/organization/${data.orgId}/feed/thread/close`;
        const csrf = await AuthService.fetchCSRF();

        const response = await axiosClient.put(
            url,
            {...data},
            {
                headers: {
                    "x-csrf-token": csrf,
                },
            }
        )

        return response.data
    }
}
