import { UserService } from "../../services";

import Notifications from "./settings-notifications";

function resetState() {
  return {
    avatar_is_loading: false,
    text_field_is_loading: false,
    password_is_updating: false,

    push_event_types_are_loading: false,

    push_message: false,
    push_journal: false,
    push_contract: false,
    // push_offer: false, // waiting for backend to supporting this settings
    // push_avatar: false, // waiting for backend to supporting this settings
    // push_rest: false, // waiting for backend to supporting this settings
  };
}

export default {
  namespaced: true,

  state: resetState(),

  getters: {
    isAvatarLoading({ avatar_is_loading }) {
      return avatar_is_loading;
    },

    isTextFieldLoading({ text_field_is_loading }) {
      return text_field_is_loading;
    },

    isPasswordUpdating({ password_is_updating }) {
      return password_is_updating;
    },

    arePushEventTypesLoading({ push_event_types_are_loading }) {
      return push_event_types_are_loading;
    },

    getPushSettingFor(state) {
      return function name(name) {
        return state[`push_${name}`];
      };
    },
  },

  mutations: {
    SET_AVATAR_IS_LOADING(state, value) {
      state.avatar_is_loading = value;
    },

    SET_TEXT_FIELD_IS_LOADING(state, value) {
      state.text_field_is_loading = value;
    },

    SET_PASSWORD_IS_UPDATING(state, value) {
      state.password_is_updating = value;
    },

    SET_PUSH_MESSAGE(state, value) {
      state.push_message = value;
    },

    SET_PUSH_JOURNAL(state, value) {
      state.push_journal = value;
    },

    SET_PUSH_CONTRACT(state, value) {
      state.push_contract = value;
    },

    // SET_PUSH_OFFER(state, value) { // waiting for backend to supporting this settings
    //   state.push_offer = value;
    // },

    // SET_PUSH_AVATAR(state, value) { // waiting for backend to supporting this settings
    //   state.push_avatar = value;
    // },

    // SET_PUSH_REST(state, value) { // waiting for backend to supporting this settings
    //   state.push_rest = value;
    // },

    SET_PUSH_EVENT_TYPES_ARE_LOADING(state, value) {
      state.push_event_types_are_loading = value;
    },

    RESET_STATE(state) {
      Object.assign(state, resetState());
    },
  },

  actions: {
    async loadPushEventTypes({ commit, dispatch }) {
      commit("SET_PUSH_EVENT_TYPES_ARE_LOADING", true);

      let settings;

      try {
        settings = await UserService.fetchPushSettings();
      } finally {
        commit("SET_PUSH_EVENT_TYPES_ARE_LOADING", false);
      }

      dispatch("initPushSettings", settings);
    },

    async changeField({ commit, dispatch }, { fieldName, value }) {
      commit("SET_TEXT_FIELD_IS_LOADING", true);

      try {
        await UserService.changeUserSettingsField(fieldName, value);
      } catch (error) {
        if (error.response?.data?.error) {
          throw new Error(error.response.data.error);
        } else {
          throw new Error(`Ошибка при загрузке поля. ${fieldName}`);
        }
      } finally {
        commit("SET_TEXT_FIELD_IS_LOADING", false);
      }

      dispatch("changeTextField", { fieldName, value });
    },

    async changeAvatar({ commit, dispatch }, file) {
      let userData;

      commit("SET_AVATAR_IS_LOADING", true);

      try {
        userData = await UserService.changeUserSettingsField("avatar", file);
      } catch {
        throw new Error("Ошибка при загрузке аватарки.");
      } finally {
        commit("SET_AVATAR_IS_LOADING", false);
      }

      dispatch("changeAvatarURL", userData?.avatar?.thumb?.url);
    },

    changeAvatarURL({ commit }, url) {
      commit("User/SET_AVATAR_URL", url, { root: true });
    },

    changeTextField({ commit }, { fieldName, value }) {
      switch (fieldName) {
        case "name":
          commit("User/SET_NAME", value, { root: true });
          break;

        case "position":
          commit("User/SET_POSITION", value, { root: true });
          break;

        case "email":
          commit("User/SET_EMAIL", value, { root: true });
          break;

        case "phone":
          commit("User/SET_PHONE", value, { root: true });
          break;

        default:
          break;
      }
    },

    async changePassword({ commit }, { currentPassword, newPassword }) {
      commit("SET_PASSWORD_IS_UPDATING", true);

      try {
        await UserService.changePassword({ currentPassword, newPassword });
      } finally {
        commit("SET_PASSWORD_IS_UPDATING", false);
      }
    },

    initPushSettings({ commit }, settings) {
      for (let setting in settings) {
        commit(`SET_PUSH_${setting.toUpperCase()}`, settings[setting]);
      }
    },

    async togglePushSetting({ commit, getters }, key) {
      commit("SET_PUSH_EVENT_TYPES_ARE_LOADING", true);

      const commitName = `SET_PUSH_${key.toUpperCase()}`;

      const newValue = !getters["getPushSettingFor"](key);

      try {
        await UserService.setPushSetting(key, newValue);

        commit(commitName, newValue);
      } finally {
        commit("SET_PUSH_EVENT_TYPES_ARE_LOADING", false);
      }
    },

    resetState({ commit, dispatch }) {
      dispatch("Notifications/resetState");

      commit("RESET_STATE");
    },
  },

  modules: {
    Notifications,
  },
};
