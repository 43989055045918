var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tab" },
    [
      _vm.isLoading
        ? _c("div", { staticClass: "tab__loader-overlay" }, [
            _c("img", {
              attrs: { src: require("@icons/bubble-loader.svg"), width: "48" },
            }),
          ])
        : _vm.isError
        ? _c("div", [_vm._v("Error...")])
        : [
            _c(
              "div",
              { staticClass: "tab__top" },
              [
                _c("AddNewAgentBlock", {
                  staticClass: "tab__add-agent",
                  attrs: { agents: _vm.agents, rights: _vm.rights },
                }),
                _vm._v(" "),
                _vm.agents.length != 0
                  ? _c("AgentsSlider", {
                      staticClass: "tab__slider",
                      attrs: {
                        agents: _vm.agents,
                        "is-at-start": _vm.sliderIsAtStart,
                        "is-at-end": _vm.sliderIsAtEnd,
                      },
                      on: {
                        "slide-prev": _vm.slidePrev,
                        "slide-next": _vm.slideNext,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "button-manual" },
              [
                _c("span", { staticClass: "button-manual__title" }, [
                  _vm._v("Уполномоченные представители"),
                ]),
                _vm._v(" "),
                _c("FileDownloadIcon", {
                  staticClass: "button-manual__download",
                  attrs: {
                    "contract-id": _vm.rights.contract_id,
                    "doc-id": _vm.rights.doc_id,
                    "form-id": "agents",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("AgentsRightsTable", {
              attrs: {
                title: "Сфера полномочий представителя Заказчика",
                agents: _vm.agents,
                rights: _vm.rights.authority,
              },
            }),
            _vm._v(" "),
            _vm.showAllSections
              ? [
                  _c("AgentsRightsTable", {
                    attrs: {
                      title: "Права пользователей в приложении БП",
                      agents: _vm.agents,
                      rights: _vm.rights.accounting.main,
                    },
                  }),
                  _vm._v(" "),
                  _c("AgentsRightsTable", {
                    attrs: {
                      title:
                        "Дополнительные профили пользователей в приложении БП",
                      agents: _vm.agents,
                      rights: _vm.rights.accounting.extra,
                    },
                  }),
                  _vm._v(" "),
                  _c("AgentsRightsTable", {
                    attrs: {
                      title: "Права пользователей в приложении ЗиУП",
                      agents: _vm.agents,
                      rights: _vm.rights.hrm,
                    },
                  }),
                  _vm._v(" "),
                  _c("AgentsRightsTable", {
                    attrs: {
                      title: "Права пользователей в приложении УНФ",
                      agents: _vm.agents,
                      rights: _vm.rights.unf,
                    },
                  }),
                ]
              : _vm._e(),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }