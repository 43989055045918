<template>
  <form class="form">
    <CabTextField
      v-model="currentPassword"
      class="form__input"
      autocomplete="new-password"
      label="Текущий пароль"
      name="current-password-in-settings"
      placeholder=" Введите текущий пароль"
      :type="currentPasswordType"
      :error-message="currentPasswordErrorMessage"
      @input="resetErrors"
    >
      <template #appendedIcon>
        <EyeIcon
          class="login-form__password-icon"
          :is-striked="currentPasswordType == 'password'"
          @click.native="onIconClick('currentPasswordType')"
        />
      </template>
    </CabTextField>

    <CabTextField
      v-model="newPassword"
      class="form__input"
      autocomplete="new-password"
      label="Новый пароль"
      name="new-password-in-settings"
      placeholder=" Введите новый пароль"
      :type="newPasswordType"
      :error-message="newPasswordErrorMessage"
      @input="resetErrors"
    >
      <template #appendedIcon>
        <EyeIcon
          class="login-form__password-icon"
          :is-striked="newPasswordType == 'password'"
          @click.native="onIconClick('newPasswordType')"
        />
      </template>
    </CabTextField>

    <CabTextField
      v-model="repeatNewPassword"
      class="form__input"
      autocomplete="new-password"
      label="Подтверждение пароля"
      name="new-password-in-settings-repeat"
      placeholder=" Повторите новый пароль"
      :type="newPasswordType"
      :error-message="repeatNewPasswordErrorMessage"
      @input="resetErrors"
    >
      <template #appendedIcon>
        <EyeIcon
          class="login-form__password-icon"
          :is-striked="newPasswordType == 'password'"
          @click.native="onIconClick('newPasswordType')"
        />
      </template>
    </CabTextField>

    <div :class="classesForButton" @click="savePassword">
      Сохранить
      <FadeTransition>
        <div v-if="passwordIsUpdating" class="form__loader">
          <img src="@icons/bubble-loader.svg" width="24" />
        </div>
      </FadeTransition>
    </div>
  </form>
</template>

<script>
import { required, minLength } from "vuelidate/dist/validators.min";

import { mapGetters, mapActions } from "vuex";

import CabTextField from "../common/CabTextField.vue";
import EyeIcon from "../../components/common/EyeIcon.vue";
import FadeTransition from "../../components/transitions/Fade.vue";

export default {
  name: "UserSettingsPasswordForm",

  components: {
    CabTextField,
    EyeIcon,
    FadeTransition,
  },

  data() {
    return {
      currentPassword: "",
      newPassword: "",
      repeatNewPassword: "",

      currentPasswordType: "password",
      newPasswordType: "password",

      currentPasswordErrorMessage: "",
      newPasswordErrorMessage: "",
      repeatNewPasswordErrorMessage: "",
    };
  },

  validations: {
    currentPassword: {
      required,
    },

    newPassword: {
      required,
      minLength: minLength(7),
    },
  },

  computed: {
    ...mapGetters("User/Settings", {
      passwordIsUpdating: "isPasswordUpdating",
    }),

    classesForButton() {
      return {
        form__button: true,
        "form__button--blocked": this.$v.$invalid,
      };
    },
  },

  methods: {
    ...mapActions({
      changePassword: "User/Settings/changePassword",
    }),

    onIconClick(passwordType) {
      this[passwordType] = this[passwordType] == "text" ? "password" : "text";
    },

    async savePassword() {
      const currentPassword = this.currentPassword;
      const newPassword = this.newPassword;
      const repeatNewPassword = this.repeatNewPassword;

      if (newPassword != repeatNewPassword) {
        this.newPasswordErrorMessage = "Пароли не совпадают";
        this.repeatNewPasswordErrorMessage = "Пароли не совпадают";
        return;
      }

      if (currentPassword == newPassword) {
        this.currentPasswordErrorMessage = "Пароли совпадают";
        this.newPasswordErrorMessage = "Пароли совпадают";
        return;
      }

      try {
        await this.changePassword({ currentPassword, newPassword });

        this.resetFormInputs();

        this.$notify({
          text: "Вы успешно изменили пароль",
        });
      } catch (error) {
        if (error.cause.actual)
          this.currentPasswordErrorMessage = error.cause.actual;

        if (error.cause.new) this.newPasswordErrorMessage = error.cause.new;

        if (error.cause.error) {
          this.$notify({
            type: "error",
            text: error.cause.error,
            duration: -1,
          });
        }
      }
    },

    resetFormInputs() {
      this.currentPassword = "";
      this.newPassword = "";
      this.repeatNewPassword = "";
    },

    resetErrors() {
      this.currentPasswordErrorMessage = "";
      this.newPasswordErrorMessage = "";
      this.repeatNewPasswordErrorMessage = "";
    },
  },
};
</script>

<style scoped lang="scss">
.form {
  &__input {
    width: 260px;
    margin-bottom: 32px;
  }

  &__button {
    @include gradient-button;

    position: relative;
    width: 140px;
    height: 40px;
  }

  &__loader {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid $yellow-400;
    border-radius: 6px;
    background: white;
  }
}
</style>
