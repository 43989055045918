var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "cab-text-field" },
    [
      _c(
        "div",
        {
          staticClass: "cab-text-field__wrapper",
          class: _vm.classesForWrapper,
        },
        [
          _c(
            "label",
            {
              staticClass: "cab-text-field__label",
              class: _vm.classesForLabel,
              attrs: { for: _vm.name },
            },
            [_vm._v("\n      " + _vm._s(_vm.label) + "\n    ")]
          ),
          _vm._v(" "),
          _c(
            "CabInput",
            _vm._b(
              {
                staticClass: "cab-text-field__input",
                attrs: {
                  placeholder: _vm.$attrs.placeholder,
                  autocomplete: _vm.$attrs.autocomplete,
                },
                on: {
                  focus: _vm.onFocus,
                  blur: _vm.onBlur,
                  input: _vm.onInput,
                },
              },
              "CabInput",
              _vm.$props,
              false
            )
          ),
          _vm._v(" "),
          _vm.$slots.appendedIcon
            ? _c(
                "div",
                { staticClass: "cab-text-field__icon" },
                [_vm._t("appendedIcon")],
                2
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("ExpandTransition", [
        _vm.errorMessage
          ? _c("div", {
              staticClass: "cab-text-field__error-message",
              domProps: { textContent: _vm._s(_vm.errorMessage) },
            })
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("ExpandTransition", [
        _vm.hintMessage
          ? _c("div", {
              staticClass: "cab-text-field__hint-message",
              domProps: { textContent: _vm._s(_vm.hintMessage) },
            })
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }