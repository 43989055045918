<template>
  <CabTooltip placement="bottom-end" :interactive="true" :timeout="0">
    <template #activator>
      <div class="history-action">
        <AppIcon name="clock-back" class="history-icon" /> История действий
      </div>
    </template>
    <div class="root__tooltip history-tooltip">
      <div class="history-list scroll">
        <a
          v-for="historyItem in history"
          :key="historyItem.id"
          :href="historyUrl + historyItem.id"
          class="history-item-link"
          download
        >
          <div class="history-item-left">
            <div class="history-item-name">{{ historyItem.name }}</div>
            <div class="history-item-date">
              {{ historyItem.date | formatDate("dd.MM.yyyy HH:mm:ss") }}
            </div>
          </div>
          <AppIcon name="download" class="history-item-download" />
        </a>
      </div>
    </div>
  </CabTooltip>
</template>

<script>
import { mapGetters } from "vuex";
import { API_URL } from "@/utils/constants";
import AppIcon from "@/components/common/Icon.vue";
import CabTooltip from "@/components/common/CabTooltip.vue";

export default {
  name: "QuestionnaireHistory",

  components: {
    AppIcon,
    CabTooltip,
  },

  props: {
    history: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    ...mapGetters({ orgId: "Organizations/ActiveOne/getId" }),

    historyUrl() {
      return `${API_URL}/organization/${this.orgId}/questionnaire/history/`;
    },
  },
};
</script>

<style scoped lang="scss">
.history-action {
  display: inline-flex;
  align-items: center;
  color: #848484;
  cursor: pointer;
  font-size: 16px;
  transition: 0.2s;

  &:hover {
    color: #6b6b6b;
  }
}

.history-icon {
  margin-right: 9px;
  color: #6398e9;
}

.history-tooltip {
  width: max-content;
  max-width: 430px;
}

.history-list {
  max-height: 400px;
}

.history-item-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 3px;
  margin: 5px 0;
}

.history-item-left {
  margin-right: 20px;
}

.history-item-name {
  margin-bottom: 4px;
  color: #3072c4;
  font-size: 15px;
  line-height: 18px;
}

.history-item-date {
  color: #848484;
  font-size: 12px;
  line-height: 15px;
}

.history-item-download {
  margin-left: auto;
  color: #848484;
}

.scroll {
  overflow: auto;

  &::-webkit-scrollbar {
    width: 4px;
    height: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: #d6d6d6;
  }

  /* HACK: для safari */
  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }

  /* HACK: для safari */
  &::-webkit-scrollbar-button,
  &::-webkit-scrollbar-button:decrement,
  &::-webkit-scrollbar-button:increment {
    display: none;
  }
}
</style>
