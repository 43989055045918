<template>
  <div class="block">
    <EventTypesSelection class="block__event-type-selection" />

    <div ref="timezoneSelect" class="block__timezone-select n-timezone-select">
      <span class="n-timezone-select__title"> Часовой пояс </span>

      <TimezoneSelect
        v-if="!timezonesInfoIsLoading"
        class="n-timezone-select__select"
        :timezone-city-id="timezoneCityId"
        @select="onTimezoneSelect"
      />
    </div>

    <div class="block__time-range time-range">
      <div class="time-range__text">Уведомлять</div>

      <div class="time-range__inputs">
        <span>с</span>
        <!-- ignore-timezone -->
        <SelectTime v-model="startDate" class="time-range__select" />
        <span>по</span>
        <!-- ignore-timezone -->
        <SelectTime v-model="endDate" class="time-range__select" />
      </div>
    </div>

    <div class="block__week-days week-days">
      <div class="week-days__text">Дни недели</div>

      <div class="week-days__days">
        <MultipleCheckbox
          v-for="(weekday, key) in weekdays"
          :key="key"
          v-model="selectedWeekdays"
          class="week-days__day"
          :value="key"
        >
          {{ weekday }}
        </MultipleCheckbox>
      </div>
    </div>

    <div :class="classesForButton" @click="update">
      Сохранить
      <FadeTransition>
        <div v-if="formIsLoading" class="block__loader">
          <img src="@icons/bubble-loader.svg" width="24" />
        </div>
      </FadeTransition>
    </div>
  </div>
</template>

<script>
import cloneDeep from "lodash-es/cloneDeep";

import { mapGetters, mapActions } from "vuex";

import EventTypesSelection from "./EventTypesSelection.vue";
import TimezoneSelect from "./TimezoneSelect.vue";
import SelectTime from "../common/SelectTime.vue";
import MultipleCheckbox from "../common/MultipleCheckbox.vue";
import FadeTransition from "../../components/transitions/Fade.vue";

export default {
  name: "UserSettingsNotificationsBlock",

  components: {
    EventTypesSelection,
    TimezoneSelect,
    SelectTime,
    MultipleCheckbox,
    FadeTransition,
  },

  data() {
    return {
      weekdays: {
        1: "Понедельник",
        2: "Вторник",
        3: "Среда",
        4: "Четверг",
        5: "Пятница",
        6: "Суббота",
        7: "Воскресенье",
      },
    };
  },

  computed: {
    ...mapGetters("User/Settings/Notifications", {
      newSettings: "getSettingsObjectForUpdateRequest",
      currentSettings: "getCurrentSettings",
      timezoneCityId: "getTimezoneCityId",
      formIsDirty: "isDirty",
      formIsInvalid: "isInvalid",
      formIsLoading: "isLoading",
    }),

    ...mapGetters("Info", {
      timezonesInfoIsLoading: "isTimezonesInfoLoading",
    }),

    startDate: {
      set(value) {
        this.$store.commit("User/Settings/Notifications/SET_START", value);
      },

      get() {
        return this.$store.getters["User/Settings/Notifications/getStart"];
      },
    },

    endDate: {
      set(value) {
        this.$store.commit("User/Settings/Notifications/SET_END", value);
      },

      get() {
        return this.$store.getters["User/Settings/Notifications/getEnd"];
      },
    },

    selectedWeekdays: {
      set(value) {
        const weekdaysString = value.sort().join("");

        this.$store.commit(
          "User/Settings/Notifications/SET_WEEKDAYS",
          weekdaysString
        );
      },

      get() {
        return this.$store.getters["User/Settings/Notifications/getWeekdays"];
      },
    },

    classesForButton() {
      return {
        block__button: true,
        "block__button--blocked": !this.formIsDirty || !this.timezoneCityId,
      };
    },
  },

  async created() {
    await this.loadTimezonesList();
    await this.loadPushEventTypes();

    if (this.$route.params.highlightTimezoneSelect) {
      this.$refs["timezoneSelect"].scrollIntoView();
      this.highlightTimezoneSelect();
    }
  },

  methods: {
    ...mapActions("Info", ["loadTimezonesList"]),

    ...mapActions("User/Settings", ["loadPushEventTypes"]),

    ...mapActions("User/Settings/Notifications", { updateSettings: "update" }),

    async update() {
      try {
        await this.updateSettings(this.newSettings);

        this.$notify({
          text: "Настройки успешно изменены.",
        });
      } catch {
        this.$notify({
          type: "error",
          text: "Произошла ошибка. Попробуйте позже...",
        });

        this.$store.commit(
          "User/Settings/Notifications/SET_CHANGED_SETTINGS",
          cloneDeep(this.currentSettings)
        );
      }
    },

    onTimezoneSelect(timezone) {
      this.$store.commit(
        "User/Settings/Notifications/SET_TIMEZONE_CITY_ID",
        timezone.id
      );
    },

    highlightTimezoneSelect() {
      const el = this.$refs["timezoneSelect"];
      const className = "n-timezone-select--highlighted";

      el.classList.add(className);

      setTimeout(function resetHighlightClass() {
        el.classList.remove(className);
      }, 2000);
    },
  },
};
</script>

<style scoped lang="scss">
.block {
  &__event-type-selection {
    margin-bottom: 40px;
  }

  &__timezone-select {
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 24px;
  }

  &__time-range {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
  }

  &__week-days {
    margin-bottom: 24px;
  }

  &__button {
    @include gradient-button;

    position: relative;
    width: 140px;
    height: 40px;
  }

  &__loader {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid $yellow-400;
    border-radius: 6px;
    background: white;
  }
}

.n-timezone-select {
  transition: background-color $short;

  &--highlighted {
    background-color: rgba(0, 96, 255, 0.1);
    transition: background-color $short;
  }

  &__title {
    margin-right: 40px;
  }

  &__select {
    flex: 1;
  }

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: unset;

    &__title {
      margin-bottom: 16px;
    }

    &__select {
      width: 100%;
    }
  }
}

.time-range {
  display: flex;

  span {
    margin-right: 10px;
  }

  &__text {
    margin-right: 50px;
  }

  &__inputs {
    display: flex;
    align-items: center;
  }

  &__select {
    margin-right: 20px;
  }

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: unset;

    &__text {
      margin-bottom: 16px;
    }
  }
}

.week-days {
  display: flex;

  &__text {
    margin-right: 50px;
  }

  &__days {
    display: flex;
    max-height: 140px;
    flex-direction: column;
    flex-wrap: wrap;
  }

  &__day {
    margin-bottom: 12px;

    &:nth-child(1) {
      margin-right: 40px;
    }

    &:nth-child(2) {
      margin-right: 40px;
    }

    &:nth-child(3) {
      margin-right: 40px;
    }
  }

  @media (max-width: 600px) {
    flex-direction: column;

    &__text {
      margin-bottom: 16px;
    }
  }
}
</style>
