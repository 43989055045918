<template>
  <div class="tab">
    <div v-if="isLoading" class="tab__loader-overlay">
      <img src="@icons/bubble-loader.svg" width="48" />
    </div>

    <div v-else-if="isError">Error...</div>

    <template v-else>
      <div class="tab__top">
        <AddNewAgentBlock
          :agents="agents"
          :rights="rights"
          class="tab__add-agent"
        />

        <AgentsSlider
          v-if="agents.length != 0"
          :agents="agents"
          :is-at-start="sliderIsAtStart"
          :is-at-end="sliderIsAtEnd"
          class="tab__slider"
          @slide-prev="slidePrev"
          @slide-next="slideNext"
        />
      </div>

      <div class="button-manual">
        <span class="button-manual__title">Уполномоченные представители</span>
        <FileDownloadIcon
            :contract-id="rights.contract_id"
            :doc-id="rights.doc_id"
            :form-id="'agents'"
            class="button-manual__download"
        />
      </div>

      <AgentsRightsTable
        title="Сфера полномочий представителя Заказчика"
        :agents="agents"
        :rights="rights.authority"
      />

      <template v-if="showAllSections">
        <AgentsRightsTable
          title="Права пользователей в приложении БП"
          :agents="agents"
          :rights="rights.accounting.main"
        />

        <AgentsRightsTable
          title="Дополнительные профили пользователей в приложении БП"
          :agents="agents"
          :rights="rights.accounting.extra"
        />

        <AgentsRightsTable
          title="Права пользователей в приложении ЗиУП"
          :agents="agents"
          :rights="rights.hrm"
        />

        <AgentsRightsTable
          title="Права пользователей в приложении УНФ"
          :agents="agents"
          :rights="rights.unf"
        />
      </template>
    </template>
  </div>
</template>

<script>
import Vue, { computed } from "vue";

import { useQuery } from "@tanstack/vue-query";

import { mapGetters } from "vuex";
import store from "../../store";

import { InfoService } from "../../services";

import AddNewAgentBlock from "../../components/Tariff/AddNewAgentBlock.vue";
import AgentsSlider from "../../components/Tariff/AgentsSlider.vue";
import AgentsRightsTable from "../../components/Tariff/AgentsRightsTable.vue";
import FileDownloadIcon from "@/components/common/FileDownloadIcon.vue";

export default {
  name: "TariffResponsible",

  components: {
    FileDownloadIcon,
    AddNewAgentBlock,
    AgentsSlider,
    AgentsRightsTable,
  },

  inject: ["screenSize"],

  setup() {
    const orgId = computed(
      () => store.getters["Organizations/ActiveOne/getId"]
    );

    const { data, isLoading, isError } = useQuery(
      ["agents", orgId],
      () => InfoService.fetchAgentsAndTheirRights(orgId.value),
      {
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        retry: false,
        onError: () => {
          Vue.notify({
            type: "error",
            text: "Ошибка при загрузке представителей (agents)",
          });
        },
      }
    );

    return { data, isLoading, isError, orgId };
  },

  data() {
    return {
      startIndex: 0,
    };
  },

  computed: {
    agentsAmount() {
      if (this.screenSize.smallerOrEqual("xs").value) {
        return 2;
      } else if (this.screenSize.smallerOrEqual("md").value) {
        return 3;
      } else if (this.screenSize.smallerOrEqual("lg").value) {
        return 4;
      } else {
        return 5;
      }
    },

    agents() {
      return (this.data?.agents || []).slice(
        this.startIndex,
        this.startIndex + this.agentsAmount
      );
    },

    rights() {
      return this.data || {};
    },

    sliderIsAtStart() {
      return this.startIndex == 0;
    },

    sliderIsAtEnd() {
      const amount = this.agentsAmount;

      return this.startIndex + amount >= (this.data?.agents || []).length;
    },

    ...mapGetters("Organizations/ActiveOne", {
      tariff: "getContractTariff",
    }),

    showAllSections() {
      const FORBIDDEN_TARIFF_KEYS = [
        "ПрограммаИКонсультация",
        "НулеваяОтчетность",
      ];

      return !FORBIDDEN_TARIFF_KEYS.includes(this.tariff.key);
    },
  },

  watch: {
    orgId() {
      this.startIndex = 0;
    },
  },

  methods: {
    slidePrev() {
      this.startIndex--;
    },

    slideNext() {
      this.startIndex++;
    },
  },
};
</script>

<style scoped lang="scss">
.tab {
  height: 100%;

  &__top {
    display: flex;
    align-items: center;
  }

  &__add-agent {
    width: 360px;
    padding-left: 28px;
  }

  &__slider {
    width: calc(100% - 360px);
    padding: 48px;
  }

  &__loader-overlay {
    display: flex;
    width: 100%;
    height: 300px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  @media (min-width: 1265px) and (max-width: 1904px) {
    // linear equation:
    // 1904*x1 + x2 = 360
    // 1265*x1 + x2 = 300
    &__add-agent {
      width: calc(10vw + 180px);
    }

    &__slider {
      width: calc(100% - 10vw - 180px);
    }
  }

  @media (min-width: 961px) and (max-width: 1264px) {
    // linear equation:
    // 1264*x1 + x2 = 360
    // 961*x1 + x2 = 300
    &__add-agent {
      width: calc(20vw + 110px);
    }

    &__slider {
      width: calc(100% - 20vw - 110px);
    }
  }

  @media (max-width: 960px) {
    &__top {
      flex-direction: column;
    }

    &__add-agent {
      width: 100%;
      margin-top: 20px;
    }

    &__slider {
      width: 100%;
      padding: 32px 24px;
    }
  }

  @media (max-width: 600px) {
    &__slider {
      padding: 24px 12px;
    }

    &__add-agent {
      width: 100%;
      padding: 0 10px;
    }
  }
}

.button-manual {
  width: 400px;
  padding: 15px 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, .15);
  transition: .3s;

  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-left: 20px;
  margin-bottom: 20px;

  &:hover {
    background-color: #f6f6f6;
  }

  &__title {
    color: #1c1c1c;
    font-size: 18px;
  }

  &__download {
    width: 16px;
    margin-left: 5px;
    color: #848484;

    &:hover {
      color: #6b6b6b;
    }
  }
}
</style>
