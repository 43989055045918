import property from "lodash-es/property";
import zipObject from "lodash-es/zipObject";
import intersection from "lodash-es/intersection";

import { nanoid } from "nanoid";

import { FeedService } from "../../services";
import { loadTopic } from "@/utils";

let orgTopicsList = [];

function resetState() {
  return {
    _update: undefined,
  };
}

export default {
  namespaced: true,

  state: resetState(),

  getters: {
    topicsList(state) {
      state._update;

      return JSON.parse(localStorage.getItem("cab-topics-list")) || [];
    },

    orgTopicsList(state) {
      state._update;

      return orgTopicsList;
    },

    availableTopicsIdsForSelectedOrg(_, getters) {
      const allTopicsIds = getters["topicsList"]?.map(property("id")) || [];
      const orgTopicsIds = getters["orgTopicsList"]?.map(property("id")) || [];

      return intersection(allTopicsIds, orgTopicsIds);
    },

    idsToCodesMap(state, getters) {
      const topicsIds = getters["topicsList"].map(property("id"));
      const topicsCodes = getters["topicsList"].map(property("code"));

      return zipObject(topicsIds, topicsCodes);
    },

    idsToNamesMap(state, getters) {
      const topicsIds = getters["topicsList"].map(property("id"));
      const topicsNames = getters["topicsList"].map(property("name"));

      return zipObject(topicsIds, topicsNames);
    },

    idsToIconsMap(state, getters) {
      const topicsIds = getters["topicsList"].map(property("id"));
      const topicsNames = getters["topicsList"].map(property("icon"));

      return zipObject(topicsIds, topicsNames);
    },

    idsToActiveMap(state, getters) {
      const topicsIds = getters["topicsList"].map(property("id"));
      const topicsActives = getters["topicsList"].map(property("active"));

      return zipObject(topicsIds, topicsActives);
    },

    namesToIdsMap(state, getters) {
      const topicsIds = getters["topicsList"].map(property("id"));
      const topicsNames = getters["topicsList"].map(property("name"));

      return zipObject(topicsNames, topicsIds);
    },
  },

  mutations: {
    SET_TOPICS_LIST(_, topics) {
      localStorage.setItem("cab-topics-list", JSON.stringify(topics));
    },

    SET_ORG_TOPICS_LIST(_, topics) {
      orgTopicsList = topics;
    },

    UPDATE(state) {
      state._update = nanoid();
    },

    RESET_STATE(state) {
      localStorage.removeItem("cab-topics-list");
      orgTopicsList = [];

      Object.assign(state, resetState());
    },
  },

  actions: {
    setTopicsList({ commit }, topics) {
      commit("SET_TOPICS_LIST", topics);
      commit("UPDATE");
    },

    async loadOrgTopicsList({ commit, rootGetters }, { orgId }) {
      let org = rootGetters["Organizations/ActiveOne/organization"];

      let topics = await loadTopic(org, orgId);

      commit("SET_ORG_TOPICS_LIST", topics);
      commit("UPDATE");
    },

    resetState({ commit }) {
      commit("RESET_STATE");
    },
  },
};
