import { getInitials } from "../../utils";
import { AuthService } from "../../services";

import Settings from "./user-settings";

function resetState() {
  return {
    user_id: "",
    side: "",
    name: "",
    position: "",
    email: "",
    phone: "",
    avatar_url: "",
    feed: {},
  };
}

export default {
  namespaced: true,

  state: resetState(),

  getters: {
    getUserId(state) {
      return state.user_id;
    },

    getName(state) {
      return state.name;
    },

    getPosition(state) {
      return state.position;
    },

    getEmail(state) {
      return state.email;
    },

    getPhone(state) {
      return state.phone;
    },

    getAvatarURL(state) {
      return state.avatar_url;
    },

    getFeed(state) {
      return state.feed;
    },

    getInitials(state, getters) {
      return getInitials(getters["getName"]);
    },

    buildAuthorForMessage(state) {
      return {
        type: "user",
        id: state.user_id,
        name: state.name,
      };
    },
  },

  mutations: {
    SET_USER(state, user) {
      state.user = user;
    },

    SET_USER_ID(state, userId) {
      state.user_id = userId;
    },

    SET_SIDE(state, side) {
      state.side = side;
    },

    SET_NAME(state, name) {
      state.name = name;
    },

    SET_POSITION(state, position) {
      state.position = position;
    },

    SET_AVATAR_URL(state, url) {
      state.avatar_url = url;
    },

    SET_EMAIL(state, email) {
      state.email = email;
    },

    SET_PHONE(state, phone) {
      state.phone = phone;
    },

    SET_FEED(state, payload) {
      state.feed = payload;
    },

    RESET_STATE(state) {
      Object.assign(state, resetState());
    },
  },

  actions: {
    init({ commit }, user) {
      commit("SET_USER_ID", user.user_id);
      commit("SET_SIDE", user.side);
      commit("SET_NAME", user.name);
      commit("SET_POSITION", user.position);
      commit("SET_EMAIL", user.email);
      commit("SET_PHONE", user.phone);
      commit("SET_AVATAR_URL", user.avatar?.thumb?.url);
      commit("SET_FEED", user.feed);
    },

    resetState({ commit, dispatch }) {
      dispatch("Settings/resetState");

      commit("RESET_STATE");
    },
  },

  modules: {
    Settings,
  },
};
