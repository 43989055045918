var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "reference-faq" },
    [
      _c("div", { staticClass: "reference-faq__title" }, [
        _vm._v(
          "\n    Ответы на часто встречающиеся вопросы пользователей о работе в приложениях\n    «1С» через тонкий клиент\n  "
        ),
      ]),
      _vm._v(" "),
      _vm._l(_vm.questions, function (question, index) {
        return _c(
          "div",
          { key: question.question, staticClass: "expand-panel" },
          [
            _c(
              "div",
              {
                staticClass: "expand-panel__header",
                class: {
                  "expand-panel__header--active": _vm.opened.includes(index),
                },
                on: {
                  click: function ($event) {
                    return _vm.toggle(index)
                  },
                },
              },
              [
                _vm._v("\n      " + _vm._s(question.question) + "\n      "),
                _c("img", {
                  attrs: { src: require("@icons/chevron-arrow-down.svg") },
                }),
              ]
            ),
            _vm._v(" "),
            _c("ExpandTransition", [
              _vm.opened.includes(index)
                ? _c("div", {
                    staticClass: "expand-panel__content",
                    domProps: { innerHTML: _vm._s(question.answer) },
                  })
                : _vm._e(),
            ]),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }