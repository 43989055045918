var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "button",
      class: _vm.classesForButton,
      on: {
        click: function ($event) {
          return _vm.$emit("click")
        },
      },
    },
    [
      _vm._t("default"),
      _vm._v(" "),
      _c("FadeTransition", [
        _vm.isLoading
          ? _c("div", { staticClass: "button__loader" }, [
              _c("img", {
                attrs: {
                  src: require("@icons/bubble-loader.svg"),
                  width: "24",
                },
              }),
            ])
          : _vm._e(),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }