<template>
  <transition name="expand" @enter="onEnter" @leave="onLeave">
    <slot></slot>
  </transition>
</template>

<script>
export default {
  name: "ExpandTransition",

  methods: {
    onEnter(element) {
      const { width } = getComputedStyle(element);

      element.style.width = width;
      element.style.position = "absolute";
      element.style.visibility = "hidden";
      element.style.height = "auto";

      const { height } = getComputedStyle(element);

      element.style.width = null;
      element.style.position = null;
      element.style.visibility = null;
      element.style.height = 0;

      // Force repaint to make sure the
      // animation is triggered correctly.
      getComputedStyle(element).height;

      requestAnimationFrame(() => {
        element.style.height = height;
      });
    },

    onLeave(element) {
      element.style.height = 0;
    },
  },
};
</script>

<style scoped lang="scss">
.expand-enter-active,
.expand-leave-active {
  overflow: hidden;
  transition: height $shortest ease-in-out;
}
</style>
