var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "cab-textarea" },
    [
      _c(
        "div",
        { staticClass: "cab-textarea__wrapper", class: _vm.classesForWrapper },
        [
          _c("textarea", {
            ref: "textarea",
            staticClass: "cab-textarea__textarea",
            class: _vm.classesForTextarea,
            style: { maxHeight: _vm.maxHeight },
            attrs: {
              id: _vm.name,
              name: _vm.name,
              placeholder: _vm.$attrs.placeholder,
              rows: _vm.$attrs.rows,
              maxlength: _vm.$attrs.maxlength,
            },
            domProps: { value: _vm.value },
            on: {
              input: function ($event) {
                return _vm.onInput($event.target.value)
              },
              focus: _vm.onFocus,
              blur: _vm.onBlur,
            },
          }),
          _vm._v(" "),
          _c(
            "label",
            { staticClass: "cab-textarea__label", attrs: { for: _vm.name } },
            [_vm._v("\n      " + _vm._s(_vm.label) + "\n    ")]
          ),
          _vm._v(" "),
          _vm.clearButton && _vm.value
            ? _c("AppIcon", {
                staticClass: "cab-textarea__clear",
                attrs: { name: "close" },
                nativeOn: {
                  click: function ($event) {
                    return _vm.clear.apply(null, arguments)
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("ExpandTransition", [
        _vm.errorMessage
          ? _c("div", {
              staticClass: "cab-textarea__error-message",
              domProps: { textContent: _vm._s(_vm.errorMessage) },
            })
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }