import { nanoid } from "nanoid";

import OpsPanel from "./ops-panel";

function resetState() {
  return {
    _update: undefined,
  };
}

export default {
  namespaced: true,

  state: resetState(),

  mutations: {
    UPDATE(state) {
      state._update = nanoid();
    },

    RESET_STATE(state) {
      Object.assign(state, resetState());
    },
  },

  actions: {
    resetState({ commit, dispatch }) {
      dispatch("OpsPanel/resetState");

      commit("RESET_STATE");
    },
  },

  modules: {
    OpsPanel,
  },
};
