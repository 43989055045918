function resetState() {
  return {
    // TODO: save year and month between page reloads
    year: new Date().getFullYear(),
    month: new Date().getMonth(),
  };
}

export default {
  namespaced: true,

  state: resetState(),

  getters: {
    getSelectedPeriod({ year, month }) {
      if (year == undefined || month == undefined) return undefined;

      return { year, month };
    },
  },

  mutations: {
    SET_YEAR(state, year) {
      state.year = year;
    },

    SET_MONTH(state, month) {
      state.month = month;
    },

    RESET_STATE(state) {
      Object.assign(state, resetState());
    },
  },

  actions: {
    selectPeriod({ commit }, period) {
      commit("SET_YEAR", period.year);
      commit("SET_MONTH", period.month);
    },

    resetState({ commit }) {
      commit("RESET_STATE");
    },
  },
};
