<template>
  <div class="reference-instructions-list">
    <div class="reference-instructions-list__title">Инструкции</div>

    <div class="reference-instructions-list__list">
      <router-link
        v-for="(instruction, i) in instructions"
        :key="instruction"
        :to="{ name: 'ReferenceInstructions', params: { id: i + 1 } }"
        class="reference-instructions-list__link"
      >
        <img src="@icons/instruction.svg" alt="instruction.svg" />
        {{ instruction }}
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "InstructionsList",

  data() {
    return {
      instructions: [
        "Ручная настройка списка информационных баз",
        "Инструкция по подключению сервиса 1C-Отчетность в приложении «1С:Предприниматель 2015»",
        "Назначение Тонкого клиента «1С:Предприниматель»",
      ],
    };
  },
};
</script>

<style scoped lang="scss">
.reference-instructions-list {
  &__title {
    margin-bottom: 30px;
    font-size: 24px;
    font-weight: 700;
  }

  &__list {
    margin-bottom: 48px;
  }

  &__link {
    display: flex;
    align-items: center;
    padding: 4px 0;
    margin-bottom: 16px;
    color: $black;

    img {
      margin-right: 18px;
    }
  }

  @media (max-width: 600px) {
    &__title {
      font-size: 20px;
    }
  }
}
</style>
