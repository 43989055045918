import xor from "lodash-es/xor";
import negate from "lodash-es/negate";
import matches from "lodash-es/matches";
import filter from "lodash-es/filter";

const EMPTY_OPERATION = {
  client: {
    free: 0,
    tariff: 0,
    beyond: 0,
  },
  booker: {
    free: 0,
    tariff: 0,
    beyond: 0,
  },
};

function resetState() {
  return {
    showEmptyGroups: false,
    unfoldedGroupsNames: [],
  };
}

export default {
  namespaced: true,

  state: resetState(),

  getters: {
    getShowEmptyGroups(state) {
      return state.showEmptyGroups;
    },

    getUnfoldedGroupsNames(state) {
      return state.unfoldedGroupsNames;
    },

    operations(state, getters, rootState, rootGetters) {
      return rootGetters["ActiveJournal/journal"]?.units || [];
    },

    getOperationsForGroup(state, getters) {
      let operations;
      const unfoldedGroupsNames = getters["getUnfoldedGroupsNames"];

      return function withName(groupName) {
        operations = filter(getters["operations"], ["group", groupName]);

        if (unfoldedGroupsNames.includes(groupName)) {
          return operations;
        } else {
          const isOperationNotEmpty = negate(matches(EMPTY_OPERATION));

          const notEmptyOperations = operations.filter(isOperationNotEmpty);

          return notEmptyOperations;
        }
      };
    },

    isGroupUnfolded(state) {
      return function withName(groupName) {
        return state.unfoldedGroupsNames.includes(groupName);
      };
    },
  },

  mutations: {
    SET_SHOW_EMPTY_GROUPS(state, value) {
      state.showEmptyGroups = value;
    },

    SET_UNFOLDED_GROUPS_NAMES(state, names) {
      state.unfoldedGroupsNames = names;
    },

    TOGGLE_NAME_IN_UNFOLDED_GROUPS_NAMES(state, name) {
      state.unfoldedGroupsNames = xor(state.unfoldedGroupsNames, [name]);
    },

    RESET_STATE(state) {
      Object.assign(state, resetState());
    },
  },

  actions: {
    toggleVisibilityOfEmptyOperationsForGroup({ commit }, { groupName }) {
      commit("TOGGLE_NAME_IN_UNFOLDED_GROUPS_NAMES", groupName);
    },

    resetState({ commit }) {
      commit("RESET_STATE");
    },
  },
};
