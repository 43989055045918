var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "FadeTransition",
    [
      _vm.isOpened
        ? _c("AppModal", {
            attrs: { "max-width": 400 },
            on: {
              close: function ($event) {
                return _vm.$emit("close")
              },
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "body",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "modal-wr" },
                        [
                          _c("AppIcon", {
                            staticClass: "check-icon",
                            attrs: { name: "check-o-big" },
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "modal-title" }, [
                            _vm._v("Анкета отправлена"),
                          ]),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              511978902
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }