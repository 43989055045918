var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "city-select" },
    [
      _c("CabTextField", {
        staticClass: "city-select__input",
        attrs: { label: "Город", name: "city-select", autocomplete: "off" },
        on: { blur: _vm.onFormInputBlur },
        scopedSlots: _vm._u([
          {
            key: "appendedIcon",
            fn: function () {
              return [
                _vm.selectableCitiesListIsEmpty
                  ? _c("img", {
                      attrs: {
                        src: require("@icons/arrow-closed.svg"),
                        alt: "arrow-closed.svg",
                      },
                    })
                  : _c("img", {
                      attrs: {
                        src: require("@icons/arrow-open.svg"),
                        alt: "arrow-open.svg",
                      },
                    }),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.input,
          callback: function ($$v) {
            _vm.input = $$v
          },
          expression: "input",
        },
      }),
      _vm._v(" "),
      _c(
        "ItemsBox",
        {
          staticClass: "city-select__cities-list",
          attrs: {
            "key-string": "name",
            "is-empty": _vm.selectableCitiesListIsEmpty,
          },
          on: { select: _vm.onCitiesListSelect },
        },
        _vm._l(_vm.selectableCities, function (city) {
          return _c(
            "div",
            {
              key: city.id,
              staticClass: "items-box__item",
              attrs: { tabindex: "0" },
              on: {
                click: function ($event) {
                  return _vm.selectCity(city)
                },
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.selectCity(city)
                },
                focus: _vm.onCitiesListFocus,
                blur: _vm.onCitiesListBlur,
              },
            },
            [_vm._v("\n      " + _vm._s(city.name) + "\n    ")]
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }