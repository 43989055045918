import castArray from "lodash-es/castArray";

import qs from "qs";

import { AuthService } from "./auth.service";

import axiosClient from "./axiosClient.js";
import useDocumentsStateQuery from "@/queries/useDocumentsStateQuery";

const DocumentsService = {
  async fetchNotificationsSuspension(orgId) {
    const url = `/organization/${orgId}/debt/notification/suspension`;

    const response = await axiosClient.get(url);

    return response.data.notification_suspension;
  },

  async updateNotificationsSuspension({ orgId, update }) {
    const url = `/organization/${orgId}/debt/notification/suspension`;

    const csrf = await AuthService.fetchCSRF();

    const response = await axiosClient.put(url, update, {
      headers: {
        "x-csrf-token": csrf,
      },
    });

    return response.data;

    // TODO: handle this case
    // if (response.status == 400 || response.status == 500) {
    //   const json = await response.json();

    //   if (json.result == false && json.error) {
    //     throw new Error(
    //       `Ошибка в PUT /debt/notification/suspension: ${json.error}`
    //     );
    //   }
    // }
  },

  async fetchStatements(orgId) {
    const url = `/organization/${orgId}/debt/statement`;

    const response = await axiosClient.get(url);

    return response.data;
  },

  async addStatementFiles({ bankAccountId, statementFilesIds }) {
    const url = `/debt/statement/${bankAccountId}/file`;

    const csrf = await AuthService.fetchCSRF();

    const body = { attachments: statementFilesIds };

    const response = await axiosClient.post(url, body, {
      headers: {
        "x-csrf-token": csrf,
      },
    });

    return response.data;
  },

  async updateNoCashPeriod({ bankAccountId, period }) {
    const url = `/debt/statement/${bankAccountId}/nocashperiod`;

    const csrf = await AuthService.fetchCSRF();

    const response = await axiosClient.put(url, period, {
      headers: {
        "x-csrf-token": csrf,
      },
    });

    return response.data;

    // TODO: handle this case
    // if (response.status == 400) {
    //   const json = await response.json();

    //   if (json.result == false && json.error) {
    //     throw new Error(
    //       `Ошибка в PUT /debt/statement/:id/nocashperiod: ${json.error}`
    //     );
    //   }
    // }
  },

  async fetchClients(orgId) {
    const url = `/organization/${orgId}/debt/documents/closing/client`;

    const response = await axiosClient.get(url);

    return response.data;
  },

  async fetchDebitsBatch({ clientIds = [], contractIds = [], debitIds = [] }) {
    clientIds = castArray(clientIds);
    contractIds = castArray(contractIds);
    debitIds = castArray(debitIds);

    if (!clientIds.length && !contractIds.length && !debitIds.length) {
      return [];
    }

    // TODO: ask backend to fix this inconsistency
    const params = {
      clientId: clientIds,
      contractId: contractIds,
      debitId: debitIds,
    };

    const url = `/debt/documents/closing/batch`;

    const response = await axiosClient.get(url, {
      params,
      paramsSerializer: {
        serialize: (params) => qs.stringify(params, { arrayFormat: "comma" }),
      },
    });

    return response.data;
  },

  async fetchDebitsByContract({ contractIds = [], lastId = "" }) {
    contractIds = castArray(contractIds);

    if (!contractIds.length) return [];

    const url = `/debt/documents/closing/debits`;

    // TODO: ask backend to fix this inconsistency
    const params = { contractId: contractIds, lastId };

    const response = await axiosClient.get(url, {
      params,
      paramsSerializer: {
        serialize: (params) => qs.stringify(params, { arrayFormat: "comma" }),
      },
    });

    return response.data;
  },

  async fetchDebit(debitId) {
    const url = `/debt/documents/closing/debits/${debitId}`;

    const response = await axiosClient.get(url);

    return response.data;
  },

  async debitsSuspension({ debitIds = [], date, reason = "" }) {
    debitIds = castArray(debitIds);

    const url = `/debt/documents/closing/suspension`;

    const params = {
      debitId: debitIds,
      date,
      reason,
    };

    const csrf = await AuthService.fetchCSRF();

    const response = await axiosClient.post(url, undefined, {
      headers: {
        "x-csrf-token": csrf,
      },
      params,
      paramsSerializer: {
        serialize: (params) => qs.stringify(params, { arrayFormat: "comma" }),
      },
    });

    return response.data;
  },

  async addClosingFiles({ orgId, debitIds, closingFilesIds }) {
    const url = `/organization/${orgId}/debt/documents/closing/file`;

    const body = {
      attachments: closingFilesIds,
      debitId: debitIds,
      v2: "1",
    };

    const csrf = await AuthService.fetchCSRF();

    const response = await axiosClient.post(url, body, {
      headers: {
        "x-csrf-token": csrf,
      },
    });

    return response.data;
  },
};

export { DocumentsService };
