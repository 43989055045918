//@ts-check

import merge from "lodash-es/merge";
import negate from "lodash-es/negate";
import property from "lodash-es/property";
import matchesProperty from "lodash-es/matchesProperty";

import { nanoid } from "nanoid";

import { FilesService } from "../../../services";

/**
 * @typedef {Object} Attachment
 * @prop {string} id - nanoid()
 * @prop {"waiting" | "success" | "error"} uploadStatus
 * @prop {File} fileFromClient
 * @prop {Object} fileFromServer - file description from API
 */

/**
 * @returns {{
 * attachments: Array<Attachment>,
 * attachments_are_uploading: Boolean,
 * attachments_overflow: Boolean
 * }}
 */
function resetState() {
  return {
    attachments: [],

    attachments_are_uploading: false,
    attachments_overflow: false,
  };
}

// need to export function to avoid state sharing
export default function () {
  return {
    namespaced: true,

    state: resetState(),

    getters: {
      getAttachments(state) {
        return state.attachments;
      },

      getAttachmentsIds(state) {
        return state.attachments.map(property("fileFromServer.id"));
      },

      areAttachmentsUploading(state) {
        return state.attachments_are_uploading;
      },

      getAttachmentsOverflow(state) {
        return state.attachments_overflow;
      },

      getAttachmentsWaitingToBeUpload(state) {
        return state.attachments.filter(matchesProperty("uploadStatus", "waiting"));
      },

      getUploadedAttachments(state) {
        return state.attachments.filter(matchesProperty("uploadStatus", "success"));
      },

      getFileDescriptionsOfUploadedAttachments(state, getters) {
        return getters["getUploadedAttachments"].map(property("fileFromServer"));
      },
    },

    mutations: {
      RESET_STATE(state) {
        Object.assign(state, resetState());
      },

      ADD_ATTACHMENTS(state, attachments) {
        state.attachments.unshift(...attachments);
      },

      SET_ATTACHMENTS_ARE_UPLOADING(state, value) {
        state.attachments_are_uploading = value;
      },

      SET_ATTACHMENTS_OVERFLOW(state, value) {
        state.attachments_overflow = value;
      },

      UPDATE_ATTACHMENT(state, { id, update }) {
        const attachment = state.attachments.find(matchesProperty("id", id));

        merge(attachment, update);
      },

      REMOVE_ATTACHMENT(state, id) {
        const byNotMatchingIds = negate(matchesProperty("id", id));

        state.attachments = state.attachments.filter(byNotMatchingIds);
      },
    },

    actions: {
      handleFiles({ state, commit, dispatch }, { files }) {
        if (state.attachments.length + files.length > 10) {
          commit("SET_ATTACHMENTS_OVERFLOW", true);

          setTimeout(() => {
            commit("SET_ATTACHMENTS_OVERFLOW", false);
          }, 3000);

          return;
        }

        const attachments = [];

        Array.from(files).forEach((file) => {
          const attachment = {
            id: nanoid(),
            uploadStatus: "waiting",
            fileFromClient: file,
            fileFromServer: undefined,
          };

          attachments.push(attachment);
        });

        dispatch("addAttachmentsInFront", attachments);

        dispatch("uploadFiles");
      },

      async uploadFiles({ commit, getters }) {
        if (getters["areAttachmentsUploading"]) return;

        commit("SET_ATTACHMENTS_ARE_UPLOADING", true);

        while (getters["getAttachmentsWaitingToBeUpload"].length !== 0) {
          const attachment = getters["getAttachmentsWaitingToBeUpload"][0];

          let update;

          try {
            const file = attachment.fileFromClient;

            if (file.size > 50 * 1024 * 1024) throw new Error("too-large");

            const fileFromServer = await FilesService.uploadFile(file);

            update = {
              uploadStatus: "success",
              fileFromServer,
            };
          } catch (error) {
            update = {
              uploadStatus: "error",
              errorMessage: error.message,
            };
          }

          commit("UPDATE_ATTACHMENT", { id: attachment.id, update });
        }

        commit("SET_ATTACHMENTS_ARE_UPLOADING", false);
      },

      addAttachmentsInFront({ commit }, attachments) {
        commit("ADD_ATTACHMENTS", attachments);
      },

      unpinAttachment({ commit }, id) {
        commit("REMOVE_ATTACHMENT", id);
      },

      resetState({ commit }) {
        commit("RESET_STATE");
      },
    },
  };
}
