function resetState() {
  return {
    images: [],
    index: 0,
    is_opened: false,
  };
}

export default {
  namespaced: true,

  state: resetState(),

  getters: {
    isOpened(state) {
      return state.is_opened;
    },

    getImages(state) {
      return state.images;
    },

    getStartIndex(state) {
      return state.index;
    },
  },

  mutations: {
    SET_OPENED(state, isOpened) {
      state.is_opened = isOpened;
    },

    SET_IMAGES(state, images) {
      state.images = images;
    },

    SET_INDEX(state, index) {
      state.index = index;
    },

    RESET_STATE(state) {
      Object.assign(state, resetState());
    },
  },

  actions: {
    openGallery({ commit }, { images, index }) {
      if (images.length == 0 || index == undefined) return;

      commit("SET_IMAGES", images);
      commit("SET_INDEX", index);
      commit("SET_OPENED", true);
    },

    closeGallery({ commit }) {
      commit("SET_IMAGES", []);
      commit("SET_INDEX", 0);
      commit("SET_OPENED", false);
    },

    resetState({ commit }) {
      commit("RESET_STATE");
    },
  },
};
