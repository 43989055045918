<template>
  <div class="downloads-link">
    {{ name }}
    <div class="downloads-link__description-wrapper">
      <img
        v-hint.clickable="{
          text: description,
          boundarySelector: '.app__main',
        }"
        src="@icons/reference/question-mark.svg"
        class="downloads-link__description-activator"
      />
    </div>

    <a
      :href="url_download"
      class="downloads-link__download-link"
      target="_blank"
    >
      <img src="@icons/reference/download.svg" />
    </a>
  </div>
</template>

<script>
export default {
  name: "DownloadsLink",

  inheritAttrs: false,

  props: {
    name: {
      type: String,
      required: true,
    },

    description: {
      type: String,
      required: true,
    },

    url_download: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
.downloads-link {
  display: flex;
  padding: 14px 0;
  font-size: 16px;

  &:not(:last-child) {
    border-bottom: 1px solid $grey-200;
  }

  &__description-wrapper {
    position: relative;
  }

  &__description-activator {
    width: 18px;
    margin-right: 20px;
    margin-left: 10px;
    cursor: pointer;
    opacity: 0.6;
    transition: opacity $short;

    &:hover,
    &:focus {
      opacity: 1;
    }
  }

  &__download-link {
    margin-left: auto;
  }

  .app-hint {
    &__body {
      z-index: 2;
      width: 450px;
      max-width: 90vw;
    }
  }

  @media (max-width: 600px) {
    font-size: 14px;
  }
}
</style>
