import { ContractOfferService } from "../../services";

function resetState() {
  return {
    current_step: "one",
    file_name: undefined,
    file: undefined,
    file_is_uploading: false,
    input_is_valid: true,
  };
}

export default {
  namespaced: true,

  state: resetState(),

  getters: {
    getCurrentStep(state) {
      return state.current_step;
    },

    getFileName(state) {
      return state.file_name;
    },

    getFile(state) {
      return state.file;
    },

    isFileUploading(state) {
      return state.file_is_uploading;
    },

    isInputValid(state) {
      return state.input_is_valid;
    },
  },

  mutations: {
    SET_CURRENT_STEP(state, step) {
      state.current_step = step;
    },

    SET_FILE_NAME(state, fileName) {
      state.file_name = fileName;
    },

    SET_FILE(state, file) {
      state.file = file;
    },

    SET_FILE_IS_UPLOADING(state, fileIsUploading) {
      state.file_is_uploading = fileIsUploading;
    },

    SET_INPUT_IS_VALID(state, isValid) {
      state.input_is_valid = isValid;
    },

    RESET_STATE(state) {
      Object.assign(state, resetState());
    },
  },

  actions: {
    goToStep({ commit }, step) {
      commit("SET_CURRENT_STEP", step);
    },

    validateFile({ commit }, file) {
      const validTypes = [
        "application/pdf",
        "image/png",
        "image/jpeg",
        "image/tiff",
      ];

      if (file.size > 10 * 1024 * 1024 || !validTypes.includes(file.type)) {
        commit("SET_INPUT_IS_VALID", false);

        setTimeout(() => {
          commit("SET_INPUT_IS_VALID", true);
        }, 1800);

        return false;
      } else {
        return true;
      }
    },

    prepareFileForUpload({ commit }, file) {
      commit("SET_FILE", file);
      commit("SET_FILE_NAME", file.name);
    },

    resetFile({ commit }) {
      commit("SET_FILE", undefined);
      commit("SET_FILE_NAME", undefined);
    },

    async uploadFile({ commit, getters, rootGetters }) {
      if (getters["isFileUploading"]) return;

      const file = getters["getFile"];

      commit("SET_FILE_IS_UPLOADING", true);

      const orgId = rootGetters["Organizations/ActiveOne/getId"];

      try {
        await ContractOfferService.uploadFile(orgId, file);
        commit("SET_CURRENT_STEP", "four");
      } catch (error) {
        console.log(error);
      }

      commit("SET_FILE_IS_UPLOADING", false);
    },

    resetState({ commit }) {
      commit("RESET_STATE");
    },
  },
};
