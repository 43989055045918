<template>
  <div class="download-icon" @click.stop="downloadFile">
    <svg v-if="!loading" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
      <path d="M12.43 6.83L9.8 9.47V1H8.2v8.47L5.57 6.83 4.43 7.97 9 12.53l4.57-4.56-1.14-1.14z" fill="currentColor" />
      <path
        d="M17 15.4v-3.2h-1.6v3.2H2.6v-3.2H1v3.2c0 .88.72 1.6 1.6 1.6h12.8c.88 0 1.6-.72 1.6-1.6z"
        fill="currentColor"
      />
    </svg>
    <img v-else src="@icons/bubble-loader.svg" width="20" />
  </div>
</template>

<script>
import { API_URL } from "@/utils/constants";
import { mapGetters } from "vuex";
import { InfoService } from "@/services";

export default {
  name: "FileDownloadIcon",

  props: {
    contractId: {
      type: String,
      default: "",
    },
    docId: {
      type: String,
      default: "",
    },
    formId: {
      type: String,
      default: "",
    },
    fileId: {
      type: String,
      default: "",
    },
    form: {
      type: String,
      default: "",
    },
    action: {
      type: String,
      default: "",
    },
  },

  data: () => ({
    loading: false,
  }),

  computed: {
    ...mapGetters("Organizations/ActiveOne", {
      orgId: "getId",
    }),
    fileLink() {
      if (this.formId) {
        return `${API_URL}/organization/${this.orgId}/document/file?contract_id=${this.contractId}&doc_id=${this.docId}&form_id=${this.formId}`;
      } else {
        return this.fileId
          ? `${API_URL}/file/${this.fileId}`
          : `${API_URL}/organization/${this.orgId}/contract/${this.form}/${this.action}`;
      }
    },
  },

  methods: {
    downloadFile() {
      this.loading = true;
      InfoService.fetchDownloadFile(this.fileLink)
        .finally(() => (this.loading = false))
        .catch((error) => {
          this.$notify({
            type: "error",
            text: error.message || error.error,
          });
        });
    },
  },
};
</script>

<style scoped>
.download-icon {
  position: relative;
  z-index: 0;
  flex: none;
  cursor: pointer;
}
</style>
