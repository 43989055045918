import { AuthService } from "./auth.service";

import axiosClient from "./axiosClient.js";

const JournalsService = {
  async fetchLiteJournals(orgId) {
    const url = `/organization/${orgId}/journal`;

    const response = await axiosClient.get(url);

    return response.data;
  },

  async fetchJournalsHistory(orgId) {
    const url = `/organization/${orgId}/journal/history`;

    const response = await axiosClient.get(url);

    return response.data;
  },

  async fetchJournal(id) {
    const url = `/journal/${id}`;

    const response = await axiosClient.get(url);

    return response.data;
  },

  async fetchUnit(journalId, unitId) {
    const url = `/journal/${journalId}/unit/${unitId}`;

    const response = await axiosClient.get(url);

    return response.data;
  },

  async approve(journalId) {
    const url = `/journal/${journalId}/approve`;

    const csrf = await AuthService.fetchCSRF();

    const response = await axiosClient.post(url, undefined, {
      headers: {
        "x-csrf-token": csrf,
      },
    });

    return response.data;

    // TODO: handle this case
    // if (error.response.data.error) {
    //   throw new Error(error.response.data.error);
    // }
  },

  async postComment(journalId, comment) {
    const url = `/journal/${journalId}/comment`;

    const csrf = await AuthService.fetchCSRF();

    const response = await axiosClient.post(url, comment, {
      headers: {
        "x-csrf-token": csrf,
      },
    });

    return response.data;
  },
};

export { JournalsService };
