<template>
  <div>
    <div v-for="option in options" :key="option.name" class="">
      <MultipleCheckbox
        v-model="modelValue"
        v-hint="{ text: 'Аутсорсер внес исправление' }"
        :hint-disabled="
          disabled && option.changed && showChanges ? 'false' : 'true'
        "
        :value="option.name"
        :disabled="disabled"
        :class="{
          'question-checkbox--highlight':
            disabled && option.changed && showChanges,
        }"
        class="question-checkbox"
        @input="$emit('input', modelValue)"
      >
        {{ option.name }}
      </MultipleCheckbox>
    </div>

    <div v-if="own" class="question-other">
      <MultipleCheckbox
        v-model="modelValue"
        value="Другое"
        :disabled="disabled"
        class="question-checkbox"
        @input="$emit('input', modelValue)"
      >
        Другое:
      </MultipleCheckbox>

      <HighlightTextarea
        v-model="modelOwnText"
        v-hint="{
          text: 'Аутсорсер внес исправление',
          placement: 'top-start',
        }"
        :hint-disabled="!(disabled && ownChanged && showChanges)"
        :name="'name_' + Math.random()"
        label=""
        rows="1"
        maxlength="500"
        :disabled="disabled"
        :highlight-text="disabled && ownChanged && showChanges"
        autocomplete="off"
        class="question-other__text"
        @focus="$emit('change', modelValue)"
        @input="$emit('inputOwnText', modelOwnText)"
      />
    </div>
  </div>
</template>

<script>
import MultipleCheckbox from "@/components/common/MultipleCheckbox.vue";
import HighlightTextarea from "./HighlightTextarea.vue";

export default {
  name: "QuestionCheckbox",

  components: {
    MultipleCheckbox,
    HighlightTextarea,
  },

  inject: ["screenSize"],

  props: {
    value: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showChanges: {
      type: Boolean,
      default: false,
    },
    own: {
      type: Boolean,
      default: false,
    },
    ownText: {
      type: String,
      default: "",
    },
    ownChanged: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      modelValue: null,
      modelOwnText: "",
    };
  },

  computed: {
    isSreenSizeSmallerOrEqualSM() {
      return this.screenSize.smallerOrEqual("sm").value;
    },
  },

  watch: {
    value: {
      handler() {
        this.modelValue = this.value;
      },
      immediate: true,
    },
    ownText: {
      handler() {
        this.modelOwnText = this.ownText;
      },
      immediate: true,
    },
  },
};
</script>

<style scoped lang="scss">
/* stylelint-disable selector-class-pattern */
.question-checkbox {
  max-width: 800px;
  align-items: center;
  margin-bottom: 10px;
  word-break: break-word;

  ::v-deep input {
    width: 18px;
    height: 18px;
    margin-right: 12px;
  }
}

.question-checkbox--highlight {
  position: relative;
  display: inline-flex;
  cursor: pointer;

  &::before {
    position: absolute;
    top: -2px;
    left: -6px;
    width: calc(100% + 12px);
    height: calc(100% + 4px);
    border-radius: 10px;
    background-color: #ffe676;
    content: "";
  }
}

.question-other {
  display: flex;
  align-items: flex-start;
}

.question-other__text {
  width: 100%;
  max-width: 700px;
  margin-left: 15px;

  ::v-deep .cab-textarea__wrapper {
    padding-top: 0; // т.к. HighlightTextarea без label - убираем паддинг, отведенный для label
  }
}
</style>
