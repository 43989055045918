import property from "lodash-es/property";

import Vue from "vue";
import VueRouter from "vue-router";

import loginRoutes from "./loginRoutes.js";
import appRoutes from "./appRoutes.js";

import NotFound from "../views/NotFound.vue";

import store from "../store";

import { AuthService, UserService } from "../services";

import { BASE_PATH } from "../utils/constants";
import { loadTopic } from "@/utils";

Vue.use(VueRouter);

const routes = [
  loginRoutes,

  appRoutes,

  {
    path: "*",
    component: NotFound,
  },
];

const router = new VueRouter({
  base:
    process.env.NODE_ENV == "production"
      ? `${BASE_PATH}` // eslint-disable-line no-undef
      : undefined,
  // TODO: ask the mr. Admin to configure the server for this mode
  // mode: "history",
  routes,
});

const PUBLIC_ROUTES = loginRoutes.children.map(property("name"));

let isInitialNavigation = true;

router.beforeEach(async function globalNavGuard(to, from, next) {
  if (isInitialNavigation) {
    isInitialNavigation = false;

    localStorage.removeItem("loginRouteRedirectTo");

    store.dispatch("resetStore");

    if (PUBLIC_ROUTES.includes(to.name)) {
      localStorage.setItem("loginRouteRedirectTo", to.fullPath);
    }
    let userAndTopics;

    try {
      userAndTopics = await fetchUserInfoAndTopicsList();
    } catch (error) {
      console.error(error);

      localStorage.removeItem("loginRouteRedirectTo");

      // allow an unauthorized user to enter one of the login routes
      if (PUBLIC_ROUTES.includes(to.name)) {
        return next({ name: to.name, query: to.query });
      }

      const redirectFrom = to.query.redirectFrom || to.fullPath;

      return next({ name: "Login", query: { redirectFrom } });
    }

    try {
      await store.dispatch("initStore", userAndTopics);
    } catch (error) {
      console.error(error);

      localStorage.removeItem("loginRouteRedirectTo");
      store.dispatch("resetStore");

      return next(false);
    }
  } else {
    // handle click on a link in offer html
    if (to.query.app) {
      const formId = `annex${to.query.app}`;

      await store.dispatch("Organizations/ActiveOne/selectOfferForm", formId);

      document.querySelector(".contract-offer").scrollIntoView();

      return next(false);
    }
  }

  next();
});

export default router;

async function fetchUserInfoAndTopicsList() {
  let userInfo;
  let topicsList;

  if (localStorage.getItem("isUserAuthorized")) {
    try {
      // try to get info via a laravel_session cookie:
      userInfo = await UserService.fetchInfo();

      topicsList = await getTopicList(userInfo);
    } catch (error) {
      console.error(error);

      try {
        // a successful login via OID will set a laravel_session cookie:
        await AuthService.getLaravelSessionUsingOID();

        // try again
        userInfo = await UserService.fetchInfo();

        topicsList = await getTopicList(userInfo);
      } catch (error) {
        localStorage.removeItem("isUserAuthorized");

        throw error;
      }
    }
  } else {
    await AuthService.getLaravelSessionUsingOID();

    userInfo = await UserService.fetchInfo();

    topicsList = await getTopicList(userInfo);

    localStorage.setItem("isUserAuthorized", true);
  }

  return { userInfo, topicsList };
}

export async function getTopicList(user) {
  const orgId = localStorage.getItem(`cab-org-id:${user.user_id}`);
  const org = user.organizations.find((org) => org.id === orgId) || user.organizations[0];

  return await loadTopic(org);
}
