import property from "lodash-es/property";
import negate from "lodash-es/negate";
import matchesProperty from "lodash-es/matchesProperty";

import fuzzysort from "fuzzysort";

function resetState() {
  return {
    searchTerm: "",
    activeOptionIndex: 0,
  };
}

const TYPES = {
  "#": "topic",
  "@": "mention",
};

const commonMentions = [
  {
    name: "Все",
    position: "Оповестить всех пользователей которым доступна данная организация",
    avatar: null,
    user_id: "everyone",
  },
  {
    name: "Участники",
    position: "Оповестить всех пользователей которым доступна данная тема",
    avatar: null,
    user_id: "here",
  },
];

export default {
  namespaced: true,

  state: resetState(),

  getters: {
    searchTermType(state) {
      const firstCharOfSearchTerm = state.searchTerm[0];

      return TYPES[firstCharOfSearchTerm];
    },

    options(state, getters, rootState, rootGetters) {
      let term = "";
      let list = [];

      const type = getters["searchTermType"];

      if (!type) return [];

      term = state.searchTerm.slice(1);

      if (type == "topic") {
        list = rootGetters["Topics/topicsList"].filter(negate(matchesProperty("code", "ЗаключениеДоговора")));
      } else if (type == "mention") {
        const participants = rootGetters["Chat/Participants/participants"];
        list = participants.filter(property("user_id"));
        list = list.concat(commonMentions);
      }

      return fuzzysort.go(term, list, { key: "name", all: true }).slice(0, 7);
    },

    activeOptionIndex({ activeOptionIndex }) {
      return activeOptionIndex;
    },

    activeOption(state, getters) {
      return getters["options"][state.activeOptionIndex];
    },
  },

  mutations: {
    SET_SEARCH_TERM(state, searchTerm) {
      state.searchTerm = searchTerm;
    },

    SET_ACTIVE_OPTION_INDEX(state, activeOptionIndex) {
      state.activeOptionIndex = activeOptionIndex;
    },

    RESET_STATE(state) {
      Object.assign(state, resetState());
    },
  },

  actions: {
    updateOptions({ commit }, searchTerm) {
      commit("SET_SEARCH_TERM", searchTerm);
      commit("SET_ACTIVE_OPTION_INDEX", 0);
    },

    updateSearchTerm({ commit }, searchTerm) {
      commit("SET_SEARCH_TERM", searchTerm);
    },

    updateActiveOptionIndex({ commit }, index) {
      commit("SET_ACTIVE_OPTION_INDEX", index);
    },

    resetState({ commit }) {
      commit("RESET_STATE");
    },
  },
};
