import { mapGetters, mapActions } from "vuex";

import { AuthService, UserService, FeedService } from "../../services";

import * as Sentry from "@sentry/vue";
import { loadTopic } from "@/utils";

export default {
  data() {
    return {
      isInProcessOfLogin: false,
    };
  },

  computed: {
    ...mapGetters({
      newUser: "Organizations/ActiveOne/newUser",
      hasActiveQuestionnaire: "Organizations/ActiveOne/hasActiveQuestionnaire",
    }),
  },

  methods: {
    ...mapActions({
      initStore: "initStore",
    }),

    async loginUser({ username, password }) {
      this.isInProcessOfLogin = true;

      try {
        await AuthService.login({ username, password });

        localStorage.setItem("isUserAuthorized", true);
      } catch (error) {
        this.isInProcessOfLogin = false;

        Sentry.captureException(error, {
          user: {
            name: username,
          },
        });

        console.error(error);

        if (error.response.status == 401) {
          this.errorMessage = "Неверные логин и/или пароль.";
        } else if (error.response.status == 403) {
          if (error.response.data.reason == "user_is_outsourcer") {
            this.errorMessage = "У пользователя аутсорсера нет прав для доступа в личный кабинет клиента.";
          } else {
            this.errorMessage = "У пользователя нет прав для доступа в личный кабинет клиента.";
          }
        } else {
          this.errorMessage = "Что-то пошло не так, попробуйте позже.";
        }

        return;
      }

      // TODO: check if it works right.
      // start in parallel (without await!) process of getting OID cookie
      AuthService.authenticateInOID({
        username,
        password,
      }).catch((error) => {
        console.error("Error in OID flow:", error);
      });

      try {
        const userInfo = await UserService.fetchInfo();

        const orgId = localStorage.getItem(`cab-org-id:${userInfo.user_id}`);
        const org = userInfo.organizations.find((org) => org.id === orgId) || userInfo.organizations[0];

        let topicsList = await loadTopic(org);

        // Init view steps:
        // [0] initialize organization
        await this.initStore({ userInfo, topicsList });

        const redirectTo = this.$route.query.redirectTo;

        // [1] load first view
        if (redirectTo) {
          this.$router.push(redirectTo);
        } else if (this.hasActiveQuestionnaire) {
          this.$router.push({ name: "TechAudit" });
        } else if (this.newUser) {
          this.$router.push({ name: "TariffContractOrOffer" });
        } else {
          this.$router.push(this.$route.query.redirectFrom || { name: "Chat" });
        }
      } catch (error) {
        Sentry.captureException(error, {
          user: {
            name: username,
          },
        });

        console.error(error);
        this.$store.dispatch("resetStore");

        this.$notify({
          title: "Ошибка:",
          text: error.message,
          type: "error",
          duration: -1,
        });
      } finally {
        this.isInProcessOfLogin = false;
      }
    },

    onIconClick() {
      this.isPasswordVisible = !this.isPasswordVisible;
    },
  },
};
