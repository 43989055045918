var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    { staticClass: "form" },
    [
      _c("CabTextField", {
        staticClass: "form__input",
        attrs: {
          autocomplete: "new-password",
          label: "Текущий пароль",
          name: "current-password-in-settings",
          placeholder: " Введите текущий пароль",
          type: _vm.currentPasswordType,
          "error-message": _vm.currentPasswordErrorMessage,
        },
        on: { input: _vm.resetErrors },
        scopedSlots: _vm._u([
          {
            key: "appendedIcon",
            fn: function () {
              return [
                _c("EyeIcon", {
                  staticClass: "login-form__password-icon",
                  attrs: {
                    "is-striked": _vm.currentPasswordType == "password",
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.onIconClick("currentPasswordType")
                    },
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.currentPassword,
          callback: function ($$v) {
            _vm.currentPassword = $$v
          },
          expression: "currentPassword",
        },
      }),
      _vm._v(" "),
      _c("CabTextField", {
        staticClass: "form__input",
        attrs: {
          autocomplete: "new-password",
          label: "Новый пароль",
          name: "new-password-in-settings",
          placeholder: " Введите новый пароль",
          type: _vm.newPasswordType,
          "error-message": _vm.newPasswordErrorMessage,
        },
        on: { input: _vm.resetErrors },
        scopedSlots: _vm._u([
          {
            key: "appendedIcon",
            fn: function () {
              return [
                _c("EyeIcon", {
                  staticClass: "login-form__password-icon",
                  attrs: { "is-striked": _vm.newPasswordType == "password" },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.onIconClick("newPasswordType")
                    },
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.newPassword,
          callback: function ($$v) {
            _vm.newPassword = $$v
          },
          expression: "newPassword",
        },
      }),
      _vm._v(" "),
      _c("CabTextField", {
        staticClass: "form__input",
        attrs: {
          autocomplete: "new-password",
          label: "Подтверждение пароля",
          name: "new-password-in-settings-repeat",
          placeholder: " Повторите новый пароль",
          type: _vm.newPasswordType,
          "error-message": _vm.repeatNewPasswordErrorMessage,
        },
        on: { input: _vm.resetErrors },
        scopedSlots: _vm._u([
          {
            key: "appendedIcon",
            fn: function () {
              return [
                _c("EyeIcon", {
                  staticClass: "login-form__password-icon",
                  attrs: { "is-striked": _vm.newPasswordType == "password" },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.onIconClick("newPasswordType")
                    },
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.repeatNewPassword,
          callback: function ($$v) {
            _vm.repeatNewPassword = $$v
          },
          expression: "repeatNewPassword",
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        { class: _vm.classesForButton, on: { click: _vm.savePassword } },
        [
          _vm._v("\n    Сохранить\n    "),
          _c("FadeTransition", [
            _vm.passwordIsUpdating
              ? _c("div", { staticClass: "form__loader" }, [
                  _c("img", {
                    attrs: {
                      src: require("@icons/bubble-loader.svg"),
                      width: "24",
                    },
                  }),
                ])
              : _vm._e(),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }