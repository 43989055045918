<template>
  <div class="table">
    <div class="table__head" @click="opened = !opened">
      {{ title }}
      <EyeIcon :is-striked="!opened" />
    </div>

    <!-- <ExpandTransition> -->
    <div v-if="opened" class="table__content">
      <div v-for="(right, i) in rights" :key="i" class="table__row">
        <div class="table__row--key">{{ right.name }}</div>
        <div v-for="agent in agents" :key="agent.id" class="table__row--value">
          <svg
            v-if="right.list.includes(agent.id)"
            class="table__icon"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12 23a11 11 0 1 1 0-22 11 11 0 0 1 0 22Zm0-2a9 9 0 1 0 0-18 9 9 0 0 0 0 18Zm3.3-12.7L10 13.58l-2.3-2.3-1.4 1.42 3.7 3.7 6.7-6.7-1.4-1.42Z"
              fill="#04C200"
            />
          </svg>
        </div>
      </div>
    </div>
    <!-- </ExpandTransition> -->
  </div>
</template>

<script>
import EyeIcon from "../common/EyeIcon.vue";
import ExpandTransition from "../transitions/Expand.vue";

export default {
  name: "TariffAgentsRightsTable",

  components: {
    EyeIcon,
    ExpandTransition,
  },

  props: {
    title: {
      type: String,
      required: true,
    },

    agents: {
      type: Array,
      required: true,
    },

    rights: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      opened: true,
    };
  },
};
</script>

<style scoped lang="scss">
.table {
  &__head {
    display: flex;
    align-items: center;
    padding: 9px 24px;
    background-color: $grey-50;
    cursor: pointer;
    font-weight: 700;
    user-select: none;

    svg {
      width: 16px;
      min-width: 16px;
      margin-left: 8px;
    }
  }

  &__row {
    display: flex;
    padding: 9px 0;
    border-bottom: 1px solid $grey-100;
    margin: 0 24px;

    &--key {
      display: flex;
      width: 360px;
      align-items: center;
    }

    &--value {
      display: flex;
      min-height: 24px;
      flex: 1;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &:nth-child(2) {
        margin-left: 48px;
      }

      &:last-child {
        margin-right: 48px;
      }
    }
  }

  &__icon {
    width: 24px;
  }

  @media (min-width: 1265px) and (max-width: 1904px) {
    // linear equation:
    // 1904*x1 + x2 = 360
    // 1265*x1 + x2 = 300
    &__row--key {
      width: calc(10vw + 180px);
    }
  }

  @media (min-width: 961px) and (max-width: 1264px) {
    // linear equation:
    // 1264*x1 + x2 = 360
    // 961*x1 + x2 = 300
    &__row--key {
      width: calc(20vw + 110px);
    }
  }

  @media (max-width: 960px) {
    &__head {
      font-size: 12px;
    }

    &__row {
      flex-wrap: wrap;

      &--key {
        width: 100%;
        margin-bottom: 10px;
        color: $grey-700;
        font-size: 12px;
      }

      &--value {
        &:nth-child(2) {
          margin-left: 24px;
        }

        &:last-child {
          margin-right: 24px;
        }
      }
    }
  }
}
</style>
