import xor from "lodash-es/xor";

import { calendarTabs } from "../../utils/layout";

function resetState() {
  return {
    active_tab: calendarTabs[0],
    findstr: undefined,
    only_with_reminder: false,

    selected_status_filter: {
      all: "any",
      my: "any",
      outsourcer: "any",
      assignment: "any",
    },

    status_filter_options: {
      all: {
        any: "Любой статус",
        expired: "Просрочено",
        work: "В работе",
        assigned: "Запланировано",
        done: "Выполнено",
        canceled: "Отменено",
        // example1: "Пример различия аутсорсер",
        // example2: "Пример различия поручение",
      },

      my: {
        any: "Любой статус",
        expired: "Просрочено",
        work: "В работе",
        assigned: "Запланировано",
        done: "Выполнено",
        canceled: "Отменено",
      },

      outsourcer: {
        any: "Любой статус",
        expired: "Просрочено",
        work: "В работе",
        assigned: "Запланировано",
        done: "Выполнено",
        canceled: "Отменено",
        // example1: "Пример различия аутсорсер",
      },

      assignment: {
        any: "Любой статус",
        assigned: "Запланировано",
        work: "В работе",
        done: "Выполнено",
        expired: "Просрочено",
        canceled: "Отменено",
        example2: "Пример различия поручение",
      },
    },
  };
}

export default {
  namespaced: true,

  state: resetState(),

  getters: {
    getActiveTab({ active_tab }) {
      return active_tab;
    },

    getFindstr(state) {
      return state.findstr;
    },

    getOnlyWithReminder({ only_with_reminder }) {
      return only_with_reminder;
    },

    getSelectedStatus({ selected_status_filter }) {
      return function forTab(tab) {
        return selected_status_filter[tab];
      };
    },

    getActiveStatusFilterOptions({ status_filter_options }) {
      return function forTab(tab) {
        return status_filter_options[tab];
      };
    },
  },

  mutations: {
    SET_ACTIVE_TAB(state, tab) {
      state.active_tab = tab;
    },

    UPDATE_FINDSTR(state, findstr) {
      state.findstr = findstr;
    },

    SET_ONLY_WITH_REMINDER(state, flag) {
      state.only_with_reminder = flag;
    },

    SET_STATUS_FILTER(state, value) {
      state.selected_status_filter[state.active_tab.key] = value;
    },

    RESET_STATE(state) {
      Object.assign(state, resetState());
    },
  },

  actions: {
    toggleFilter({ state, commit }, filter) {
      commit("SET_FILTERS", xor(state.selected, [filter]));
    },

    resetFindstr({ commit }) {
      commit("UPDATE_FINDSTR", undefined);
    },

    resetState({ commit }) {
      commit("RESET_STATE");
    },
  },
};
