var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "profile" },
    [
      _c("AvatarSelect", { staticClass: "profile__avatar-select" }),
      _vm._v(" "),
      _c("div", { staticClass: "profile__text-fields" }, [
        _c("div", { staticClass: "profile__name" }, [
          _vm._v("\n      " + _vm._s(_vm.name) + "\n    "),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "profile__position" }, [
          _vm._v("\n      " + _vm._s(_vm.position) + "\n    "),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }