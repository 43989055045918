import { AuthService } from "./auth.service";

import axiosClient from "./axiosClient.js";

const FilesService = {
  async uploadFile(file) {
    const url = `/file`;

    const csrf = await AuthService.fetchCSRF();

    let formData = new FormData();

    formData.append("file", file, file.name);

    const response = await axiosClient.post(url, formData, {
      headers: {
        "x-csrf-token": csrf,
      },
    });

    return response.data;
  },
};

export { FilesService };
