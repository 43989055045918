import { ContractOfferService } from "../../services";

import MarginConfirmation from "./margin-confirmation";

function resetState() {
  return {
    offer_form_id: "offer",
    offer_form_html: "",
    offer_form_is_loading: false,
  };
}

export default {
  namespaced: true,

  state: resetState(),

  getters: {
    organization(state, getters, rootState, rootGetters) {
      return rootGetters["Organizations/getActive"];
    },

    getId(state, getters) {
      return getters["organization"]?.id;
    },

    getShowTopics(state, getters) {
      return getters["organization"]?.show_topics;
    },

    getName(state, getters) {
      return getters["organization"]?.name;
    },

    getContractTariff(state, getters) {
      return getters["organization"]?.contract.tariff;
    },

    contractTariffName(state, getters) {
      return getters["organization"]?.contract.tariff.name;
    },

    contractStartDate(state, getters) {
      return getters["organization"]?.contract.date || "-";
    },

    contractServiceDate(state, getters) {
      return getters["organization"]?.contract.service_date;
    },

    contractStatusDate(state, getters) {
      return getters["organization"]?.contract.status_date;
    },

    businessForm(state, getters) {
      return getters["organization"]?.businessform;
    },

    taxation(state, getters) {
      return getters["organization"]?.taxation;
    },

    isEmployeeTariff(state, getters) {
      const contractTariffName = getters["contractTariffName"];

      const employeeTariffs = ["Зарплата и кадровый учет", "Заработная плата"];

      return employeeTariffs.includes(contractTariffName);
    },

    isOperationsTariff(state, getters) {
      const contractTariffName = getters["contractTariffName"];

      const employeeTariffs = ["Отчетность", "Комплексный сервис"];

      return employeeTariffs.includes(contractTariffName);
    },

    isProgrammTariff(state, getters) {
      const contractTariffName = getters["contractTariffName"];

      return contractTariffName == "Программа и консультация";
    },

    isContractOffer(state, getters) {
      return getters["organization"]?.contract.is_offer;
    },

    getEarnestPayment(state, getters) {
      return getters["organization"]?.contract.margin;
    },

    getContractOfferForms(state, getters) {
      return getters["organization"]?.contract.forms;
    },

    contractStatus(state, getters) {
      return getters["organization"]?.contract?.status;
    },

    getOfferFormId(state) {
      return state.offer_form_id;
    },

    getOfferFormHTML(state) {
      return state.offer_form_html;
    },

    isPaymentOrderUploaded(state, getters) {
      return !!getters["organization"]?.contract.payment;
    },

    isOfferFormLoading(state) {
      return state.offer_form_is_loading;
    },

    getApps(state, getters) {
      return getters["organization"]?.apps;
    },

    newUser(state, getters) {
      const contract = getters["organization"]?.contract;

      return contract.is_offer && contract.status == "concluding";
    },

    hasActiveQuestionnaire(state, getters) {
      return getters["organization"]?.has_active_questionnaire;
    },
  },

  mutations: {
    SET_OFFER_FORM_ID(state, id) {
      state.offer_form_id = id;
    },

    SET_OFFER_FORM_HTML(state, html) {
      state.offer_form_html = html;
    },

    SET_OFFER_FORM_IS_LOADING(state, isLoading) {
      state.offer_form_is_loading = isLoading;
    },

    RESET_STATE(state) {
      Object.assign(state, resetState());
    },
  },

  actions: {
    setOfferFormId({ commit }, formId) {
      commit("SET_OFFER_FORM_ID", formId);
    },

    async selectOfferForm({ commit, getters }, formId) {
      const orgId = getters["getId"];

      commit("SET_OFFER_FORM_IS_LOADING", true);

      const html = await ContractOfferService.fetchOfferForm(orgId, formId);

      commit("SET_OFFER_FORM_IS_LOADING", false);

      const parser = new DOMParser();

      const dom = parser.parseFromString(html, "text/html");

      if (formId == "offer") {
        commit("SET_OFFER_FORM_HTML", dom.body.children[0].innerHTML);
      } else {
        commit("SET_OFFER_FORM_HTML", dom.body.innerHTML);
      }
    },

    resetState({ commit, dispatch }) {
      dispatch("MarginConfirmation/resetState");

      commit("RESET_STATE");
    },
  },

  modules: {
    MarginConfirmation,
  },
};
