// import Documents from "../views/Documents.vue";
const Documents = () =>
  import(
    /* webpackChunkName: "Documents" */
    "../views/Documents.vue"
  );
// import DocumentsStatements from "../views/Documents/Statements.vue";
const DocumentsStatements = () =>
  import(
    /* webpackChunkName: "DocumentsStatements" */
    "../views/Documents/Statements.vue"
  );
// import DocumentsClosing from "../views/Documents/Closing.vue";
const DocumentsClosing = () =>
  import(
    /* webpackChunkName: "DocumentsClosing" */
    "../views/Documents/Closing.vue"
  );

export default {
  path: "documents",
  name: "Documents",
  component: Documents,
  redirect: {
    name: "DocumentsStatements",
  },

  meta: {
    withTabs: true,
  },

  children: [
    {
      path: "statements",
      name: "DocumentsStatements",
      component: DocumentsStatements,

      meta: {
        withTabs: true,
      },
    },

    {
      path: "closing",
      name: "DocumentsClosing",
      component: DocumentsClosing,

      meta: {
        withTabs: true,
      },
    },
  ],
};
