var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._l(_vm.options, function (option) {
        return _c(
          "div",
          { key: option.name },
          [
            _c(
              "MultipleCheckbox",
              {
                directives: [
                  {
                    name: "hint",
                    rawName: "v-hint",
                    value: { text: "Аутсорсер внес исправление" },
                    expression: "{ text: 'Аутсорсер внес исправление' }",
                  },
                ],
                staticClass: "question-checkbox",
                class: {
                  "question-checkbox--highlight":
                    _vm.disabled && option.changed && _vm.showChanges,
                },
                attrs: {
                  "hint-disabled":
                    _vm.disabled && option.changed && _vm.showChanges
                      ? "false"
                      : "true",
                  value: option.name,
                  disabled: _vm.disabled,
                },
                on: {
                  input: function ($event) {
                    return _vm.$emit("input", _vm.modelValue)
                  },
                },
                model: {
                  value: _vm.modelValue,
                  callback: function ($$v) {
                    _vm.modelValue = $$v
                  },
                  expression: "modelValue",
                },
              },
              [_vm._v("\n      " + _vm._s(option.name) + "\n    ")]
            ),
          ],
          1
        )
      }),
      _vm._v(" "),
      _vm.own
        ? _c(
            "div",
            { staticClass: "question-other" },
            [
              _c(
                "MultipleCheckbox",
                {
                  staticClass: "question-checkbox",
                  attrs: { value: "Другое", disabled: _vm.disabled },
                  on: {
                    input: function ($event) {
                      return _vm.$emit("input", _vm.modelValue)
                    },
                  },
                  model: {
                    value: _vm.modelValue,
                    callback: function ($$v) {
                      _vm.modelValue = $$v
                    },
                    expression: "modelValue",
                  },
                },
                [_vm._v("\n      Другое:\n    ")]
              ),
              _vm._v(" "),
              _c("HighlightTextarea", {
                directives: [
                  {
                    name: "hint",
                    rawName: "v-hint",
                    value: {
                      text: "Аутсорсер внес исправление",
                      placement: "top-start",
                    },
                    expression:
                      "{\n        text: 'Аутсорсер внес исправление',\n        placement: 'top-start',\n      }",
                  },
                ],
                staticClass: "question-other__text",
                attrs: {
                  "hint-disabled": !(
                    _vm.disabled &&
                    _vm.ownChanged &&
                    _vm.showChanges
                  ),
                  name: "name_" + Math.random(),
                  label: "",
                  rows: "1",
                  maxlength: "500",
                  disabled: _vm.disabled,
                  "highlight-text":
                    _vm.disabled && _vm.ownChanged && _vm.showChanges,
                  autocomplete: "off",
                },
                on: {
                  focus: function ($event) {
                    return _vm.$emit("change", _vm.modelValue)
                  },
                  input: function ($event) {
                    return _vm.$emit("inputOwnText", _vm.modelOwnText)
                  },
                },
                model: {
                  value: _vm.modelOwnText,
                  callback: function ($$v) {
                    _vm.modelOwnText = $$v
                  },
                  expression: "modelOwnText",
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }