export default function (name) {
  const nameParts = name.split(/\s/).filter(notEmptyString);

  const firstLetters = nameParts.map(getFirstLetter);

  return firstLetters.slice(0, 2).join("");
}

function notEmptyString(value) {
  return value !== "";
}

function getFirstLetter(string) {
  return string[0];
}
