var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "app-modal",
      attrs: { tabindex: "0" },
      on: {
        keyup: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          )
            return null
          return _vm.$emit("close")
        },
      },
    },
    [
      _c("div", {
        staticClass: "app-modal__overlay",
        on: {
          click: function ($event) {
            return _vm.$emit("close")
          },
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          ref: "container",
          staticClass: "app-modal__content app-modal-content",
          class: { "app-modal-content--clean": _vm.$slots.clean },
          style: { maxWidth: _vm.maxWidth + "px" },
        },
        [
          _vm.$slots.clean
            ? _vm._t("clean")
            : [
                _c("div", {
                  staticClass: "app-modal-content__close",
                  attrs: { name: "body" },
                  domProps: { innerHTML: _vm._s(_vm.crossIcon) },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("close")
                    },
                  },
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "app-modal-content__header",
                    class: _vm.classes.header,
                  },
                  [_vm._t("header")],
                  2
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "app-modal-content__subheader",
                    class: _vm.classes.subheader,
                  },
                  [_vm._t("subheader")],
                  2
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "app-modal-content__body",
                    class: _vm.classes.body,
                  },
                  [_vm._t("body")],
                  2
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "app-modal-content__footer",
                    class: _vm.classes.footer,
                  },
                  [_vm._t("footer")],
                  2
                ),
              ],
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }