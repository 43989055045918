<template>
  <div class="service-connection">
    <div class="service-connection__header">
      <div
        tabindex="0"
        class="service-connection__back"
        @click="goBack"
        @keyup.enter="goBack"
      >
        <img src="@icons/arrow-back.svg" alt="arrow-back.svg" />
      </div>

      <h2 class="service-connection__title">Подключить 1С:БО</h2>
    </div>

    <form class="service-connection__form">
      <CabTextField
        v-model="$v.name.$model"
        class="service-connection__input"
        label="Имя"
        name="service-connection-name"
      />

      <CabTextField
        v-model="$v.phone.$model"
        class="service-connection__input"
        label="Номер телефона"
        name="service-connection-phone"
        mask="+7 (###) ###-##-##"
      />

      <CabTextField
        v-model="$v.email.$model"
        class="service-connection__input"
        label="Email"
        name="service-connection-email"
      />

      <CitySelect @select="onCitySelect" />

      <div :class="classesForSendButton" @click="sendServiceConnectionRequest">
        Отправить
      </div>

      <!-- <div @click="test">test</div> -->
    </form>

    <div class="service-connection__help-info">
      <div>
        Если вы ещё не являетесь клиентом сервиса 1С:БО — оставьте заявку на
        подключение к сервису. Партнёр 1С:БО свяжется с вами для подключения.
      </div>

      <div>
        Если у вас уже есть договор с партнёром сервиса 1С:БО — обратитесь к
        вашему партнёру.
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

import { UserService } from "../../services";

import { required, email, minLength } from "vuelidate/dist/validators.min";

import CabTextField from "../../components/common/CabTextField.vue";
import CitySelect from "../../components/Login/CitySelect.vue";

export default {
  name: "ServiceConnection",

  components: {
    CabTextField,
    CitySelect,
  },

  data() {
    return {
      name: "",
      phone: "",
      email: "",
      cityId: "",
    };
  },

  validations: {
    name: {
      required,
    },

    phone: {
      required,
      minLength: minLength(18),
    },

    email: {
      required,
      email,
    },

    cityId: {
      required,
    },
  },

  computed: {
    classesForSendButton() {
      return {
        "service-connection__send-button": true,
        "service-connection__send-button--blocked": this.$v.$invalid,
      };
    },
  },

  created() {
    this.loadCitiesList();
  },

  methods: {
    ...mapActions("Info", ["loadCitiesList"]),

    goBack() {
      this.$router.push({ name: "Login" });
    },

    onCitySelect(city) {
      this.cityId = city.id;
    },

    async sendServiceConnectionRequest() {
      const requestInfo = {
        name: this.name,
        phone: this.phone,
        email: this.email,
        cityId: this.cityId,
      };

      this.name = "";
      this.phone = "";
      this.email = "";
      this.cityId = "";

      await UserService.serviceConnectionRequest(requestInfo);

      // this.$router.push("/login/service-connection-success");
    },

    test() {
      this.name = "Любомир";
      this.phone = "79999999999";
      this.email = "ksdjflksdfj230rj3e0fij@yopmail.com";
      this.cityId = "06814fb6-0dc3-4bec-ba20-11f894a0faf5";
    },
  },
};
</script>

<style scoped lang="scss">
.service-connection {
  width: 332px;
  max-width: 96%;

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 50px;
  }

  &__title {
    font-size: 24px;
    font-weight: 500;
    line-height: 1;
  }

  &__help-info {
    margin-top: 24px;
    color: $grey-600;
    font-size: 13px;

    & > :first-child {
      margin-bottom: 6px;
    }
  }

  &__back {
    display: flex;
    align-items: center;
    margin-right: 20px;
    cursor: pointer;
  }

  &__input {
    margin-bottom: 52px;
  }

  &__send-button {
    @include gradient-button;

    margin-top: 44px;
  }
}
</style>
