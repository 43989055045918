import UploadingFiles from "./common/uploading-files";

function resetState() {
  return {
    message: "",
  };
}

export default {
  namespaced: true,

  state: resetState(),

  mutations: {
    RESET_STATE(state) {
      Object.assign(state, resetState());
    },
  },

  actions: {
    resetState({ commit, dispatch }) {
      dispatch("UploadingFiles/resetState");

      commit("RESET_STATE");
    },
  },

  modules: {
    UploadingFiles: UploadingFiles(),
  },
};
