var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.downloads.length != 0
    ? _c("div", { staticClass: "reference-downloads" }, [
        _c("div", { staticClass: "reference-downloads__group" }, [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "reference-downloads__links" },
            [
              _vm._l(_vm.downloads, function (download) {
                return [
                  download.type == "windows"
                    ? _c(
                        "DownloadsLink",
                        _vm._b(
                          { key: download.url_download },
                          "DownloadsLink",
                          download,
                          false
                        )
                      )
                    : _vm._e(),
                ]
              }),
            ],
            2
          ),
          _vm._v(" "),
          _vm._m(1),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "reference-downloads__links" },
            [
              _vm._l(_vm.downloads, function (download) {
                return [
                  download.type == "wp"
                    ? _c(
                        "DownloadsLink",
                        _vm._b(
                          { key: download.url_download },
                          "DownloadsLink",
                          download,
                          false
                        )
                      )
                    : _vm._e(),
                ]
              }),
            ],
            2
          ),
          _vm._v(" "),
          _vm._m(2),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "reference-downloads__links" },
            [
              _vm._l(_vm.downloads, function (download) {
                return [
                  download.type == "linux"
                    ? _c(
                        "DownloadsLink",
                        _vm._b(
                          { key: download.url_download },
                          "DownloadsLink",
                          download,
                          false
                        )
                      )
                    : _vm._e(),
                ]
              }),
            ],
            2
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "reference-downloads__group" }, [
          _vm._m(3),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "reference-downloads__links" },
            [
              _vm._l(_vm.downloads, function (download) {
                return [
                  download.type == "ios" || download.type == "mac_os"
                    ? _c(
                        "DownloadsLink",
                        _vm._b(
                          { key: download.url_download },
                          "DownloadsLink",
                          download,
                          false
                        )
                      )
                    : _vm._e(),
                ]
              }),
            ],
            2
          ),
          _vm._v(" "),
          _vm._m(4),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "reference-downloads__links" },
            [
              _vm._l(_vm.downloads, function (download) {
                return [
                  download.type == "android"
                    ? _c(
                        "DownloadsLink",
                        _vm._b(
                          { key: download.url_download },
                          "DownloadsLink",
                          download,
                          false
                        )
                      )
                    : _vm._e(),
                ]
              }),
            ],
            2
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "reference-downloads__head-of-group" }, [
      _c("img", {
        staticClass: "reference-downloads__os-logo",
        attrs: { src: require("@icons/reference/windows.svg") },
      }),
      _vm._v("\n      Windows\n    "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "reference-downloads__head-of-group" }, [
      _c("img", {
        staticClass: "reference-downloads__os-logo",
        attrs: { src: require("@icons/reference/windows10.svg") },
      }),
      _vm._v("\n      Приложение для Windows 10\n    "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "reference-downloads__head-of-group" }, [
      _c("img", {
        staticClass: "reference-downloads__os-logo",
        attrs: { src: require("@icons/reference/linux.svg") },
      }),
      _vm._v("\n      Linux\n    "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "reference-downloads__head-of-group" }, [
      _c("img", {
        staticClass: "reference-downloads__os-logo",
        attrs: { src: require("@icons/reference/ios.svg") },
      }),
      _vm._v("\n      Mac OS, IOS\n    "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "reference-downloads__head-of-group" }, [
      _c("img", {
        staticClass: "reference-downloads__os-logo",
        attrs: { src: require("@icons/reference/android.svg") },
      }),
      _vm._v("\n      Android\n    "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }