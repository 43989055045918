import { AuthService } from "./auth.service";

import axiosClient from "./axiosClient.js";

const ContractOfferService = {
  async fetchOfferForm(orgId, formId) {
    const url = `/organization/${orgId}/offer/form/${formId}`;

    const response = await axiosClient.get(url);

    return response.data;
  },

  async uploadFile(orgId, file) {
    const url = `/organization/${orgId}/offer/payment`;

    const csrf = await AuthService.fetchCSRF();

    const formData = new FormData();

    formData.append("file", file, file.name);

    const response = await axiosClient.post(url, formData, {
      headers: {
        "x-csrf-token": csrf,
      },
    });

    return response.data;
  },
};

export { ContractOfferService };
