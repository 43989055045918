<template>
  <div ref="view" class="view" @scroll="scrollList">
    <template v-if="isQuestionnaireLoading">
      <div class="center">
        <AppIcon src="@icons/bubble-loader.svg" class="loader-icon" />
      </div>
    </template>
    <template v-else>
      <div v-if="isQuestionnaireAllowed" class="view__main">
        <div class="title-wr">
          <div class="view__title">Анкета по используемым технологиям</div>
          <QuestionnaireHistory
            v-if="questionnaire.history && questionnaire.history.length"
            :history="questionnaire.history"
            class="questionnaire-history"
          />
        </div>

        <div
          v-for="(theme, index) in questionnaire.themes"
          :key="index"
          class="section"
        >
          <div class="section-title">{{ theme.topic_name }}</div>

          <div
            v-for="question in theme.questions"
            :key="question.id"
            class="question"
            :class="{
              'question--disabled': isEditMode && !activeQuestions[question.id],
            }"
          >
            <div class="question-text">
              {{ question.number }}. {{ question.text }}
              <OpenInstruction
                v-if="question.instruction"
                :question="question"
                class="question-hint"
              />
            </div>

            <div class="question-content">
              <!-- eslint-disable vue/no-v-html -->
              <div
                v-if="question.description"
                class="question-description"
                v-html="sanitizeHtml(question.description)"
              ></div>
              <!--eslint-enable-->

              <template v-if="question.type === 'table'">
                <div class="question-table">
                  <div
                    v-for="(answer, rowIndex) in question.answer"
                    :key="rowIndex"
                    :class="{
                      'question-table__row--tablet':
                        isSreenSizeSmallerOrEqualSM,
                    }"
                    class="question-table__row"
                  >
                    <div
                      v-for="(column, columnIndex) in question.columns"
                      :key="columnIndex"
                      :class="{
                        'question-table__cell--tablet':
                          isSreenSizeSmallerOrEqualSM,
                        'question-table__cell--radio':
                          !isSreenSizeSmallerOrEqualSM &&
                          column.type === 'radio',
                      }"
                      class="question-table__cell"
                    >
                      <template v-if="column.type === 'text'">
                        <div class="question-table__header-cell">
                          {{ column.name }}
                        </div>
                        <QuestionText
                          v-model="answer[columnIndex].value"
                          :options="column.variants"
                          :disabled="!isEditMode"
                          :changed-all="answer[columnIndex].changed"
                          :show-changes="showChanges"
                          :in-table="true"
                          @focus="clearQuestionError(question)"
                          @input="answerChange(question)"
                        />
                      </template>

                      <template v-else-if="column.type === 'radio'">
                        <div class="question-table__header-cell">
                          {{ column.name }}
                        </div>
                        <QuestionRadio
                          v-model="answer[columnIndex].value"
                          :options="column.variants"
                          :disabled="!isEditMode"
                          :changed-all="answer[columnIndex].changed"
                          :show-changes="showChanges"
                          :in-table="true"
                          @change="
                            clearQuestionError(question);
                            answerChange(question);
                          "
                        />
                      </template>

                      <!-- checkbox в table не может быть (от 1С) -->
                    </div>

                    <AppIcon
                      v-if="question.answer.length > 1 && isEditMode"
                      name="close"
                      class="question-table__remove-row"
                      @click.native="
                        removeTableAnswer(question.answer, rowIndex)
                      "
                    />
                  </div>
                  <div
                    v-if="
                      question.add_answer_btn &&
                      isEditMode &&
                      question.answer.length < 5
                    "
                    class="question-table__button"
                  >
                    <AppIcon name="plus" />
                    <div
                      class="question-table__button-title"
                      @click="addTableAnswer(question)"
                    >
                      {{ question.add_answer_btn }}
                    </div>
                  </div>
                </div>
                <div v-if="question.errorMessage" class="error-message">
                  {{ question.errorMessage }}
                </div>
              </template>

              <template v-else-if="question.type === 'text'">
                <QuestionText
                  v-model="question.answer"
                  :disabled="!isEditMode"
                  :changed="question.changed"
                  :show-changes="showChanges"
                  :error-message="question.errorMessage"
                  @focus="clearQuestionError(question)"
                  @input="answerChange(question)"
                />
              </template>

              <template v-else-if="question.type === 'radio'">
                <QuestionRadio
                  v-model="question.answer"
                  :options="question.variants"
                  :disabled="!isEditMode"
                  :show-changes="showChanges"
                  :own="question.own"
                  :own-text="question.own_text"
                  :own-changed="question.own_changed"
                  @change="
                    clearQuestionError(question);
                    answerChange(question);
                  "
                  @inputOwnText="
                    question.own_text = $event;
                    answerChange(question);
                  "
                />

                <div v-if="question.errorMessage" class="error-message">
                  {{ question.errorMessage }}
                </div>
              </template>

              <template v-else-if="question.type === 'checkbox'">
                <QuestionCheckbox
                  v-model="question.answer"
                  :options="question.variants"
                  :disabled="!isEditMode"
                  :show-changes="showChanges"
                  :own="question.own"
                  :own-text="question.own_text"
                  :own-changed="question.own_changed"
                  @input="
                    clearQuestionError(question);
                    answerChange(question);
                  "
                  @inputOwnText="
                    question.own_text = $event;
                    answerChange(question);
                  "
                />

                <div v-if="question.errorMessage" class="error-message">
                  {{ question.errorMessage }}
                </div>
              </template>
            </div>
          </div>
        </div>

        <div class="buttons">
          <ButtonWithLoader
            class="button btn btn--yellow uppercase"
            @click="submit"
          >
            <span v-if="isEditMode">Отправить</span>
            <span v-if="!isEditMode">Подтвердить</span>
          </ButtonWithLoader>
          <ButtonWithLoader
            v-if="!isEditMode"
            class="button btn btn--yellow btn--edit uppercase"
            @click="startEdit"
          >
            Редактировать
          </ButtonWithLoader>
          <div v-if="!valid" class="not-valid">Заполнены не все поля</div>
        </div>
      </div>

      <div
        v-if="isQuestionnaireAllowed"
        class="scroll-to"
        @click="scrollTo(scrollPosition > 200 ? 'up' : 'down')"
      >
        <AppIcon
          name="chevron-down"
          class="scroll-to-icon"
          :class="{ 'scroll-to-icon--up': scrollPosition > 200 }"
        />
      </div>

      <div v-if="!isQuestionnaireAllowed" class="empty">
        <div class="empty__bg">
          <AppIcon name="file-x" />
        </div>
        <div class="empty__text">Нет данных</div>
      </div>

      <ConfirmModal
        :is-opened="isConfirmModalOpened"
        :questionnaire-for1c="questionnaireFor1c"
        @close="onConfirmModalClose"
      />

      <SuccessModal
        :is-opened="isSuccessModalOpened"
        @close="isSuccessModalOpened = false"
      />
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import AppIcon from "@/components/common/Icon.vue";
import ButtonWithLoader from "@/components/common/ButtonWithLoader.vue";
import OpenInstruction from "@/components/Account/OpenInstruction.vue";
import QuestionnaireHistory from "@/components/Account/QuestionnaireHistory.vue";
import QuestionText from "@/components/Account/QuestionText.vue";
import QuestionRadio from "@/components/Account/QuestionRadio.vue";
import QuestionCheckbox from "@/components/Account/QuestionCheckbox.vue";
import ConfirmModal from "@/components/Account/ConfirmModal.vue";
import SuccessModal from "@/components/Account/SuccessModal.vue";
import throttle from "lodash-es/throttle";
// import sanitizeHtml from "sanitize-html";

// Статусы анкеты:
//  - none- Отсутствует
//  - saved - ничего не делать, отображать тоже самое что и при none
//  - initial - Первоначальная (анкета сразу в режиме редактирования, кнопка Отправить)
//  - initial_completed - если партнер заполнил анкету до отправки ему письма (предлагать Редактировать и Подтвердить)
//  - repeated - Повторная
//  - modified_on - Модифицированная c отображения
//  - modified_off - Модифицированная без отображения

export default {
  name: "TechAuditView",

  components: {
    AppIcon,
    ButtonWithLoader,
    OpenInstruction,
    QuestionnaireHistory,
    QuestionText,
    QuestionRadio,
    QuestionCheckbox,
    ConfirmModal,
    SuccessModal,
  },

  inject: ["screenSize"],

  data() {
    return {
      questionnaire: {},
      questionnaireFor1c: {},
      isEditMode: false,
      valid: true,
      isConfirmModalOpened: false,
      isSuccessModalOpened: false,
      scrollPosition: 0,
    };
  },

  computed: {
    ...mapGetters({
      orgId: "Organizations/ActiveOne/getId",
      isQuestionnaireLoading: "TechAudit/areQuestionnaireLoading",
    }),

    isQuestionnaireAllowed() {
      return (
        this.questionnaire.status !== "none" &&
        this.questionnaire.status !== "saved"
      );
    },

    showChanges() {
      return this.questionnaire.status === "modified_on";
    },

    isSreenSizeSmallerOrEqualSM() {
      return this.screenSize.smallerOrEqual("sm").value;
    },

    questionsHash() {
      const hash = {};

      this.questionnaire.themes.forEach((theme) => {
        theme.questions.forEach((question) => {
          hash[question.id] = question;
        });
      });

      return hash;
    },
    activeQuestions() {
      const questions = {};

      Object.keys(this.questionsHash).forEach((questionId) => {
        const question = this.questionsHash[questionId];

        if (question.dependent?.length) {
          // условие всегда ИЛИ
          // Правило: Вопрос не активен если хотя бы в одном условии у вопроса с id таким-то ответ равен что в value
          // Тоже самое, но по-другому: Вопрос активен если в каждом условии у вопроса с id таким-то ответ не равен что в value
          const isActive = question.dependent.every((depend) => {
            const dependQuestion = this.questionsHash[depend.id];

            if (dependQuestion) {
              return dependQuestion.answer !== depend.value;
            } else {
              return true;
            }
          });

          if (!isActive) {
            question.errorMessage = "";
          }

          questions[question.id] = isActive;
        } else {
          questions[question.id] = true;
        }
      });

      return questions;
    },
  },

  async created() {
    this.questionnaire = this.encodeFrom1cFormat(
      await this.fetchQuestionnaire(this.orgId)
    );

    this.isEditMode = this.questionnaire.status === "initial"; // Если status "initial", то сразу в режим редактирования
  },

  methods: {
    ...mapActions({
      fetchQuestionnaire: "TechAudit/fetchQuestionnaire",
      // sendQuestionnaire: "TechAudit/sendQuestionnaire",
    }),

    startEdit() {
      this.isEditMode = true;
    },

    addTableAnswer(question) {
      const newRow = question.columns.map(() => ({ value: null }));
      question.answer.push(newRow);
    },

    removeTableAnswer(rows, rowIndex) {
      rows.splice(rowIndex, 1);
    },

    clearQuestionError(question) {
      question.errorMessage = "";
    },

    // при любом изменении ответа на вопрос - обновляем временную метку у него
    answerChange(question) {
      question.date_filling = new Date().toUTCString(); // Wed, 16 Nov 2022 12:44:33 GMT
    },

    /**
     * Т.к. в 1С данные для checkbox и radio храняться по-другому (значение true/false у каждого элемента), то переделаем в нужный формат
     */
    encodeFrom1cFormat(questionnaire) {
      let questionNumber = 0;

      questionnaire.themes.forEach((theme) => {
        theme.questions.forEach((question) => {
          question.number = ++questionNumber;
          question.errorMessage = "";

          if (question.type === "radio") {
            question.answer = "";
            question.variants.forEach((variant) => {
              // TODO: т.к. есть пустой вопрос в анкете (name = null)
              if (!variant.name) {
                variant.name = " ";
              }

              if (variant.answer) {
                question.answer = variant.name;
              }
            });

            if (question.own_text) {
              question.answer = "Другое";
            }
          }

          if (question.type === "checkbox") {
            question.answer = [];
            question.variants.forEach((variant) => {
              if (variant.answer) {
                question.answer.push(variant.name);
              }
            });

            if (question.own_text) {
              question.answer.push("Другое");
            }
          }
        });
      });

      return questionnaire;
    },

    /**
     * Перед отправкой заполненной анкеты - переделываем обратно в нужный для 1С формат
     */
    decodeTo1cFormat(questionnaire, status) {
      questionnaire = JSON.parse(JSON.stringify(questionnaire));
      questionnaire.date_dispatch = new Date().toUTCString(); // Wed, 16 Nov 2022 12:44:33 GMT - относительно 0-го часового пояса
      questionnaire.time_zone = -(new Date().getTimezoneOffset() / 60); // отправляем на бэк смещение часового пояса (для скачивания анкеты)
      questionnaire.status = status;
      delete questionnaire.history;

      questionnaire.themes.forEach((theme) => {
        theme.questions.forEach((question) => {
          delete question.number;
          delete question.errorMessage;
          delete question.changed;

          if (question.type === "table") {
            question.answer.forEach((answer) => {
              answer.forEach((column) => {
                delete column.changed;
              });
            });
          }

          if (question.type === "radio") {
            question.variants.forEach((variant) => {
              if (question.answer === variant.name) {
                variant.answer = true;

                if (question.own) {
                  question.own_text = "";
                }
              } else {
                variant.answer = false;
              }

              delete variant.changed;
            });

            delete question.answer;
          }

          if (question.type === "checkbox") {
            question.variants.forEach((variant) => {
              if (question.answer.includes(variant.name)) {
                variant.answer = true;
              } else {
                variant.answer = false;
              }

              delete variant.changed;
            });

            if (question.own && !question.answer.includes("Другое")) {
              question.own_text = "";
            }

            delete question.answer;
          }
        });
      });

      return questionnaire;
    },

    validate() {
      let valid = true;

      const errorMessages = {
        required: "Данное поле обязательно для заполнения",
        minLength: "Ответ должен быть не короче 3-х символов",
      };

      const errorMessagesTable = {
        required: "Все поля обязательны для заполнения",
        minLength: "Ответ должен быть не короче 3-х символов",
      };

      const checkAnswer = (data) => {
        if (
          data.answer === undefined ||
          data.answer === null ||
          data.answer.length === 0 ||
          ((data.type === "radio" || data.type === "checkbox") &&
            data.answer === "Другое" &&
            !data.own_text)
        ) {
          return {
            valid: false,
            errorType: "required",
          };
        } else {
          if (data.type === "text" && data.answer.length < 3) {
            return {
              valid: false,
              errorType: "minLength",
            };
          } else {
            return {
              valid: true,
            };
          }
        }
      };

      this.questionnaire.themes.forEach((theme) => {
        theme.questions.forEach((question) => {
          if (this.activeQuestions[question.id]) {
            if (question.type === "table") {
              const tableCheck = {
                valid: true,
              };

              question.answer.forEach((answer) => {
                question.columns.forEach((column, columnIndex) => {
                  const check = checkAnswer({
                    answer: answer[columnIndex].value,
                    type: column.type,
                  });

                  if (!check.valid) {
                    tableCheck.errorMessage =
                      errorMessagesTable[check.errorType];
                    tableCheck.valid = false;
                  }
                });
              });

              if (!tableCheck.valid) {
                question.errorMessage = tableCheck.errorMessage;
                valid = false;
              }
            } else {
              const check = checkAnswer({
                answer: question.answer,
                type: question.type,
                own_text: question.own_text,
              });

              if (!check.valid) {
                question.errorMessage = errorMessages[check.errorType];
                valid = false;
              }
            }
          } else {
            question.errorMessage = "";
          }
        });
      });

      return valid;
    },

    submit() {
      this.valid = this.validate();
      let status = "edited"; // отправляемый статус анкеты, если нажали Отправить

      if (!this.isEditMode) {
        status = "answered"; // отправляемый статус анкеты, если нажали Подтвердить
      }

      if (this.valid) {
        this.questionnaireFor1c = this.decodeTo1cFormat(
          this.questionnaire,
          status
        );
        this.isConfirmModalOpened = true;
      }
    },

    async onConfirmModalClose(result) {
      if (result) {
        // если была успешная отправка
        this.isConfirmModalOpened = false;

        try {
          this.questionnaire = this.encodeFrom1cFormat(
            await this.fetchQuestionnaire(this.orgId)
          );

          this.isEditMode = false;
          this.isSuccessModalOpened = true;
        } catch (error) {
          this.$notify({
            type: "error",
            text: error.message,
            duration: -1,
          });

          throw new Error(error);
        }
      } else {
        // если закрыли окно подтверждения
        this.isConfirmModalOpened = false;
      }
    },

    sanitizeHtml(html) {
      return html;
      // return sanitizeHtml(html);
    },

    scrollList: throttle(function () {
      this.scrollPosition = this.$refs.view.scrollTop;
    }, 200),

    scrollTo(direction) {
      if (direction === "up") {
        this.$refs.view.scroll({ top: 0, behavior: "smooth" });
      } else if (direction === "down") {
        this.$refs.view.scroll({
          top: this.$refs.view.scrollHeight,
          behavior: "smooth",
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
/* stylelint-disable selector-class-pattern */
.view {
  width: 100%;
  flex: 1 0 auto;
  padding: 30px 30px 0; // padding-bottom: 0 для padding-bottom у .buttons
}

.center {
  display: flex;
  height: 100%;
  flex: 1 1 0%;
  align-items: center;
  justify-content: center;
}

.loader-icon {
  width: 64px;
}

.view__main {
  min-height: 100%;
}

.view__title {
  margin-bottom: 28px;
  font-size: 28px;
  font-weight: 500;

  @media (max-width: 960px) {
    width: 100%;
  }
}

.title-wr {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.questionnaire-history {
  @media (max-width: 960px) {
    order: -1;
    margin-bottom: 15px;
  }
}

.section-title {
  margin-top: 30px;
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
}

.question {
  position: relative;
  margin-top: 20px;
}

.question--disabled {
  opacity: 0.5;

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100px;
    content: "";
  }
}

.question-text {
  margin-bottom: 12px;
  font-size: 18px;
  line-height: 27px;
}

.question-hint {
  margin-left: 5px;
}

.question-content {
  padding-left: 23px;
}

.question-description {
  font-size: 18px;
  line-height: 27px;

  ::v-deep ul {
    padding-left: 20px;
    list-style-type: disc;
  }
}

.question-table {
  max-width: 800px;
  margin-top: 40px;
}

.question-table__row {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
  transition: 0.3s;

  &:has(.question-table__remove-row:hover) {
    border-radius: 10px;
    background-color: rgba(255, 0, 0, 0.03);
    box-shadow: 0 0 4px 3px rgba(255, 0, 0, 0.03);
  }
}

.question-table__row--tablet {
  margin-top: 20px;
}

.question-table__header-cell {
  margin-bottom: 10px;
  color: #848484;
  font-size: 12px;
  line-height: 15px;
}

.question-table__cell {
  flex: 1 1 auto;
}

.question-table__cell--tablet {
  width: 100%;
  margin-top: 15px;
}

.question-table__cell--radio {
  margin-right: 14px;
  margin-left: 14px;
}

.question-table__remove-row {
  position: absolute;
  top: calc(50% - 6px);
  right: -20px;
  width: 12px;
  color: $red-400;
  cursor: pointer;
}

.question-table__button {
  display: inline-flex;
  padding: 5px 15px;
  border: 1px solid #dcdcdc;
  border-radius: 5px;
  color: #7b7b7b;
  cursor: pointer;
  transition: 0.3s;
}

.question-table__button:hover {
  background-color: rgba(128, 128, 128, 0.07);
}

.question-table__button-title {
  margin-left: 5px;
  color: #515151;
  font-size: 11px;
}

.buttons {
  position: sticky;
  bottom: 0;
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  margin-top: 30px;
  background: #fff;
}

.btn {
  display: inline-flex;
  width: auto;
  height: 40px;
  padding: 5px 20px;
  font-size: 16px;
  line-height: 19px;
  transition: 0.3s;
}

.btn--yellow {
  background: $yellow-500;
  color: $black;

  &:hover {
    filter: brightness(0.96);
  }
}

.btn--edit {
  margin-left: 12px;
}

.error-message {
  margin-top: 6px;
  color: #f75837;
  font-size: 12px;
}

.not-valid {
  margin-left: 33px;
  color: #f44f44;
}

.empty {
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.empty__bg {
  display: flex;
  width: 190px;
  height: 190px;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  margin-bottom: 32px;
  background-color: #ebebeb;
  color: #b7b7b7;
}

.empty__text {
  color: #333;
  font-size: 26px;
  font-weight: 500;
}

.uppercase {
  text-transform: uppercase;
}

.scroll-to {
  position: fixed;
  right: 30px;
  bottom: 40px;
  display: flex;
  width: 36px;
  height: 36px;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background-color: #609cd3;
  color: #fff;
  cursor: pointer;
  transition: transform 0.3s;
}

.scroll-to-icon {
  width: 14px;
  transition: 0.3s;
}

.scroll-to-icon--up {
  transform: rotate(180deg) translateY(1px);
}
</style>
