var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "reference-view" },
    [
      _c(
        "Portal",
        {
          attrs: {
            to: "header-reference-tabs",
            slim: "",
            disabled: _vm.disabled,
          },
        },
        [
          _c(
            "ViewTabs",
            {
              model: {
                value: _vm.activeTab,
                callback: function ($$v) {
                  _vm.activeTab = $$v
                },
                expression: "activeTab",
              },
            },
            _vm._l(_vm.referenceTabs, function (tab) {
              return _c("AppTab", {
                key: tab.key,
                attrs: { tab: tab, "is-active": _vm.isActive(tab) },
                nativeOn: {
                  click: function ($event) {
                    return _vm.navigate.apply(null, arguments)
                  },
                },
              })
            }),
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "reference-view__main" },
        [
          _c("div", {
            staticClass: "reference-view__content-overflow-top-border",
            class: { visible: !_vm.containerScrollIsInTopPosition },
          }),
          _vm._v(" "),
          _c(
            "FadeTransition",
            { attrs: { mode: "out-in" } },
            [
              _c("router-view", {
                staticClass: "reference-view__content",
                nativeOn: {
                  scroll: function ($event) {
                    return _vm.onScroll.apply(null, arguments)
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }