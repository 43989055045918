var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "root" },
    [
      _vm._l(_vm.eventTypes, function (eventType, key) {
        return _c(
          "div",
          { key: key, staticClass: "root__event-type" },
          [
            _vm._v("\n    " + _vm._s(eventType) + "\n    "),
            _c("SwitchInput", {
              attrs: { checked: _vm.pushSettingFor(key) },
              on: {
                change: function ($event) {
                  return _vm.onChange($event, key)
                },
              },
            }),
          ],
          1
        )
      }),
      _vm._v(" "),
      _vm.pushEventTypesAreLoading
        ? _c("div", { staticClass: "root__loading-overlay" }, [
            _c("img", {
              attrs: { src: require("@icons/bubble-loader.svg"), width: "40" },
            }),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }