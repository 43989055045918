import { render, staticRenderFns } from "./OpenInstruction.vue?vue&type=template&id=e8823fb4&scoped=true&"
import script from "./OpenInstruction.vue?vue&type=script&lang=js&"
export * from "./OpenInstruction.vue?vue&type=script&lang=js&"
import style0 from "./OpenInstruction.vue?vue&type=style&index=0&id=e8823fb4&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e8823fb4",
  null
  
)

export default component.exports