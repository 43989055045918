import Attachments from "./attachments";

function resetState() {
  return {
    activeTab: { key: "participants", name: "Участники" },
  };
}

export default {
  namespaced: true,

  state: resetState(),

  getters: {
    getActiveTab(state) {
      return state.activeTab;
    },
  },

  mutations: {
    SET_ACTIVE_TAB(state, activeTab) {
      state.activeTab = activeTab;
    },

    RESET_STATE(state) {
      Object.assign(state, resetState());
    },
  },

  actions: {
    selectTab({ commit }, tab) {
      commit("SET_ACTIVE_TAB", tab);
    },

    resetState({ commit, dispatch }) {
      dispatch("Attachments/resetState");

      commit("RESET_STATE");
    },
  },

  modules: {
    Attachments,
  },
};
