import cond from "lodash-es/cond";

function setType(type) {
  return function _for(message) {
    if (message?.system?.type == 'new_thread' && !message?.thread) {
      message.template = 'inline'
    } else {
      message.template = type;
    }
  };
}

export default cond([
  [["type", "message"], setType("basic")],

  [
    ["type", "assignment_request"],
    // eslint-disable-next-line no-undef
    setType(VUE_APP_HIDE_ASSIGNMENTS_IN_CHAT_SECTION ? "hidden" : "basic"),
  ],

  [
    ["type", "assignment"],
    // eslint-disable-next-line no-undef
    setType(VUE_APP_HIDE_ASSIGNMENTS_IN_CHAT_SECTION ? "hidden" : "basic"),
  ],

  [["type", "email"], setType("basic")],

  [["type", "phonecall"], setType("inline")],

  [["document.journal.status", "formed"], setType("hidden")],

  [["type", "document"], setType("basic")],

  [["type", "evaluation_request"], setType("basic")],

  // Замечание к журналу
  [["system.type", "journal_comment"], setType("basic")],

  // Сообщение с файлами выписки
  [["system.type", "statement_files"], setType("basic")],

  // Партия файлов закрывающих документов
  [["system.type", "closing_files"], setType("basic")],

  //Создание треда
  [["system.type", "new_thread"], setType("basic")],

  // Заявка на приостановку уведомлений по выпискам (в разделе Документы)
  [
    ["system.type", "suspension_request"],
    // eslint-disable-next-line no-undef
    setType(VUE_APP_HIDE_DOCUMENTS_SECTION ? "hidden" : "inline"),
  ],

  // остальные "системные"
  [["type", "system"], setType("inline")],

  // every else
  [() => true, setType("hidden")],
]);
