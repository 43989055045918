import Vue, { computed } from "vue";
import store from "@/store";
import { useQuery } from "@tanstack/vue-query";
import { InfoService } from "@/services";

const orgId = computed(() => store.getters["Organizations/ActiveOne/getId"]);

export default function useDocumentsStateQuery() {
  const data = useQuery(
    ["documents", orgId.value],
    () => InfoService.fetchDocuments(orgId.value),
    {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      retry: false,
      onError: () => {
        Vue.notify({
          type: "error",
          text: "Ошибка при получении списка документов",
        });
      },
    }
  );

  return data;
}
