<template>
  <AppTabs class="view-tabs" v-bind="$props" v-on="$listeners">
    <slot></slot>
  </AppTabs>
</template>

<script>
import AppTabs from "../common/Tabs.vue";

export default {
  name: "ViewTabs",

  components: { AppTabs },

  props: {
    value: {
      type: Object,
      required: true,
    },

    sliderClass: {
      type: String,
      default: "",
    },

    tabsClass: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped lang="scss">
.view-tabs {
  ::v-deep .tabs__overflow-container {
    border-radius: 10px 10px 0 0;
    box-shadow: 0 10px 0 white, 0 0 4px 0 rgba(0, 0, 0, 0.1);
  }

  ::v-deep .tabs__slider {
    display: none;
  }

  @media (max-width: 600px) {
    ::v-deep .tabs__slider {
      z-index: 1;
      display: block;
      background: #de1010;
    }
  }
}
</style>
