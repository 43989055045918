<template>
  <div class="reference-instructions">
    <router-link
      :to="{ name: 'ReferenceHelp' }"
      class="reference-instructions__back"
    >
      вернуться назад
    </router-link>

    <!-- // 1 -->
    <FadeGroupTransition>
      <div v-if="$route.params.id == 1" key="1">
        <h1>Ручная настройка списка информационных баз</h1>
        <ul>
          <li>
            В список информационных баз ТК могут быть добавлены как приложения
            сервиса 1С:БухОбслуживание, так и другие информационные базы,
            доступные пользователю. По вопросам подключения информационных баз,
            расположенных вне сервиса 1С:БухОбслуживание, следует обращаться к
            администраторам этих информационных баз.
          </li>
          <li>
            Для добавления в список информационной базы &mdash; приложения
            сервиса 1С:БухОбслуживание
            <ul>
              <li>
                Откройте ваше приложение в браузере (так, как вы обычно
                работаете с ним в сервисе)
              </li>
              <li>
                Скопируйте интернет-адрес приложения в буфер обмена
                <img src="../../assets/images/reference/1/pic-1.png" alt="" />
              </li>
              <li>
                В окне запуска 1С:Предприятия нажмите кнопку
                &laquo;Добавить&raquo; и выберите &laquo;Добавление в список
                существующей информационной базы&raquo;. Нажмите
                &laquo;Далее&raquo;.
                <img src="../../assets/images/reference/1/pic-2.png" alt="" />
              </li>
              <li>
                В поле &laquo;Наименование информационной базы&raquo; укажите
                понятное Вам название добавляемого приложения.
              </li>
              <li>
                Выберите тип расположения информационной базы &laquo;На
                веб-сервере&raquo; и вставьте в поле &laquo;Адрес информационной
                базы&raquo; ранее скопированный Вами из браузера адрес
                приложения. Удалите окончание &laquo;/ru_RU/&raquo;
              </li>
              <li>
                Нажмите &laquo;Далее&raquo;, &laquo;Готово&raquo;.
                Информационная база добавлена в список, Вы можете выполнить ее
                запуск и начать работу
              </li>
            </ul>
          </li>
        </ul>
        <h3>Автоматическая настройка списка информационных баз</h3>
        <ul>
          <li>
            Сервис 1С:БухОбслуживание предоставляет механизм, выполняющий
            автоматическую регистрацию и обновление списка доступных
            информационных баз на компьютере пользователя.
          </li>
          <li>
            Порядок подключения к службе списка информационных баз
            <ul>
              <li>
                В окне списка информационных баз нажмите кнопку
                &laquo;Настройка&raquo;.
              </li>
              <li>
                В открывшемся окне настроек установите флаг &laquo;Отображать в
                виде дерева&raquo;.
              </li>
              <li>
                В разделе &laquo;Адреса интернет-сервисов и списки общих
                информационных баз&raquo; нажмите кнопку &laquo;Добавить&raquo;.
              </li>
              <li>
                В поле &laquo;Интернет-сервис&raquo; введите
                https://1cbo.1c.ru/a/wcib/hs и нажмите &laquo;ОК&raquo; в
                открытых окнах настроек.
              </li>
              <li>
                В случае возникновения проблем с вводом логина и пароля
                попробуйте изменить ссылку на:
                https://1cbo.1c.ru/a/wcib-noopenid/hs
                <img src="../../assets/images/reference/1/pic-4.png" alt="" />
              </li>
              <li>
                Через некоторое время &mdash; обычно две-три секунды &mdash;
                система запросит имя пользователя и пароль для подключения к
                веб-серверу. Введите те учетные данные, с которыми Вы входите в
                приложения 1С:БухОбслуживание. После успешной авторизации список
                информационных баз будет содержать доступные Вам информационные
                базы сервиса 1С:БухОбслуживание
                <img src="../../assets/images/reference/1/pic-5.png" alt="" />
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <!-- // 2 -->
      <div v-else-if="$route.params.id == 2" key="2">
        <h1>
          Инструкция по подключению сервиса 1C-Отчетность в приложении
          &laquo;1С: Предприниматель 2015&raquo;
        </h1>
        <p>
          Подключение к сервису &laquo;1С-Отчетность&raquo; доступно при наличии
          оплаченного периода использования программы &laquo;1С: Предприниматель
          2015&raquo; в сервисе 1С: БухОбслуживание на 6 или 12 месяцев.
        </p>
        <p>
          Перед стартом подключения к сервису 1С-Отчетность зайдите в ваш Личный
          кабинет на сайте https://1cbo.ru и убедитесь, что приложение 1С:
          Предприниматель оплачено.
        </p>
        <p>
          С использованием сервиса 1С-Отчетность доступна отправка через
          Интернет отчетов в ФНС, ПФР, ФСС, Росстат, Росалкогольрегулирование.
        </p>
        <p>
          <strong
            >Подключение к сервису 1С-Отчетность из приложения 1С:
            Предприниматель 2015 включает 3 шага</strong
          >
        </p>
        <p>
          <strong>Шаг 1.</strong> Заявление на подключение к сервису
          &laquo;1С-Отчетность&raquo;.
        </p>
        <p>
          <strong>Шаг 2.</strong> Запрос на подтверждение заявления о
          подключении к сервису &laquo;1С-Отчетность&raquo;, отправка скан-копий
          документов, подтверждающих полномочия руководителя организации/ИП.
        </p>
        <p>
          <strong>Шаг 3.</strong> Одобрение заявления на подключение к
          &laquo;1С-Отчетости&raquo; спецоператором связи &laquo;Калуга
          Астрал&raquo;. Установка сертификата, автоматическая настройка
          документооборота.
        </p>
        <h3>
          Шаг 1 <br />
          Подготовить заявление на подключение к сервису 1С-Отчетность
        </h3>
        <p>Шаг 1 включает <strong>три действия</strong></p>
        <ol>
          <li>
            Подготовить в программе 1С: Предприниматель заявление на подключение
            к сервису электронного документооборота с контролирующими органами
            &mdash; 1С-Отчетность.
          </li>
          <li>Выбор и установка программы-криптопровайдера;</li>
          <li>Настройка электронной подписи (ЭП).</li>
        </ol>
        <p>
          <strong>Действие 1. Оформление заявления</strong><br />
          В форме элемента справочника &laquo;Организации&raquo; выберите пункт
          &laquo;Подключение к 1С-Отчетности&raquo;.
          <img src="../../assets/images/reference/2/pic-1.png" alt="" />
        </p>
        <p>
          Открывшийся Помощник поможет заполнить и отправить заявление на
          подключение. Обратите внимание: оформлять заявление на подключение
          рекомендуется именно с того компьютера, с которого в будущем
          планируется осуществлять документооборот.
        </p>
        <p>
          В случае работы через браузер потребуется установить внешнюю
          компоненту для работы с криптографией.
        </p>
        <p>
          Для установки компоненты необходимо в открывшемся окне нажать кнопку
          Далее. <img src="../../assets/images/reference/2/pic-2.png" alt="" />
        </p>
        <p>
          Убедитесь в том, что внешняя компонента установлена, о чем будет
          свидетельствовать соответствующий диалог, в котором нужно нажать
          кнопку ОК.
          <img src="../../assets/images/reference/2/pic-3.png" alt="" />
        </p>
        <p>
          <strong
            >Действие 2 Выбор и установка программы криптопровайдера</strong
          ><br />
          Для работы с сервисом &laquo;1С-Отчетность&raquo; требуется программа
          для защиты информации &mdash; криптопровайдер.
        </p>
        <p>
          Помощник проверяет наличие установленных на компьютере программ
          &mdash; криптопровайдеров. Если не установлен ни один криптопровайдер,
          то Помощник подключения предложит скачать с сайта производителя и
          установить такую программу. В настоящее время программой
          поддерживаются криптопровайдеры ViPNet CSP (ОАО
          &laquo;ИнфотеКС&raquo;) и CryptoPro CSP (&laquo;КРИПТО-ПРО&raquo;).
        </p>
        <p>
          С условиями распространения средств криптографической защиты
          информации можно ознакомиться на сайтах их разработчиков, ссылки на
          которые приведены на форме.
          <img src="../../assets/images/reference/2/pic-4.png" alt="" />
        </p>
        <p>
          Для завершения установки программы-криптопровайдера компьютер
          потребуется перезагрузить.
        </p>
        <p>
          В случае если происходит закрытие Мастера (из-за установки
          криптопровайдера и перезагрузки компьютера), то при последующем
          запуске программы помощник откроется на этом же шаге автоматически.
          <br />
          После установки криптопровайдера второй шаг будет выглядеть так:
          <img src="../../assets/images/reference/2/pic-5.png" alt="" />
        </p>
        <p>
          Если установлено два криптопровайдера, то будет выдано предупреждение
          о необходимости удалить один из них.
        </p>
        <p>
          <strong
            >Возврат к Действию 1 &laquo;Оформление заявления на подключение к
            сервису 1С-Отчетность&raquo;</strong
          ><br />
          На следующем шаге необходимо ознакомиться с текстом соглашения о
          присоединении к регламенту удостоверяющего центра &mdash; Калуги
          Астрал. Для продолжения нужно подтвердить принятие условий соглашения.
          <img src="../../assets/images/reference/2/pic-6.png" alt="" />
        </p>
        <p>
          Регистрационный номер программы &laquo;1С: Предприниматель 2015&raquo;
          будет сформирован автоматически.
        </p>
        <p>
          Далее необходимо выбрать организацию, которую планируется подключить,
          сотрудника &mdash; владельца сертификата ключа и перечислить
          контролирующие органы в которые планируется сдавать отчетность. При
          установке флажка &laquo;Росстат&raquo; автоматически установится
          флажок &laquo;ФНС&raquo; (подключение к Росстату невозможно без
          подключения к ФНС).
          <img src="../../assets/images/reference/2/pic-7.png" alt="" />
        </p>
        <p>
          Далее требуется убедиться в корректности заполнения сведений об
          организации. Основные реквизиты организации можно отредактировать в
          справочнике, открыв его по ссылке с названием организации.
        </p>
        <p>
          Контактную информацию можно править непосредственно в форме мастера.
          Здесь указываются и контактные телефоны, по которым представитель
          специализированного оператора связи свяжется с пользователем для
          встречи и оформления документов по подключению.
          <img src="../../assets/images/reference/2/pic-8.png" alt="" />
        </p>
        <p>
          На следующем шаге необходимо проверить сведения о лице, на которое
          будет издан сертификат квалифицированной цифровой подписи, необходимый
          для осуществления документооборота. Обычно в качестве такого
          сотрудника выступает руководитель организации. При выборе сотрудника
          программа заполнит поля со сведениями о нем. Эти сведения необходимо
          тщательно проверить и при необходимости заполнить (отредактировать по
          ссылке) недостающие обязательные поля.
          <img src="../../assets/images/reference/2/pic-9.png" alt="" />
        </p>
        <p>
          Далее требуется проверить и при необходимости отредактировать коды
          инспекций ФНС, отделения ПФР, территориального органа Росстата. В
          случае, если организация представляет отчетность не в один налоговый
          орган, указывается несколько кодов ИФНС.
          <img src="../../assets/images/reference/2/pic-10.png" alt="" />
        </p>
        <p>
          Далее будет предложено еще раз проверить правильность всех сведений,
          указанных в заявлении.
          <img src="../../assets/images/reference/2/pic-11.png" alt="" />
        </p>
        <p>
          <strong>Действие 3. Генерация ключа электронной подписи</strong><br />
          Ключ электронной подписи будет сгенерирован с помощью программы
          &mdash; криптопровайдера.
          <img src="../../assets/images/reference/2/pic-12.png" alt="" />
        </p>
        <p>
          Ключ, в зависимости от криптопровайдера, может быть размещен на
          внешнем устройстве или на компьютере пользователя.
          <img src="../../assets/images/reference/2/pic-13.png" alt="" />
        </p>
        <p>
          После выбора размещения электронного ключа будет предложено задать
          пароль.
          <img src="../../assets/images/reference/2/pic-14.png" alt="" />
        </p>
        <p>
          При создании ключа для работы генератора случайных чисел потребуется в
          произвольном порядке нажимать клавиши на клавиатуре или двигать мышью.
          <img src="../../assets/images/reference/2/pic-15.png" alt="" />
        </p>
        <p>
          По завершении создания ключа потребуется еще раз ввести пароль, и
          заявление будет передано через Интернет спецоператору связи.
          <img src="../../assets/images/reference/2/pic-16.png" alt="" />
          <img src="../../assets/images/reference/2/pic-17.png" alt="" />
        </p>
        <h3>
          Шаг 2<br />
          Направить запрос на подтверждение заявления о подключении к сервису
          1С-Отчетность.
        </h3>
        <p>
          Запрос на подтверждение заявления о подключении к сервису
          1С-Отчетность направляется в фирму &laquo;1С&raquo;, как представителя
          спецоператора связи, на адрес bo@1c. ru и включает: <br />
          1. Письмо с темой &laquo;1С-Отчетность для 1С: Предприниматля
          2015&raquo; и текстом по шаблону:<br />
          &laquo;Прошу подтвердить заявление о подключении
          &not;&not;___________________(ИНН/КПП)
        </p>
        <p>
          К защищенному электронному документообороту. Скан-копии документов,
          подтверждающих полномочия руководителя ______________________________
          в приложении&raquo;.
        </p>
        <p>
          2. Скан-копии документов, подтверждающих полномочия руководителя
          организации/ИП во вложении к письму.<br />
          a) для юридического лица:
        </p>
        <ul>
          <li>
            копия свидетельства о постановке на учет организации-клиента, с
            указанием ИНН/КПП;
          </li>
          <li>
            копия общегражданского паспорта руководителя (достаточно разворота с
            ФИО);
          </li>
          <li>
            заверенная копия приказа о назначении руководителя на должность (или
            другой документ, подтверждающий, что этот человек &mdash;
            руководитель)
          </li>
          <li>
            копия Соглашения об ЭДО с ОПФР (в случае, если в заявке указано
            такое направление сдачи отчетности).
          </li>
        </ul>
        <p>b) для индивидуального предпринимателя:</p>
        <ul>
          <li>копия свидетельства о постановке на учет с указанием ИНН;</li>
          <li>
            копия общегражданского паспорта ИП (достаточно разворота с ФИО);
          </li>
          <li>
            копия Соглашения об ЭДО с ОПФР (в случае, если в заявке указано
            такое направление сдачи отчетности).
          </li>
        </ul>
        <p>
          После получения запроса сотрудник фирмы &laquo;1С&raquo; свяжется с
          пользователем, используя данные, указанные в секции &laquo;Контактная
          информация&raquo; заявления, для решения организационных вопросов по
          подключению.
        </p>
        <h3>
          Шаг 3. <br />
          Одобрение заявления на подключение к &laquo;1С-Отчетости&raquo;
          спецоператором связи &laquo;Калуга Астрал&raquo;. <br />
          Установка сертификата, автоматическая настройка документооборота.
        </h3>
        <p>
          Для продолжения работы требуется дождаться одобрения заявления на
          подключение к сервису 1С-Отчетность от спецоператора связи
          Калуга-Астрал.
        </p>
        <p>
          Увидеть статус заявления можно так: подсистема
          Отчеты/Регламентированные отчеты/Настройки, далее &laquo;Список
          заявлений&raquo;.
        </p>
        <p>Из Списка заявлений можно перейти к конкретному заявлению.</p>
        <p>
          Для проверки статуса заявления можно воспользоваться кнопкой
          &laquo;Обновить статусы заявлений&raquo;. Программа автоматически
          предложит обновить статус заявления с сервера спецоператора, если с
          момента отправки прошло больше суток.
          <img src="../../assets/images/reference/2/pic-18.png" alt="" />
        </p>
        <p>
          В процессе подключения удостоверяющим центром будет издан сертификат
          ключа электронной подписи на имя сотрудника, указанного при оформлении
          заявки, и на сервере спецоператора для нового абонента будет создана
          учетная запись, которая необходима для осуществления документооборота.
        </p>
        <p>
          В случае если при подключении к сервису &laquo;1С-Отчетность&raquo;
          возникла ошибка, то причину можно посмотреть в Помощнике.
          <img src="../../assets/images/reference/2/pic-19.png" alt="" />
        </p>
        <p>
          Если заявление одобрено, можно приступать к завершению настройки
          электронного документооборота с контролирующими органами, при этом на
          экране будет показана следующая форма.
          <img src="../../assets/images/reference/2/pic-20.png" alt="" />
        </p>
        <p>
          Потребуется еще раз ввести пароль.
          <img src="../../assets/images/reference/2/pic-21.png" alt="" />
        </p>
        <p>
          Далее будет предложено выбрать пользователей, которые смогут
          использовать 1С-Отчетность по организации. Если в системе только один
          пользователь этот шаг будет пропущен.
          <img src="../../assets/images/reference/2/pic-22.png" alt="" />
        </p>
        <p>
          И на заключительном шаге будет показан следующий диалог.
          <img src="../../assets/images/reference/2/pic-23.png" alt="" />
        </p>
        <p>
          После того как все необходимые настройки произведены, пользователь
          может незамедлительно приступить к документообороту (отправке
          отчетности и обмену прочими документами).
        </p>
        <p>
          В будущем, при изменении настроек документооборота (например, смене
          сертификатов в контролирующем органе), эти изменения будут
          автоматически отражаться в информационной базе &mdash; пользователю не
          придется отслеживать и отражать изменения в настройках программы
          самостоятельно.
          <img src="../../assets/images/reference/2/pic-24.png" alt="" />
        </p>
      </div>
      <!-- // 3 -->
      <div v-else-if="$route.params.id == 3" key="3">
        <h1>Работа через тонкий клиент 1С</h1>
        <h3>Назначение Тонкого клиента 1С:Предприятие</h3>
        <p>
          Тонкий клиент 1С:Предприятие (ТК) &mdash; это специальная версия
          программ 1С:Предприятие, позволяющая работать с информационными
          базами, размещенными в облачных сервисах или на сервере
          1С:Предприятие, в частности &mdash; с информационными базами в сервисе
          1С:БухОбслуживание.
        </p>
        <p>
          Для работы в приложениях сервиса 1С:БухОбслуживание рекомендуется
          использовать именно ТК в связи с его большей устойчивостью, скоростью
          работы и диагностируемостью по сравнению с браузерами.
        </p>
        <h3>Системные требования</h3>
        <p>
          Минимальные системные требования ТК соответствуют таковым для
          платформы 1С:Предприятие 8.3 и опубликованы в составе документации по
          платформе 1С:Предприятие:
        </p>
        <ul>
          <li>
            Операционная система Windows XP Service Pack 2, Windows Server 2003
            и выше, Fedora 17, Mint 12, Ubuntu 12.04 LTS, Alt Linux СПТ 6.0
          </li>
          <li>Процессор Intel Pentium/Celeron 1800 МГц и выше</li>
          <li>Оперативная память 1 Гбайт и выше</li>
          <li>Жесткий диск (при установке используется около 70 Мбайт)</li>
          <li>SVGA-дисплей</li>
        </ul>
        <p>
          Рекомендуемые параметры оборудования, обеспечивающие комфортную работу
          в Тонком клиенте 1С:Предприятие, следующие:
        </p>
        <ul>
          <li>
            Операционная система Windows 7 и выше, Windows Server 2008 и выше
          </li>
          <li>Двухъядерный процессор Intel Core2 Duo и выше</li>
          <li>Оперативная память 2 Гбайт и выше</li>
        </ul>
        <p>
          При несоблюдении рекомендуемых параметров возможно снижение
          быстродействия и стабильности работы ТК.
        </p>
        <h3>Вопросы лицензирования</h3>
        <p>
          Клиентам сервиса 1С:БухОбслуживание предоставляется право
          использования программного обеспечения &laquo;Тонкий клиент
          1С:Предприятие&raquo; в соответствии с лицензионной политикой 1С.
        </p>
        <p>
          Сервис 1С:БухОбслуживание предоставляет пользователю необходимые
          лицензии 1С:Предприятие при отсутствии таковых у пользователя.
          Лицензии предоставляются только для работы в приложениях сервиса
          1С:БухОбслуживание, на любом компьютере, путем выделения запущенному
          приложению лицензии из пула лицензий сервиса на период сеанса работы.
        </p>
        <h3>Установка ТК</h3>
        <p>
          Установочный пакет Тонкого клиента 1С:Предприятие для Windows Вы
          можете скачать по следующей ссылке: https://1cbo.1c.
          ru/download/setuptc. zip. Версия ТК, расположенная по приведенной
          ссылке, всегда совпадает с версией платформы 1С:Предприятие,
          используемой в сервисе 1С:БухОбслуживание.
        </p>
        <p>
          Для установки ТК требуются права администратора операционной системы.
        </p>
        <p>Порядок установки ТК:</p>
        <ul>
          <li>
            Скачайте установочный пакет Тонкого клиента по ссылке, приведенной
            выше. Сохраните архивный файл установочного пакета на Ваш компьютер.
          </li>
          <li>
            Распакуйте сохраненный архив и двойным кликом запустите файл setup.
            exe. Расширение &laquo;.exe&raquo; может не отображаться в
            зависимости от настроек Вашего компьютера, в этом случае Вы можете
            опознать требуемый файл по виду иконки
            <img src="../../assets/images/reference/3/pic-1.png" alt="" />
            <img src="../../assets/images/reference/3/pic-2.png" alt="" />
          </li>
          <li>
            Следуйте рекомендациям программы установки, нажимая на кнопку
            &laquo;Далее&raquo;. Для завершения установки нажмите кнопку
            &laquo;Готово&raquo;. Какие-либо изменения параметров установки, как
            правило, не требуются.
            <img src="../../assets/images/reference/3/pic-3.png" alt="" />
          </li>
          <li>
            После завершения установки требуется настроить список информационных
            баз вручную либо при помощи службы списка информационных баз
            1С:БухОбслуживание.
          </li>
        </ul>
        <h3>Обновление ТК</h3>
        <p>
          Процесс обновления тонкого клиента 1С:Предприятие представляет собой
          установку новой версии программы. При этом удаление старых версий не
          обязательно, старые версии сохраняют работоспособность. В случае
          отсутствия нужды в старых версиях они могут быть удалены обычными
          методами без нарушения функциональности актуальных версий.
        </p>
        <p>
          Для установки новых версий тонкого клиента 1С:Предприятие могут
          применяться следующие сценарии:
        </p>
        <ul>
          <li>Обновление вручную</li>
          <li>Автоматическое обновление средствами платформы 1С:Предприятие</li>
          <li>Автоматическое обновление средствами администрирования ОС</li>
        </ul>
        <p>
          Выбор конкретного сценария определяется прежде всего требованиями к
          безопасности локальной информационной системы. Ключевым критерием
          безопасности является уровень прав пользователя, в общем случае
          представляемый как ограниченный либо административный.
        </p>
        <p>
          В случае ограниченного набора прав пользователь, как правило, не имеет
          права на установку программного обеспечения. В этом случае установка
          программного обеспечения может проводиться пользователем с временным
          повышением уровня прав либо операционной системой. Сценарий установки
          должен предусматривать такую потребность.<br />
          Если же пользователь обладает административными привилегиями, то
          вопрос достаточности прав для установки программного обеспечения не
          стоит и допустимо использовать любой сценарий.
        </p>
        <p>
          Обновление вручную применяется, как правило, на незначительном
          количестве рабочих мест. При этом процесс установки программы должен
          быть запущен с повышенными привилегиями.
        </p>
        <p>Обновление вручную выполняется идентично установке ТК</p>
        <p>Автоматическое обновление средствами платформы 1С:Предприятие</p>
        <p>
          При запуске информационной базы, подключенной в режиме &laquo;На
          веб-сервере&raquo; тонкий клиент 1С:Предприятие получает информацию о
          требуемой версии платформы и о расположении в интернете файла
          дистрибутива платформы, если на веб-сервере опубликован такой
          дистрибутив. Сервис 1С:БухОбслуживание всегда публикует дистрибутив
          актуальной версии тонкого клиента.
        </p>
        <p>
          При наличии информации о расположении дистрибутива тонкий клиент
          автоматически скачивает файл и запускает процедуру быстрой установки
          (т. е. установка производится без запроса каких-либо параметров у
          пользователя &mdash; используются параметры предыдущей установки),
          после чего перезапускается уже в нужной версии.
        </p>
        <p>
          Обновление происходит полностью автоматически и требует наличия у
          пользователя права на установку программного обеспечения.
        </p>
        <p>Варианты реализации:</p>
        <ul>
          <li>Предоставление пользователю административных привилегий</li>
          <li>
            Включение элемента групповых политик &laquo;Всегда производить
            установку с повышенными привилегиями&raquo; (англ. Always install
            elevated)
          </li>
          <li>Автоматическое обновление средствами администрирования ОС</li>
          <li>
            Развертывание приложения осуществляется в среде Active Directory из
            дистрибутива, опубликованного администратором. Процедура
            развертывания приложения средствами групповых политик Active
            Directory описана в документации Microsoft:
            https://support.microsoft.com/en-us/kb/816102
          </li>
          <li>
            Развертывание приложения рекомендуется выполнять в режиме
            &laquo;Назначенный&raquo; для компьютеров, а не пользователей домена
          </li>
          <li>
            В развертываемом пакете рекомендуется добавить следующие трансформы
          </li>
          <li>
            1049.mst &mdash; обеспечивает русский язык установленного приложения
          </li>
          <li>
            Adminstallrestart. mst &mdash; реализует предложение перезагрузить
            компьютер для выполнения автоматической установки нужной версии
          </li>
          <li>
            Указывать в развертываемом пакете обновляемые пакеты не требуется
          </li>
        </ul>
      </div>
    </FadeGroupTransition>
  </div>
</template>

<script>
import FadeGroupTransition from "../../components/transitions/FadeGroup.vue";

export default {
  name: "ReferenceInstructions",

  components: {
    FadeGroupTransition,
  },

  watch: {
    "$route.params.id": {
      handler(val) {
        if (val) this.$emit("update", this.$el);
      },
    },
  },

  mounted() {
    this.$emit("update", this.$el);
  },
};
</script>

<style scoped lang="scss">
.reference-instructions {
  &__back {
    display: block;
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: 700;
  }

  h1 {
    margin-bottom: 26px;
  }

  h2,
  h3,
  h4,
  p {
    margin-bottom: 16px;
  }

  img {
    display: block;
    max-width: 100%;
    margin: 24px 0;
  }

  ul {
    display: block;
    list-style-type: disc;
    margin-block-end: 1em;
    margin-block-start: 1em;
    margin-inline-end: 0;
    margin-inline-start: 0;
    padding-inline-start: 40px;
  }

  ul ul {
    list-style-type: circle;
    margin-block-end: 0;
    margin-block-start: 0;
  }

  li {
    display: list-item;
    margin-bottom: 15px;
    text-align: -webkit-match-parent;
  }

  @media (max-width: 600px) {
    h1 {
      font-size: 24px;
    }
  }
}
</style>
