import Reference from "../views/Reference.vue";
import Help from "../views/Reference/Help.vue";
import Instructions from "../views/Reference/Instructions.vue";
import FAQ from "../views/Reference/FAQ.vue";
import Downloads from "../views/Reference/Downloads.vue";

export default {
  path: "reference",
  name: "Reference",
  component: Reference,
  meta: {
    withTabs: true,
  },

  children: [
    {
      path: "help",
      component: Help,
      meta: {
        withTabs: true,
      },

      children: [
        {
          path: "",
          name: "ReferenceHelp",
          component: FAQ,
          meta: {
            withTabs: true,
          },
        },

        {
          path: "instructions/:id",
          name: "ReferenceInstructions",
          component: Instructions,
          meta: {
            withTabs: true,
          },
        },
      ],
    },

    {
      path: "downloads",
      name: "ReferenceDownloads",
      component: Downloads,
      meta: {
        withTabs: true,
      },
    },
  ],
};
