var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CabTooltip",
    {
      staticClass: "open-instruction-wr",
      attrs: { placement: "top", timeout: 0 },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function () {
            return [
              _c(
                "a",
                {
                  staticClass: "open-instruction",
                  attrs: { href: _vm.instructionUrl, target: "_blank" },
                },
                [
                  _c("AppIcon", { attrs: { name: "book-open" } }),
                  _vm._v(" "),
                  _vm.question.number === 1 && !_vm.fullHintAlreadyShowed
                    ? _c("span", { staticClass: "full-hint" }, [
                        _vm._v(
                          "\n        Не понятно, как отвечать на вопрос? Воспользуйтесь инструкцией\n      "
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [_vm._v(" "), _c("span", [_vm._v("Открыть инструкцию")])]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }