<template>
  <CabTooltip placement="top" :timeout="0" class="open-instruction-wr">
    <template #activator>
      <a :href="instructionUrl" class="open-instruction" target="_blank">
        <AppIcon name="book-open" />
        <span
          v-if="question.number === 1 && !fullHintAlreadyShowed"
          class="full-hint"
        >
          Не понятно, как отвечать на вопрос? Воспользуйтесь инструкцией
        </span>
      </a>
    </template>
    <span>Открыть инструкцию</span>
  </CabTooltip>
</template>

<script>
import { mapGetters } from "vuex";

import * as Sentry from "@sentry/vue";

import { API_URL } from "@/utils/constants";

import AppIcon from "@/components/common/Icon.vue";
import CabTooltip from "@/components/common/CabTooltip.vue";

export default {
  name: "OpenInstruction",

  components: {
    AppIcon,
    CabTooltip,
  },

  props: {
    question: {
      type: Object,
      default() {},
    },
  },

  data() {
    return {
      fullHintAlreadyShowed: false,
    };
  },

  computed: {
    ...mapGetters({
      orgId: "Organizations/ActiveOne/getId",
      userId: "User/getUserId",
    }),

    instructionUrl() {
      return `${API_URL}/organization/${this.orgId}/questionnaire/instruction/${this.question.id}`;
    },
  },

  mounted() {
    this.fullHintAlreadyShowed = this.getUserHintShowed();

    if (!this.fullHintAlreadyShowed) {
      document.documentElement.addEventListener("click", this.onClickBody);
    }
  },

  beforeDestroy() {
    document.documentElement.removeEventListener("click", this.onClickBody);
  },

  methods: {
    onClickBody() {
      // this.fullHintAlreadyShowed = true;
      this.setUserHintShowed();
    },
    getUserHintShowed() {
      let userHintShowed;

      try {
        userHintShowed =
          JSON.parse(localStorage.getItem("cab-hint-showed"))[this.userId] ||
          false;
      } catch (error) {
        Sentry.captureException(error);

        userHintShowed = false;
      }

      return userHintShowed;
    },
    setUserHintShowed() {
      let hintShowedUsers;

      try {
        hintShowedUsers = JSON.parse(localStorage.getItem("cab-hint-showed"));
        hintShowedUsers[this.userId] = true;
      } catch (error) {
        Sentry.captureException(error);

        hintShowedUsers = {};
        hintShowedUsers[this.userId] = true;
      }

      localStorage.setItem("cab-hint-showed", JSON.stringify(hintShowedUsers));
    },
  },
};
</script>

<style scoped lang="scss">
.open-instruction-wr {
  white-space: nowrap;
}

.open-instruction {
  display: inline-flex;
  min-height: 20px;
  align-items: center;
  padding: 4px 9px;
  border: 1px solid #cfcfcf;
  border-radius: 4px;
  color: #a4a4a4;
  cursor: pointer;
  font-size: 13px;
  line-height: 15px;
  transition: 0.2s;

  &:hover {
    border: 1px solid #609cd3;
    background-color: #609cd3;
    color: #fff;
  }

  & + ::v-deep .app-hint {
    &__body {
      line-height: 16px;
    }
  }
}

.full-hint {
  margin-left: 7px;
}
</style>
