function resetState() {
  return {
    mainAppliedFiltersByTopic: {},
    extraAppliedFiltersByTopic: {},

    mainSelectedFilters: [],
    extraSelectedFilters: [],
  };
}

export default {
  namespaced: true,

  state: resetState(),

  getters: {
    mainAppliedFiltersByTopic({ mainAppliedFiltersByTopic }) {
      return mainAppliedFiltersByTopic;
    },

    extraAppliedFiltersByTopic({ extraAppliedFiltersByTopic }) {
      return extraAppliedFiltersByTopic;
    },

    mainSelectedFilters({ mainSelectedFilters }) {
      return mainSelectedFilters;
    },

    extraSelectedFilters({ extraSelectedFilters }) {
      return extraSelectedFilters;
    },
  },

  mutations: {
    SET_MAIN_APPLIED_FILTERS_BY_TOPIC(state, { topic, filters }) {
      const newFilters = {
        ...state.mainAppliedFiltersByTopic,
        [topic]: filters,
      };

      state.mainAppliedFiltersByTopic = newFilters;
    },

    SET_EXTRA_APPLIED_FILTERS_BY_TOPIC(state, { topic, filters }) {
      const newFilters = {
        ...state.extraAppliedFiltersByTopic,
        [topic]: filters,
      };

      state.extraAppliedFiltersByTopic = newFilters;
    },

    SET_MAIN_SELECTED_FILTERS(state, filters) {
      state.mainSelectedFilters = filters;
    },

    SET_EXTRA_SELECTED_FILTERS(state, filters) {
      state.extraSelectedFilters = filters;
    },

    RESET_STATE(state) {
      Object.assign(state, resetState());
    },
  },

  actions: {
    applySelectedFilters({ state, commit }, activeTopicId) {
      commit("SET_MAIN_APPLIED_FILTERS_BY_TOPIC", {
        topic: activeTopicId,
        filters: state.mainSelectedFilters,
      });
      commit("SET_EXTRA_APPLIED_FILTERS_BY_TOPIC", {
        topic: activeTopicId,
        filters: state.extraSelectedFilters,
      });
    },

    initSelectedFiltersFromApplied({ state, commit }, activeTopicId) {
      commit(
        "SET_MAIN_SELECTED_FILTERS",
        state.mainAppliedFiltersByTopic[activeTopicId] || []
      );
      commit(
        "SET_EXTRA_SELECTED_FILTERS",
        state.extraAppliedFiltersByTopic[activeTopicId] || []
      );
    },

    resetSelectedFilters({ commit }) {
      commit("SET_MAIN_SELECTED_FILTERS", []);
      commit("SET_EXTRA_SELECTED_FILTERS", []);
    },

    resetState({ commit }) {
      commit("RESET_STATE");
    },
  },
};
