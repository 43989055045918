<template>
  <div class="button" :class="classesForButton" @click="$emit('click')">
    <slot></slot>

    <FadeTransition>
      <div v-if="isLoading" class="button__loader">
        <img src="@icons/bubble-loader.svg" width="24" />
      </div>
    </FadeTransition>
  </div>
</template>

<script>
import FadeTransition from "../transitions/Fade.vue";

export default {
  name: "ButtonWithLoader",

  components: {
    FadeTransition,
  },

  props: {
    isBlocked: {
      type: Boolean,
      default: false,
    },

    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    classesForButton() {
      return {
        "button--blocked": this.isBlocked,
        "button--loading": this.isLoading,
      };
    },
  },
};
</script>

<style scoped lang="scss">
.button {
  @include gradient-button;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  text-transform: uppercase;
  user-select: none;

  &--loading {
    pointer-events: none;
  }

  &__loader {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid $yellow-400;
    border-radius: 6px;
    background: white;
  }
}
</style>
