<template>
  <FadeTransition>
    <AppModal v-if="isOpened" :max-width="400" @close="$emit('close')">
      <template #body>
        <div class="modal-wr">
          <AppIcon name="check-o-big" class="check-icon" />
          <div class="modal-title">Анкета отправлена</div>
          <!-- <div class="modal-description">Поясняющий текст</div> -->
        </div>
      </template>
    </AppModal>
  </FadeTransition>
</template>

<script>
import FadeTransition from "@/components/transitions/Fade.vue";
import AppModal from "@/components/common/Modal.vue";
import AppIcon from "@/components/common/Icon.vue";

export default {
  name: "SuccessModal",

  components: {
    FadeTransition,
    AppModal,
    AppIcon,
  },

  props: {
    isOpened: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
/* stylelint-disable selector-class-pattern */
.modal-wr {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 15px 40px;
  text-align: center;
}

.modal-title {
  margin-top: 15px;
  margin-bottom: 5px;
  color: #1c1c1c;
  font-size: 24px;
  font-weight: 500;
}

.modal-description {
  color: #848484;
  font-size: 16px;
}

.check-icon {
  color: #04c200;
}
</style>
