var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "transition",
    _vm._b({ attrs: { name: "fade" } }, "transition", _vm.$attrs, false),
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }