var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("input", {
    directives: [
      { name: "mask", rawName: "v-mask", value: _vm.mask, expression: "mask" },
    ],
    ref: "input",
    class: _vm.classesForInput,
    attrs: {
      id: _vm.name,
      type: _vm.type == "date" ? "text" : _vm.type,
      name: _vm.name,
      maxlength: _vm.maxlength,
      placeholder: _vm.$attrs.placeholder,
      autocomplete: _vm.$attrs.autocomplete,
    },
    domProps: { value: _vm.value },
    on: {
      focus: _vm.onFocus,
      blur: _vm.onBlur,
      input: _vm.onInput,
      paste: _vm.onPaste,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }