<template>
  <div @click="$emit('select', tab, self)">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "Tab",

  props: {
    tab: {
      type: Object,
      required: true,

      validator(value) {
        if (Object.keys(value).includes("key")) return true;
      },
    },
  },

  computed: {
    self() {
      return this;
    },
  },
};
</script>

<style scoped lang="scss">
.tab {
}
</style>
