var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "cab-tooltip" }, [
    _c(
      "div",
      {
        ref: "activator",
        staticClass: "cab-tooltip__activator",
        on: { mouseenter: _vm.show, mouseleave: _vm.hideWithTimeout },
      },
      [_vm._t("activator")],
      2
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        ref: "body",
        staticClass: "cab-tooltip__body",
        class: { hidden: !_vm.showBody },
        on: { mouseenter: _vm.show, mouseleave: _vm.hideWithTimeout },
      },
      [
        _vm.interactive
          ? _c("div", { staticClass: "cab-tooltip__interactive" })
          : _vm._e(),
        _vm._v(" "),
        _c("div", {
          staticClass: "cab-tooltip__arrow",
          attrs: { "data-popper-arrow": "" },
        }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "cab-tooltip__continer" },
          [_vm._t("default")],
          2
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }