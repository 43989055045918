var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "tabs" }, [
    _c(
      "div",
      {
        ref: "overflow-container",
        staticClass: "tabs__overflow-container",
        class: _vm.tabsClass,
      },
      [
        _vm._t("default"),
        _vm._v(" "),
        _c("div", {
          staticClass: "tabs__slider",
          class: _vm.sliderClass,
          style: {
            left: _vm.left,
            width: _vm.width,
            transition: _vm.transition,
          },
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }