<template>
  <div class="root">
    <div
      v-for="(eventType, key) in eventTypes"
      :key="key"
      class="root__event-type"
    >
      {{ eventType }}
      <SwitchInput
        :checked="pushSettingFor(key)"
        @change="onChange($event, key)"
      />
    </div>

    <div v-if="pushEventTypesAreLoading" class="root__loading-overlay">
      <img src="@icons/bubble-loader.svg" width="40" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import SwitchInput from "../common/SwitchInput.vue";

export default {
  name: "UserSettingsEventTypesSelection",

  components: {
    SwitchInput,
  },

  data() {
    return {
      eventTypes: {
        message: "Новые сообщения в чате",
        journal: "Опубликован журнал учета оказанных услуг",
        contract: "Изменения статуса договора",
      },
    };
  },

  computed: {
    ...mapGetters({
      pushSettingFor: "User/Settings/getPushSettingFor",
      pushEventTypesAreLoading: "User/Settings/arePushEventTypesLoading",
    }),
  },

  methods: {
    ...mapActions("User/Settings", ["togglePushSetting"]),

    async onChange(event, key) {
      await this.togglePushSetting(key);

      this.$notify({
        text: "Настройка установлена",
      });
    },
  },
};
</script>

<style scoped lang="scss">
.root {
  position: relative;

  &__event-type {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 30px;
  }

  &__loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.8);
  }
}
</style>
