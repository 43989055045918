<template>
  <div>
    <template v-if="inTable">
      <div class="question-table__radio-wrapper">
        <div
          v-for="(option, index) in options"
          :key="option.name + index"
          class=""
        >
          <InputRadio
            v-model="modelValue"
            v-hint="{ text: 'Аутсорсер внес исправление', strategy: 'fixed' }"
            :hint-disabled="
              disabled && changedAll && showChanges ? 'false' : 'true'
            "
            :value="option.name"
            :disabled="disabled"
            :class="{
              'question-radio--highlight':
                disabled && changedAll && showChanges,
            }"
            class="question-radio"
            @change="$emit('change', modelValue)"
          >
            {{ option.name }}
          </InputRadio>
        </div>
      </div>
    </template>

    <template v-else>
      <div
        v-for="(option, index) in options"
        :key="option.name + index"
        class=""
      >
        <InputRadio
          v-model="modelValue"
          v-hint="{ text: 'Аутсорсер внес исправление' }"
          :hint-disabled="
            disabled && option.changed && showChanges ? 'false' : 'true'
          "
          :value="option.name"
          :disabled="disabled"
          :class="{
            'question-radio--highlight':
              disabled && option.changed && showChanges,
          }"
          class="question-radio"
          @change="$emit('change', modelValue)"
        >
          {{ option.name }}
        </InputRadio>
      </div>

      <div v-if="own" class="question-other">
        <InputRadio
          v-model="modelValue"
          value="Другое"
          :disabled="disabled"
          class="question-radio"
          @change="$emit('change', modelValue)"
        >
          Другое:
        </InputRadio>

        <HighlightTextarea
          v-model="modelOwnText"
          v-hint="{
            text: 'Аутсорсер внес исправление',
            placement: 'top-start',
          }"
          :hint-disabled="!(disabled && ownChanged && showChanges)"
          :name="'name_' + Math.random()"
          label=""
          rows="1"
          maxlength="500"
          :disabled="disabled"
          :highlight-text="disabled && ownChanged && showChanges"
          autocomplete="off"
          class="question-other__text"
          @focus="$emit('change', modelValue)"
          @input="$emit('inputOwnText', modelOwnText)"
        />
      </div>
    </template>
  </div>
</template>

<script>
import InputRadio from "@/components/common/InputRadio.vue";
import HighlightTextarea from "./HighlightTextarea.vue";

export default {
  name: "QuestionRadio",

  components: {
    InputRadio,
    HighlightTextarea,
  },

  model: {
    event: "change",
  },

  props: {
    value: {
      type: String,
      default: null,
    },
    options: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    changedAll: {
      type: Boolean,
      default: false,
    },
    showChanges: {
      type: Boolean,
      default: false,
    },
    inTable: {
      type: Boolean,
      default: false,
    },
    own: {
      type: Boolean,
      default: false,
    },
    ownText: {
      type: String,
      default: "",
    },
    ownChanged: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      modelValue: null,
      modelOwnText: "",
    };
  },

  watch: {
    value: {
      handler() {
        this.modelValue = this.value;
      },
      immediate: true,
    },
    ownText: {
      handler() {
        this.modelOwnText = this.ownText;
      },
      immediate: true,
    },
  },
};
</script>

<style scoped lang="scss">
/* stylelint-disable selector-class-pattern */
.question-table__radio-wrapper {
  position: relative;
}

.question-radio {
  max-width: 800px;
  margin-bottom: 10px;
  word-break: break-word;
}

.question-radio--highlight {
  position: relative;
  display: inline-flex;
  cursor: pointer;

  &::before {
    position: absolute;
    top: -2px;
    left: -6px;
    width: calc(100% + 12px);
    height: calc(100% + 4px);
    border-radius: 10px;
    background-color: #ffe676;
    content: "";
  }

  ::v-deep .input-radio__radio,
  ::v-deep .input-radio__text {
    position: relative;
  }
}

.question-other {
  display: flex;
  align-items: flex-start;
}

.question-other__text {
  width: 100%;
  max-width: 700px;
  margin-left: 15px;

  ::v-deep .cab-textarea__wrapper {
    padding-top: 0; // т.к. HighlightTextarea без label - убираем паддинг, отведенный для label
  }
}
</style>
