var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "service-connection" }, [
    _c("div", { staticClass: "service-connection__header" }, [
      _c(
        "div",
        {
          staticClass: "service-connection__back",
          attrs: { tabindex: "0" },
          on: {
            click: _vm.goBack,
            keyup: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              return _vm.goBack.apply(null, arguments)
            },
          },
        },
        [
          _c("img", {
            attrs: {
              src: require("@icons/arrow-back.svg"),
              alt: "arrow-back.svg",
            },
          }),
        ]
      ),
      _vm._v(" "),
      _c("h2", { staticClass: "service-connection__title" }, [
        _vm._v("Подключить 1С:БО"),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "form",
      { staticClass: "service-connection__form" },
      [
        _c("CabTextField", {
          staticClass: "service-connection__input",
          attrs: { label: "Имя", name: "service-connection-name" },
          model: {
            value: _vm.$v.name.$model,
            callback: function ($$v) {
              _vm.$set(_vm.$v.name, "$model", $$v)
            },
            expression: "$v.name.$model",
          },
        }),
        _vm._v(" "),
        _c("CabTextField", {
          staticClass: "service-connection__input",
          attrs: {
            label: "Номер телефона",
            name: "service-connection-phone",
            mask: "+7 (###) ###-##-##",
          },
          model: {
            value: _vm.$v.phone.$model,
            callback: function ($$v) {
              _vm.$set(_vm.$v.phone, "$model", $$v)
            },
            expression: "$v.phone.$model",
          },
        }),
        _vm._v(" "),
        _c("CabTextField", {
          staticClass: "service-connection__input",
          attrs: { label: "Email", name: "service-connection-email" },
          model: {
            value: _vm.$v.email.$model,
            callback: function ($$v) {
              _vm.$set(_vm.$v.email, "$model", $$v)
            },
            expression: "$v.email.$model",
          },
        }),
        _vm._v(" "),
        _c("CitySelect", { on: { select: _vm.onCitySelect } }),
        _vm._v(" "),
        _c(
          "div",
          {
            class: _vm.classesForSendButton,
            on: { click: _vm.sendServiceConnectionRequest },
          },
          [_vm._v("\n      Отправить\n    ")]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _vm._m(0),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "service-connection__help-info" }, [
      _c("div", [
        _vm._v(
          "\n      Если вы ещё не являетесь клиентом сервиса 1С:БО — оставьте заявку на\n      подключение к сервису. Партнёр 1С:БО свяжется с вами для подключения.\n    "
        ),
      ]),
      _vm._v(" "),
      _c("div", [
        _vm._v(
          "\n      Если у вас уже есть договор с партнёром сервиса 1С:БО — обратитесь к\n      вашему партнёру.\n    "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }