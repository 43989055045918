var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "view", staticClass: "view", on: { scroll: _vm.scrollList } },
    [
      _vm.isQuestionnaireLoading
        ? [
            _c(
              "div",
              { staticClass: "center" },
              [
                _c("AppIcon", {
                  staticClass: "loader-icon",
                  attrs: { src: "@icons/bubble-loader.svg" },
                }),
              ],
              1
            ),
          ]
        : [
            _vm.isQuestionnaireAllowed
              ? _c(
                  "div",
                  { staticClass: "view__main" },
                  [
                    _c(
                      "div",
                      { staticClass: "title-wr" },
                      [
                        _c("div", { staticClass: "view__title" }, [
                          _vm._v("Анкета по используемым технологиям"),
                        ]),
                        _vm._v(" "),
                        _vm.questionnaire.history &&
                        _vm.questionnaire.history.length
                          ? _c("QuestionnaireHistory", {
                              staticClass: "questionnaire-history",
                              attrs: { history: _vm.questionnaire.history },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm._l(_vm.questionnaire.themes, function (theme, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "section" },
                        [
                          _c("div", { staticClass: "section-title" }, [
                            _vm._v(_vm._s(theme.topic_name)),
                          ]),
                          _vm._v(" "),
                          _vm._l(theme.questions, function (question) {
                            return _c(
                              "div",
                              {
                                key: question.id,
                                staticClass: "question",
                                class: {
                                  "question--disabled":
                                    _vm.isEditMode &&
                                    !_vm.activeQuestions[question.id],
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "question-text" },
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(question.number) +
                                        ". " +
                                        _vm._s(question.text) +
                                        "\n            "
                                    ),
                                    question.instruction
                                      ? _c("OpenInstruction", {
                                          staticClass: "question-hint",
                                          attrs: { question: question },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "question-content" },
                                  [
                                    question.description
                                      ? _c("div", {
                                          staticClass: "question-description",
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.sanitizeHtml(
                                                question.description
                                              )
                                            ),
                                          },
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    question.type === "table"
                                      ? [
                                          _c(
                                            "div",
                                            { staticClass: "question-table" },
                                            [
                                              _vm._l(
                                                question.answer,
                                                function (answer, rowIndex) {
                                                  return _c(
                                                    "div",
                                                    {
                                                      key: rowIndex,
                                                      staticClass:
                                                        "question-table__row",
                                                      class: {
                                                        "question-table__row--tablet":
                                                          _vm.isSreenSizeSmallerOrEqualSM,
                                                      },
                                                    },
                                                    [
                                                      _vm._l(
                                                        question.columns,
                                                        function (
                                                          column,
                                                          columnIndex
                                                        ) {
                                                          return _c(
                                                            "div",
                                                            {
                                                              key: columnIndex,
                                                              staticClass:
                                                                "question-table__cell",
                                                              class: {
                                                                "question-table__cell--tablet":
                                                                  _vm.isSreenSizeSmallerOrEqualSM,
                                                                "question-table__cell--radio":
                                                                  !_vm.isSreenSizeSmallerOrEqualSM &&
                                                                  column.type ===
                                                                    "radio",
                                                              },
                                                            },
                                                            [
                                                              column.type ===
                                                              "text"
                                                                ? [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "question-table__header-cell",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                        " +
                                                                            _vm._s(
                                                                              column.name
                                                                            ) +
                                                                            "\n                      "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "QuestionText",
                                                                      {
                                                                        attrs: {
                                                                          options:
                                                                            column.variants,
                                                                          disabled:
                                                                            !_vm.isEditMode,
                                                                          "changed-all":
                                                                            answer[
                                                                              columnIndex
                                                                            ]
                                                                              .changed,
                                                                          "show-changes":
                                                                            _vm.showChanges,
                                                                          "in-table": true,
                                                                        },
                                                                        on: {
                                                                          focus:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.clearQuestionError(
                                                                                question
                                                                              )
                                                                            },
                                                                          input:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.answerChange(
                                                                                question
                                                                              )
                                                                            },
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            answer[
                                                                              columnIndex
                                                                            ]
                                                                              .value,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                answer[
                                                                                  columnIndex
                                                                                ],
                                                                                "value",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "answer[columnIndex].value",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ]
                                                                : column.type ===
                                                                  "radio"
                                                                ? [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "question-table__header-cell",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                        " +
                                                                            _vm._s(
                                                                              column.name
                                                                            ) +
                                                                            "\n                      "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "QuestionRadio",
                                                                      {
                                                                        attrs: {
                                                                          options:
                                                                            column.variants,
                                                                          disabled:
                                                                            !_vm.isEditMode,
                                                                          "changed-all":
                                                                            answer[
                                                                              columnIndex
                                                                            ]
                                                                              .changed,
                                                                          "show-changes":
                                                                            _vm.showChanges,
                                                                          "in-table": true,
                                                                        },
                                                                        on: {
                                                                          change:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              _vm.clearQuestionError(
                                                                                question
                                                                              )
                                                                              _vm.answerChange(
                                                                                question
                                                                              )
                                                                            },
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            answer[
                                                                              columnIndex
                                                                            ]
                                                                              .value,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                answer[
                                                                                  columnIndex
                                                                                ],
                                                                                "value",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "answer[columnIndex].value",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ]
                                                                : _vm._e(),
                                                            ],
                                                            2
                                                          )
                                                        }
                                                      ),
                                                      _vm._v(" "),
                                                      question.answer.length >
                                                        1 && _vm.isEditMode
                                                        ? _c("AppIcon", {
                                                            staticClass:
                                                              "question-table__remove-row",
                                                            attrs: {
                                                              name: "close",
                                                            },
                                                            nativeOn: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.removeTableAnswer(
                                                                  question.answer,
                                                                  rowIndex
                                                                )
                                                              },
                                                            },
                                                          })
                                                        : _vm._e(),
                                                    ],
                                                    2
                                                  )
                                                }
                                              ),
                                              _vm._v(" "),
                                              question.add_answer_btn &&
                                              _vm.isEditMode &&
                                              question.answer.length < 5
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "question-table__button",
                                                    },
                                                    [
                                                      _c("AppIcon", {
                                                        attrs: { name: "plus" },
                                                      }),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "question-table__button-title",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.addTableAnswer(
                                                                question
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                    " +
                                                              _vm._s(
                                                                question.add_answer_btn
                                                              ) +
                                                              "\n                  "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ],
                                            2
                                          ),
                                          _vm._v(" "),
                                          question.errorMessage
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "error-message",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                " +
                                                      _vm._s(
                                                        question.errorMessage
                                                      ) +
                                                      "\n              "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      : question.type === "text"
                                      ? [
                                          _c("QuestionText", {
                                            attrs: {
                                              disabled: !_vm.isEditMode,
                                              changed: question.changed,
                                              "show-changes": _vm.showChanges,
                                              "error-message":
                                                question.errorMessage,
                                            },
                                            on: {
                                              focus: function ($event) {
                                                return _vm.clearQuestionError(
                                                  question
                                                )
                                              },
                                              input: function ($event) {
                                                return _vm.answerChange(
                                                  question
                                                )
                                              },
                                            },
                                            model: {
                                              value: question.answer,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  question,
                                                  "answer",
                                                  $$v
                                                )
                                              },
                                              expression: "question.answer",
                                            },
                                          }),
                                        ]
                                      : question.type === "radio"
                                      ? [
                                          _c("QuestionRadio", {
                                            attrs: {
                                              options: question.variants,
                                              disabled: !_vm.isEditMode,
                                              "show-changes": _vm.showChanges,
                                              own: question.own,
                                              "own-text": question.own_text,
                                              "own-changed":
                                                question.own_changed,
                                            },
                                            on: {
                                              change: function ($event) {
                                                _vm.clearQuestionError(question)
                                                _vm.answerChange(question)
                                              },
                                              inputOwnText: function ($event) {
                                                question.own_text = $event
                                                _vm.answerChange(question)
                                              },
                                            },
                                            model: {
                                              value: question.answer,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  question,
                                                  "answer",
                                                  $$v
                                                )
                                              },
                                              expression: "question.answer",
                                            },
                                          }),
                                          _vm._v(" "),
                                          question.errorMessage
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "error-message",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                " +
                                                      _vm._s(
                                                        question.errorMessage
                                                      ) +
                                                      "\n              "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      : question.type === "checkbox"
                                      ? [
                                          _c("QuestionCheckbox", {
                                            attrs: {
                                              options: question.variants,
                                              disabled: !_vm.isEditMode,
                                              "show-changes": _vm.showChanges,
                                              own: question.own,
                                              "own-text": question.own_text,
                                              "own-changed":
                                                question.own_changed,
                                            },
                                            on: {
                                              input: function ($event) {
                                                _vm.clearQuestionError(question)
                                                _vm.answerChange(question)
                                              },
                                              inputOwnText: function ($event) {
                                                question.own_text = $event
                                                _vm.answerChange(question)
                                              },
                                            },
                                            model: {
                                              value: question.answer,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  question,
                                                  "answer",
                                                  $$v
                                                )
                                              },
                                              expression: "question.answer",
                                            },
                                          }),
                                          _vm._v(" "),
                                          question.errorMessage
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "error-message",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                " +
                                                      _vm._s(
                                                        question.errorMessage
                                                      ) +
                                                      "\n              "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      : _vm._e(),
                                  ],
                                  2
                                ),
                              ]
                            )
                          }),
                        ],
                        2
                      )
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "buttons" },
                      [
                        _c(
                          "ButtonWithLoader",
                          {
                            staticClass: "button btn btn--yellow uppercase",
                            on: { click: _vm.submit },
                          },
                          [
                            _vm.isEditMode
                              ? _c("span", [_vm._v("Отправить")])
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.isEditMode
                              ? _c("span", [_vm._v("Подтвердить")])
                              : _vm._e(),
                          ]
                        ),
                        _vm._v(" "),
                        !_vm.isEditMode
                          ? _c(
                              "ButtonWithLoader",
                              {
                                staticClass:
                                  "button btn btn--yellow btn--edit uppercase",
                                on: { click: _vm.startEdit },
                              },
                              [_vm._v("\n          Редактировать\n        ")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.valid
                          ? _c("div", { staticClass: "not-valid" }, [
                              _vm._v("Заполнены не все поля"),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.isQuestionnaireAllowed
              ? _c(
                  "div",
                  {
                    staticClass: "scroll-to",
                    on: {
                      click: function ($event) {
                        return _vm.scrollTo(
                          _vm.scrollPosition > 200 ? "up" : "down"
                        )
                      },
                    },
                  },
                  [
                    _c("AppIcon", {
                      staticClass: "scroll-to-icon",
                      class: { "scroll-to-icon--up": _vm.scrollPosition > 200 },
                      attrs: { name: "chevron-down" },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            !_vm.isQuestionnaireAllowed
              ? _c("div", { staticClass: "empty" }, [
                  _c(
                    "div",
                    { staticClass: "empty__bg" },
                    [_c("AppIcon", { attrs: { name: "file-x" } })],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "empty__text" }, [
                    _vm._v("Нет данных"),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("ConfirmModal", {
              attrs: {
                "is-opened": _vm.isConfirmModalOpened,
                "questionnaire-for1c": _vm.questionnaireFor1c,
              },
              on: { close: _vm.onConfirmModalClose },
            }),
            _vm._v(" "),
            _c("SuccessModal", {
              attrs: { "is-opened": _vm.isSuccessModalOpened },
              on: {
                close: function ($event) {
                  _vm.isSuccessModalOpened = false
                },
              },
            }),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }