import xor from "lodash-es/xor";

import { nanoid } from "nanoid";

import { FeedService } from "../../services";

const participantsCache = {};

function resetState() {
  return {
    loading_ids: [],

    _update: undefined,
  };
}

export default {
  namespaced: true,

  state: resetState(),

  getters: {
    participants(state, getters, rootState, rootGetters) {
      state._update;

      const orgId = rootGetters["Organizations/ActiveOne/getId"];

      return participantsCache[orgId] || [];
    },

    loadingIds({ loading_ids }) {
      return loading_ids;
    },

    loadedIds(state) {
      state._update;

      return Object.keys(participantsCache);
    },
  },

  mutations: {
    TOGGLE_IS_LOADING(state, [orgId, value]) {
      // ignore any subsequent call with the same params
      if (state.loading_ids.includes(orgId) == value) return;

      state.loading_ids = xor(state.loading_ids, [orgId]);
    },

    ADD_PARTICIPANTS(state, { orgId, participants }) {
      participantsCache[orgId] = participants;
    },

    UPDATE(state) {
      state._update = nanoid();
    },

    RESET_STATE(state) {
      Object.assign(state, resetState());
    },
  },

  actions: {
    async loadParticipants({ commit }, orgId) {
      commit("TOGGLE_IS_LOADING", [orgId, true]);

      let participants;

      try {
        participants = await FeedService.fetchParticipants(orgId);

        commit("ADD_PARTICIPANTS", { orgId, participants });
        commit("UPDATE");
      } finally {
        commit("TOGGLE_IS_LOADING", [orgId, false]);
      }
    },

    resetState({ commit }) {
      commit("RESET_STATE");
    },
  },
};
