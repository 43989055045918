var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "contract-history" },
    [
      _vm._t("title"),
      _vm._v(" "),
      _vm.isLoading
        ? _c("div", { staticClass: "contract-history__loader-overlay" }, [
            _c("img", {
              staticClass: "contract-history__loader-icon",
              attrs: { src: require("@icons/bubble-loader.svg"), width: "40" },
            }),
          ])
        : _vm._l(_vm.history, function (item, index) {
            return _c(
              "div",
              {
                key: item.org_id + index,
                staticClass: "contract-history-item",
              },
              [
                _c(
                  "div",
                  { staticClass: "contract-history-item__wrapper" },
                  [
                    _c("AppIcon", {
                      staticClass: "contract-history-item__icon",
                      attrs: { src: _vm.icon(item.action) },
                    }),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "contract-history-item__dashed-line",
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "contract-history-item__text" }, [
                  _c(
                    "div",
                    {
                      staticClass: "contract-history-item__title",
                      class: {
                        "contract-history-item__title--bold":
                          _vm.actions[item.action]?.is_bold,
                      },
                    },
                    [_vm._v("\n          " + _vm._s(item.text) + "\n        ")]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "contract-history-item__date" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm._f("localDate")(item.date)) +
                        "\n        "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("FileDownloadIcon", {
                  staticClass: "contract-history-item__link",
                  attrs: {
                    "file-id": item?.file?.id,
                    "org-id": item?.org_id,
                    form: item?.form,
                    action: item?.action,
                  },
                }),
              ],
              1
            )
          }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }