var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "outerBox", class: _vm.classesForItemsBox }, [
    _c(
      "div",
      {
        ref: "innerBox",
        staticClass: "items-box__items",
        style: { maxHeight: _vm.maxHeight },
      },
      [_vm._t("default")],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }