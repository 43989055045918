import axios from "axios";
import Vue, { nextTick } from "vue";
import qs from "qs";

import store from "../store";
import router from "../router";

import * as Sentry from "@sentry/vue";

import { API_URL } from "../utils/constants";

const axiosClient = axios.create({
  baseURL: API_URL,
  withCredentials: true,
  paramsSerializer: {
    serialize: (params) => qs.stringify(params, { arrayFormat: "brackets" }),
  },
});

axiosClient.interceptors.response.use(
  function (response) {
    // status: 2XX
    return response;
  },
  function (error) {
    // status: not 2XX
    Sentry.captureException(error);

    const { request, response } = error;

    const url = request?.url || request?.responseURL || "";

    error.message = error.message + " | " + url;

    // handle login separately
    if (response.config.url == "/login") {
      return Promise.reject(error);
    }

    if (
      response.status == 401 &&
      /* requests to /me are possible only during app initialization */
      response.config.url != "/me" &&
      /* skip error handling for /logout because we are using the localsStorage.isUserAuthorized flag */
      response.config.url != "/logout"
    ) {
      if (!localStorage.getItem("isUserAuthorized")) {
        store.dispatch("resetStore");
      }

      router.push({ name: "Login" }).catch((error) => {
        console.log(error, "error");
      });

      nextTick(() => {
        Vue.notify({
          type: "error",
          text: "401 | Unauthorized",
        });
      });
    }

    return Promise.reject(error);
  }
);

export default axiosClient;
