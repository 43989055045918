<template>
  <div ref="outerBox" :class="classesForItemsBox">
    <div ref="innerBox" class="items-box__items" :style="{ maxHeight }">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "ItemsBox",

  props: {
    isEmpty: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      maxHeight: "0px",
    };
  },

  computed: {
    classesForItemsBox() {
      return {
        "items-box": true,
        "items-box--hidden": this.isEmpty,
      };
    },
  },

  mounted() {
    this.setMaxHeightOfInnerBox();

    window.addEventListener("resize", this.setMaxHeightOfInnerBox);
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.setMaxHeightOfInnerBox);
  },

  methods: {
    setMaxHeightOfInnerBox() {
      const outerBoxY = this.$refs["outerBox"].getBoundingClientRect().y;

      let innerBoxMaxHeight = innerHeight - outerBoxY;

      innerBoxMaxHeight = innerBoxMaxHeight < 230 ? innerBoxMaxHeight : 230;

      this.maxHeight = `${innerBoxMaxHeight - 44}px`;
    },
  },
};
</script>

<style scoped lang="scss">
.items-box {
  overflow: hidden;
  padding: 12px;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.12);

  &--hidden {
    height: 0;
    padding: 0;
  }

  &__items {
    height: 100%;
    min-height: 32px;
    overflow-y: auto;
    scrollbar-color: $grey-200 transparent;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 2px;
      background: $grey-200;
    }
  }

  &__item {
    display: flex;
    height: 32px;
    align-items: center;
    padding-left: 8px;
    border-radius: 5px;
    cursor: pointer;
    outline: none;

    &:hover,
    &:focus {
      background: $grey-100;
    }
  }
}
</style>
