var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "AppTabs",
    _vm._g(
      _vm._b({ staticClass: "view-tabs" }, "AppTabs", _vm.$props, false),
      _vm.$listeners
    ),
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }