export default function (value) {
  const date = new Date(value);

  if (isNaN(date)) return "NOT A DATE";

  return (
    `${date.getHours()}`.padStart(2, "0") +
    ":" +
    `${date.getMinutes()}`.padStart(2, "0")
  );
}
