<template>
  <FadeGroupTransition>
    <router-view v-if="$route.name != null" key="view" />
    <ScreenLoader v-else key="loader" />
  </FadeGroupTransition>
</template>

<script>
import { provide } from "vue";

import { useBreakpoints, breakpointsVuetify } from "@vueuse/core";

import ScreenLoader from "./components/AppLayout/ScreenLoader.vue";
import FadeGroupTransition from "./components/transitions/FadeGroup.vue";

import Pusher from "pusher-js";
window.Pusher = Pusher;

Pusher.Runtime.createXHR = function () {
  var xhr = new XMLHttpRequest();
  xhr.withCredentials = true;
  return xhr;
};

export default {
  name: "App",

  components: {
    ScreenLoader,
    FadeGroupTransition,
  },

  setup() {
    // let points = { xs: 600, sm: 960, md: 1264, lg: 1904 };
    const screenSize = useBreakpoints(breakpointsVuetify);

    provide("screenSize", screenSize);
  },
};
</script>

// TODO: make scoped
<style lang="scss">
// @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");
@import "./assets/fonts/Roboto/style.css";

@import "./lib/vue-directives/hint.scss";

html {
  height: 100%;
}

body {
  position: fixed;
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background-color: $grey-100;
  color: $black;
  font-family: Roboto, sans-serif;
  font-size: 15px;
  -webkit-tap-highlight-color: transparent;

  @media (max-width: 600px) {
    background-color: white;
  }
}

textarea,
input {
  font-family: Roboto, sans-serif;
  font-size: 15px;
}

.filler {
  flex: 1;
}

mark {
  background: transparent;
  color: red;
}

// CONTRACT OFFER PRINT STYLE
@media print {
  // 1. body should be a top relative element
  body {
    position: relative;
  }

  // 2. all imtemediate nodes in tree should be "transparent"
  .app,
  .app__main,
  .tariff-view,
  .tariff-view__main,
  .tariff-contract-offer,
  .tariff-contract-offer__main,
  .contract-offer-wrapper {
    position: static !important;
    z-index: 40000;
    overflow: visible !important;
    padding: 0 !important;
    margin: 0 !important;
  }

  .contract-offer {
    position: absolute;
    top: 0;
    left: 0;
    background: white;
  }
}
</style>
