import { TechAuditService } from "@/services";

function resetState() {
  return {
    questionnaire_are_loading: false,
  };
}

export default {
  namespaced: true,

  state: resetState(),

  getters: {
    areQuestionnaireLoading({ questionnaire_are_loading }) {
      return questionnaire_are_loading;
    },
  },

  mutations: {
    RESET_STATE(state) {
      Object.assign(state, resetState());
    },

    SET_QUESTIONNARIE_ARE_LOADING(state, value) {
      state.questionnaire_are_loading = value;
    },
  },

  actions: {
    async fetchQuestionnaire({ commit, rootGetters }, orgId) {
      commit("SET_QUESTIONNARIE_ARE_LOADING", true);

      try {
        const questionnaire = await TechAuditService.fetchQuestionnaire(orgId);

        return questionnaire;
      } finally {
        if (rootGetters["Organizations/ActiveOne/getId"] === orgId) {
          commit("SET_QUESTIONNARIE_ARE_LOADING", false);
        }
      }
    },

    async sendQuestionnaire(ctx, data) {
      const questionnaire = await TechAuditService.sendQuestionnaire(data);

      return questionnaire;
    },

    resetState({ commit }) {
      commit("RESET_STATE");
    },
  },
};
