var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "checkbox", class: { "checkbox--disabled": _vm.disabled } },
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.selected,
            expression: "selected",
          },
        ],
        attrs: {
          id: _vm.id,
          type: "checkbox",
          name: _vm.id,
          disabled: _vm.disabled,
        },
        domProps: {
          value: _vm.value,
          checked: Array.isArray(_vm.selected)
            ? _vm._i(_vm.selected, _vm.value) > -1
            : _vm.selected,
        },
        on: {
          change: function ($event) {
            var $$a = _vm.selected,
              $$el = $event.target,
              $$c = $$el.checked ? true : false
            if (Array.isArray($$a)) {
              var $$v = _vm.value,
                $$i = _vm._i($$a, $$v)
              if ($$el.checked) {
                $$i < 0 && (_vm.selected = $$a.concat([$$v]))
              } else {
                $$i > -1 &&
                  (_vm.selected = $$a.slice(0, $$i).concat($$a.slice($$i + 1)))
              }
            } else {
              _vm.selected = $$c
            }
          },
        },
      }),
      _vm._v(" "),
      _c("label", { attrs: { for: _vm.id } }, [_vm._t("default")], 2),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }