var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "download-icon",
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return _vm.downloadFile.apply(null, arguments)
        },
      },
    },
    [
      !_vm.loading
        ? _c(
            "svg",
            {
              attrs: {
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg",
                viewBox: "0 0 18 18",
              },
            },
            [
              _c("path", {
                attrs: {
                  d: "M12.43 6.83L9.8 9.47V1H8.2v8.47L5.57 6.83 4.43 7.97 9 12.53l4.57-4.56-1.14-1.14z",
                  fill: "currentColor",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M17 15.4v-3.2h-1.6v3.2H2.6v-3.2H1v3.2c0 .88.72 1.6 1.6 1.6h12.8c.88 0 1.6-.72 1.6-1.6z",
                  fill: "currentColor",
                },
              }),
            ]
          )
        : _c("img", {
            attrs: { src: require("@icons/bubble-loader.svg"), width: "20" },
          }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }