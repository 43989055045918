import Tariff from "../views/Tariff.vue";
import TariffContractOrOffer from "../views/Tariff/ContractOrOffer.vue";
import TariffOutsourcer from "../views/Tariff/Outsourcer.vue";
import TariffResponsible from "../views/Tariff/Responsible.vue";

export default {
  path: "contract",
  name: "Tariff",
  component: Tariff,

  meta: {
    withTabs: true,
  },

  children: [
    {
      path: "document",
      name: "TariffContractOrOffer",
      component: TariffContractOrOffer,

      meta: {
        withTabs: true,
      },
    },

    {
      path: "responsible",
      name: "TariffResponsible",
      component: TariffResponsible,

      meta: {
        withTabs: true,
      },
    },

    {
      path: "outsourcer",
      name: "TariffOutsourcer",
      component: TariffOutsourcer,

      meta: {
        withTabs: true,
      },
    },
  ],
};
