const AppLayout = () =>
  import(
    /* webpackPrefetch: true */
    /* webpackChunkName: "AppLayout" */
    "../layouts/App.vue"
  );

// import Overview from "../views/Overview.vue";
// import Chat from "../views/Chat.vue";
const Chat = () =>
  import(
    /* webpackPrefetch: true */
    /* webpackChunkName: "Chat" */
    "../views/Chat.vue"
  );
// import Calendar from "../views/Calendar.vue";
const Calendar = () =>
  import(
    /* webpackChunkName: "Calendar" */
    "../views/Calendar.vue"
  );
// import Journals from "../views/Journals.vue";
const Journals = () =>
  import(
    /* webpackChunkName: "Journals" */
    "../views/Journals.vue"
  );

import accountRoutes from "./accountRoutes.js";
import documentsRoutes from "./documentsRoutes.js";
import tariffRoutes from "./tariffRoutes.js";
import promoRoutes from "./promoRoutes.js";
import referenceRoutes from "./referenceRoutes.js";

export default {
  path: "/",
  name: "App",
  component: AppLayout,
  redirect: "chat",

  beforeEnter(to, from, next) {
    // prevent an unauthorized user from seeing the App routes
    if (!localStorage.getItem("isUserAuthorized"))
      return next({ name: "Login" });

    next();
  },

  children: [
    // {
    //   path: "overview",
    //   name: "Overview",
    //   component: Overview,
    // },

    {
      path: "chat",
      name: "Chat",
      component: Chat,
    },

    {
      path: "calendar",
      name: "Calendar",
      component: Calendar,
    },

    {
      path: "journals",
      name: "Journals",
      component: Journals,
    },

    accountRoutes,

    documentsRoutes,

    tariffRoutes,

    promoRoutes,

    referenceRoutes,

    {
      path: "user-settings",
      redirect: "account/user-settings", // чтобы старый урл не потерялся - делаем редирект
    },
    
    {
      path: '/tariff/contract',
      redirect: "/contract/document",
    },
    
    {
      path: '/tariff/:subPath*',
      redirect: "/contract/:subPath*",
    },
    
  ],
};
