<template>
  <div class="app-screen-loader">
    <img src="@icons/bubble-loader.svg" width="64" />
  </div>
</template>

<script>
export default {
  name: "AppScreenLoader",
};
</script>

<style scoped lang="scss">
.app-screen-loader {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 4px solid $yellow-400;
  background: white;
}
</style>
