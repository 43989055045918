import UploadingFiles from "./common/uploading-files";
import Autocomplete from "./input-panel-autocomplete";

function resetState() {
  return {
    quote: undefined,
    quoteThread: undefined,

    message: "",
  };
}

export default {
  namespaced: true,

  state: resetState(),

  getters: {
    getQuote: (state) => (isThread) => {
      return state[isThread ? "quoteThread" : "quote"];
    },

    getMessage(state) {
      return state.message;
    },
  },

  mutations: {
    SET_QUOTE(state, payload) {
      state[payload.thread ? "quoteThread" : "quote"] = payload.quote;
    },

    UPDATE_MESSAGE(state, message) {
      state.message = message;
    },

    RESET_STATE(state) {
      Object.assign(state, resetState());
    },
  },

  actions: {
    pinQuote({ commit }, { quote, thread }) {
      commit("SET_QUOTE", { quote, thread });
    },

    unpinQuote({ commit }, thread) {
      commit("SET_QUOTE", { thread, quote: undefined });
    },

    updateMessage({ commit }, text) {
      commit("UPDATE_MESSAGE", text);
    },

    resetState({ commit, dispatch }) {
      dispatch("UploadingFiles/resetState");

      commit("RESET_STATE");
    },
  },

  modules: {
    UploadingFiles: UploadingFiles(),
    Autocomplete,
  },
};
