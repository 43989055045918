<template>
  <div class="profile">
    <AvatarSelect class="profile__avatar-select" />

    <div class="profile__text-fields">
      <div class="profile__name">
        {{ name }}
      </div>

      <div class="profile__position">
        {{ position }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import AvatarSelect from "./AvatarSelect.vue";

export default {
  name: "UserSettingsProfileBlock",

  components: {
    AvatarSelect,
  },

  computed: {
    ...mapGetters({
      name: "User/getName",
      position: "User/getPosition",
    }),
  },
};
</script>

<style scoped lang="scss">
.profile {
  &__avatar-select {
    width: 110px;
    min-width: 110px;
    height: 110px;
    margin-right: 40px;
  }

  &__text-fields {
    max-width: calc(100% - 200px);
  }

  &__name,
  &__position {
    @include ellipsis;

    align-items: center;

    img {
      margin-left: 8px;
      cursor: pointer;
      opacity: 0.8;
      transition: opacity $shortest;

      &:hover {
        opacity: 1;
      }
    }
  }

  &__name {
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 24px;
    font-weight: 500;
  }

  &__position {
    color: $grey-700;
    font-size: 15px;
  }

  @media (max-width: 600px) {
    &__avatar-select {
      width: 80px;
      min-width: 80px;
      height: 80px;
      margin-right: 40px;
    }

    &__text-fields {
      max-width: calc(100% - 100px);
    }

    &__name {
      margin-top: 10px;
      margin-bottom: 6px;
      font-size: 20px;
    }
  }
}
</style>
