import matchesProperty from "lodash-es/matchesProperty";

import { nanoid } from "nanoid";

import ActiveOne from "./active-organization";
import { loadTopic } from "@/utils";

let organizations = [];

function resetState() {
  return {
    active_org_id: undefined,

    _update: undefined,
  };
}

export default {
  namespaced: true,

  state: resetState(),

  getters: {
    getAll(state) {
      state._update;

      return organizations;
    },

    getActive(state) {
      state._update;

      return organizations.find(matchesProperty("id", state.active_org_id));
    },
  },

  mutations: {
    SET_ORGANIZATIONS(state, value) {
      organizations = value;

      state._update = nanoid();
    },

    SET_ACTIVE_ID(state, id) {
      state.active_org_id = id;
    },

    RESET_STATE(state) {
      Object.assign(state, resetState());
    },
  },

  actions: {
    init({ commit }, organizations) {
      commit("SET_ORGANIZATIONS", organizations);
    },

    selectActive({ commit, dispatch }, { userId, orgId }) {
      commit("SET_ACTIVE_ID", orgId);
      dispatch("Thread/resetStateThreads", {}, { root: true });

      localStorage.setItem(`cab-org-id:${userId}`, orgId);
    },

    resetState({ commit, dispatch }) {
      dispatch("ActiveOne/resetState");

      organizations = [];

      commit("RESET_STATE");
    },
  },

  modules: {
    ActiveOne,
  },
};
