const PromoView = () =>
  import(
    /* webpackPrefetch: true */
    /* webpackChunkName: "Promo" */
    "../views/Promo.vue"
  );

const PromoList = () =>
  import(
    /* webpackPrefetch: true */
    /* webpackChunkName: "Promo" */
    "../views/Promo/List.vue"
  );

const PromoInvite = () =>
  import(
    /* webpackPrefetch: true */
    /* webpackChunkName: "Promo" */
    "../views/Promo/Invite.vue"
  );

const InviteProvision = () =>
  import(
    /* webpackPrefetch: true */
    /* webpackChunkName: "Promo" */
    "../views/Promo/InviteProvision.vue"
  );

export default {
  path: "promo",
  component: PromoView,

  children: [
    {
      path: "",
      name: "PromoList",
      component: PromoList,
      meta: {
        title: "Акции",
      },
    },

    {
      path: "invite",
      name: "PromoInvite",
      component: PromoInvite,
    },

    {
      path: "invite-provision",
      name: "InviteProvision",
      component: InviteProvision,
    },
  ],
};
