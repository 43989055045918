<template>
  <transition-group name="fade" v-bind="$attrs">
    <slot></slot>
  </transition-group>
</template>

<script>
export default {
  name: "FadeGroupTransition",
};
</script>

<style scoped lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity $short;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
