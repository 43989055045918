<template>
  <div>
    <template v-if="inTable">
      <HighlightInput
        v-model="modelValue"
        label=""
        :disabled="disabled"
        :highlight-text="disabled && changedAll && showChanges"
        :name="'name_' + Math.random()"
        maxlength="500"
        autocomplete="off"
        @input="$emit('input', modelValue)"
        @focus="$emit('focus')"
      >
        <template v-if="disabled && changedAll && showChanges" #highlight>
          <div
            v-hint="{
              text: 'Аутсорсер внес исправление',
              strategy: 'fixed',
            }"
            class="question-mark"
          >
            <span>{{ value }}</span>
          </div>
        </template>
      </HighlightInput>
    </template>

    <template v-else>
      <HighlightTextarea
        v-model="modelValue"
        label="Ваш ответ"
        rows="1"
        maxlength="500"
        :name="'name_' + Math.random()"
        :disabled="disabled"
        :highlight-text="disabled && changed && showChanges"
        :error-message="errorMessage"
        class="question-textarea"
        @input="$emit('input', modelValue)"
        @focus="$emit('focus')"
      >
        <template v-if="disabled && changed && showChanges" #highlight>
          <div
            v-hint="{
              text: 'Аутсорсер внес исправление',
              strategy: 'fixed',
            }"
            class="question-mark"
          >
            <span>{{ value }}</span>
          </div>
        </template>
      </HighlightTextarea>
    </template>
  </div>
</template>

<script>
import HighlightInput from "./HighlightInput.vue";
import HighlightTextarea from "./HighlightTextarea.vue";

export default {
  name: "QuestionText",

  components: {
    HighlightInput,
    HighlightTextarea,
  },

  props: {
    value: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    changedAll: {
      type: Boolean,
      default: false,
    },
    changed: {
      type: Boolean,
      default: false,
    },
    showChanges: {
      type: Boolean,
      default: false,
    },
    inTable: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      modelValue: null,
    };
  },

  watch: {
    value: {
      handler() {
        this.modelValue = this.value;
      },
      immediate: true,
    },
    ownText: {
      handler() {
        this.modelOwnText = this.ownText;
      },
      immediate: true,
    },
  },
};
</script>

<style scoped lang="scss">
/* stylelint-disable selector-class-pattern */
.question-textarea {
  max-width: 800px;
}

.question-mark {
  position: relative;
  left: 4px;
  cursor: pointer;
  pointer-events: all;

  * {
    position: relative;
  }

  &::before {
    position: absolute;
    top: -2px;
    left: -6px;
    width: calc(100% + 12px);
    height: calc(100% + 4px);
    border-radius: 10px;
    background-color: #ffe676;
    content: "";
  }
}
</style>
