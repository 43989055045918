var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "FadeTransition",
    [
      _vm.isOpened
        ? _c("AppModal", {
            attrs: { "max-width": 400 },
            on: {
              close: function ($event) {
                return _vm.close(false)
              },
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "header",
                  fn: function () {
                    return [_vm._v("Вы точно хотите отправить анкету?")]
                  },
                  proxy: true,
                },
                {
                  key: "footer",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "modal__buttons" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "modal__cancel",
                              on: {
                                click: function ($event) {
                                  return _vm.close(false)
                                },
                              },
                            },
                            [_vm._v("Нет")]
                          ),
                          _vm._v(" "),
                          _c(
                            "ButtonWithLoader",
                            {
                              staticClass: "modal__send",
                              attrs: { "is-loading": _vm.isSending },
                              on: { click: _vm.send },
                            },
                            [_vm._v("\n          Да\n        ")]
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              2222243056
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }