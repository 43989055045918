var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "support-badge" }, [
      _c("img", {
        staticClass: "support-badge__icon",
        attrs: { src: require("@icons/reference/support-badge.svg"), alt: "" },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "support-badge__wrapper" }, [
        _c("div", { staticClass: "support-badge__text" }, [
          _vm._v("Служба поддержки"),
        ]),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "support-badge__phone",
            attrs: { href: "tel:88007700339" },
          },
          [_vm._v("8 800 770-03-39")]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "support-badge__email",
            attrs: { href: "mailto:support@1cbo.ru" },
          },
          [_vm._v("\n      support@1cbo.ru\n    ")]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }