<template>
  <div class="reference-help">
    <SupportBadge class="reference-help__support-badge mobile" />

    <div class="reference-help__column">
      <InstructionsList class="reference-help__instructions-list" />

      <FadeTransition mode="out-in">
        <router-view @update="onUpdate" />
      </FadeTransition>
    </div>

    <SupportBadge class="reference-help__support-badge" />
  </div>
</template>

<script>
import InstructionsList from "./InstructionsList.vue";
import SupportBadge from "../../components/Reference/SupportBadge.vue";

import FadeTransition from "../../components/transitions/Fade.vue";

export default {
  name: "ReferenceHelp",

  components: {
    InstructionsList,
    SupportBadge,

    FadeTransition,
  },

  methods: {
    onUpdate(instructionsEl) {
      instructionsEl.scrollIntoView({ behavior: "smooth" });
    },
  },
};
</script>

<style scoped lang="scss">
.reference-help {
  display: flex;
  min-height: 500px;
  padding: 24px;

  &__title {
    margin-bottom: 30px;
    font-size: 24px;
    font-weight: 700;
  }

  &__instructions-list {
    margin-bottom: 60px;
  }

  &__support-badge {
    position: sticky;
    top: 0;
    align-self: start;
    margin: 0 auto 36px;

    // watch next comment
    &.mobile {
      display: none;
    }
  }

  &__column {
    margin-right: 36px;
  }

  @media (max-width: 960px) {
    // can't use flex-direction: column-reverse
    // because of unintuitive scrollTop behaviour in that case
    flex-direction: column;

    &__support-badge {
      position: static;

      &.mobile {
        display: flex;
      }

      &:not(.mobile) {
        display: none;
      }
    }

    &__column {
      margin-right: 0;
    }
  }

  @media (max-width: 600px) {
    &__title {
      font-size: 20px;
    }
  }
}
</style>
