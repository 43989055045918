<template>
  <div class="new-password">
    <div class="new-password__header">
      <h2 class="new-password__title">Придумайте пароль:</h2>
    </div>

    <form>
      <CabTextField
        v-model="password"
        class="new-password__input"
        autocomplete="new-password"
        label="Пароль"
        name="new-password"
        placeholder=" Введите пароль"
        :type="passwordType"
        @input="errorMessage = ''"
      >
        <template #appendedIcon>
          <EyeIcon
            class="login-form__password-icon"
            :is-striked="passwordType == 'password'"
            @click.native="onIconClick"
          />
        </template>
      </CabTextField>

      <CabTextField
        v-model="repeatPassword"
        class="new-password__input"
        autocomplete="new-password"
        label="Подтверждение пароля"
        name="new-password-repeat"
        placeholder=" Введите пароль"
        :type="passwordType"
        :error-message="errorMessage"
        @input="errorMessage = ''"
      >
        <template #appendedIcon>
          <EyeIcon
            class="login-form__password-icon"
            :is-striked="passwordType == 'password'"
            @click.native="onIconClick"
          />
        </template>
      </CabTextField>

      <ButtonWithLoader
        :is-loading="isLoading"
        :is-blocked="isBlocked"
        class="modal__send"
        @click="changePassword"
      >
        Установить
      </ButtonWithLoader>
    </form>
  </div>
</template>

<script>
import { required } from "vuelidate/dist/validators.min";

import { AuthService } from "../../services";

import CabTextField from "../../components/common/CabTextField.vue";
import EyeIcon from "../../components/common/EyeIcon.vue";
import ButtonWithLoader from "../../components/common/ButtonWithLoader.vue";

import loginMixin from "./loginMixin";

export default {
  name: "NewPassword",

  components: {
    CabTextField,
    EyeIcon,
    ButtonWithLoader,
  },

  mixins: [loginMixin],

  data() {
    return {
      password: "",
      repeatPassword: "",
      isPasswordVisible: false,
      errorMessage: "",
      isLoading: false,
    };
  },

  validations: {
    password: {
      required,
    },
  },

  computed: {
    passwordType() {
      return this.isPasswordVisible ? "text" : "password";
    },

    code() {
      return this.$route.query["code"];
    },

    username() {
      return this.$route.query["login"];
    },

    isBlocked() {
      return this.$v.$invalid;
    },
  },
  methods: {
    onIconClick() {
      this.isPasswordVisible = !this.isPasswordVisible;
    },

    async changePassword() {
      if (!this.code) throw new Error("Need a restoration code.");

      if (this.password !== this.repeatPassword) {
        this.errorMessage = "Пароль должен совпадать";
        return;
      }

      this.isLoading = true;

      try {
        await AuthService.changePassword({
          restoreCode: this.code,
          newPassword: this.password,
        });

      } catch (error) {
        const { response } = error;

        this.errorMessage =
          response.data.error || "Что-то пошло не так, попробуйте позже...";

        throw error;
      } finally {
        this.isLoading = false;
      }

      await this.loginUser({
        username: this.username,
        password: this.password,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.new-password {
  width: 332px;
  max-width: 96%;

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 28px;
  }

  &__title {
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: 500;
    line-height: 1;
  }

  &__back {
    display: flex;
    align-items: center;
    margin-right: 20px;
    cursor: pointer;
  }

  &__instruction {
    margin-bottom: 32px;
    color: $grey-600;
  }

  &__input {
    margin-bottom: 40px;
  }

  &__email-input {
    margin-bottom: 16px;
  }
}
</style>
