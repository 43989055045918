import cloneDeep from "lodash-es/cloneDeep";
import merge from "lodash-es/merge";
import set from "lodash-es/set";
import isEmpty from "lodash-es/isEmpty";

import subMinutes from "date-fns/subMinutes";
import lightFormat from "date-fns/lightFormat";
import parse from "date-fns/parse";

import { PromoService } from "../../services";

function universalDateString(date) {
  const timezoneOffset = new Date().getTimezoneOffset();

  return subMinutes(date, timezoneOffset).toUTCString();
}

function resetState() {
  return {
    payment_data_is_approved: false,

    passport: {
      surname: "",
      name: "",
      patronymic: "",
      series: "",
      number: "",
      issuedBy: "",
      dateOfIssue: "",
    },

    personal: {
      dateOfBirth: "",
      INN: "",
      SNILS: "",
      phoneNumber: "",
      registrationAddress: "",
    },

    bank: {
      accountNumber: "",
      name: "",
      BIK: "",
      INN: "",
      KPP: "",
      correspondentAccount: "",
      purposeOfPayment: "",
    },
  };
}

export default {
  namespaced: true,

  state: resetState(),

  getters: {
    paymentDataIsApproved({ payment_data_is_approved }) {
      return payment_data_is_approved;
    },

    passportHasEmptyValue({ passport }) {
      return Object.values(passport).some(isEmpty);
    },

    personalHasEmptyValue({ personal }) {
      return Object.values(personal).some(isEmpty);
    },

    bankHasEmptyValue({ bank }) {
      return Object.values(bank).some(isEmpty);
    },
  },

  mutations: {
    SET_PAYMENT_DATA_IS_APPROVED(state, value) {
      state.payment_data_is_approved = value;
    },

    SET_PAYMENT_DATA(state, data) {
      merge(state, data);
    },

    SET_FIELD(state, field) {
      set(state, field.key, field.value);
    },

    RESET_STATE(state) {
      Object.assign(state, resetState());
    },
  },

  actions: {
    setPaymentData({ commit }, data) {
      const paymentData = cloneDeep(data);

      paymentData.passport.dateOfIssue = lightFormat(
        new Date(data.passport.dateOfIssue),
        "dd.MM.yyyy"
      );

      paymentData.personal.dateOfBirth = lightFormat(
        new Date(data.personal.dateOfBirth),
        "dd.MM.yyyy"
      );

      commit("SET_PAYMENT_DATA", paymentData);
    },

    approvePaymentData({ commit }) {
      commit("SET_PAYMENT_DATA_IS_APPROVED", true);
    },

    async sendPaymentData({ state: { passport, personal, bank } }) {
      const paymentData = cloneDeep({
        passport,
        personal,
        bank,
      });

      paymentData.passport.dateOfIssue = universalDateString(
        parse(passport.dateOfIssue, "dd.MM.yyyy", new Date())
      );

      paymentData.personal.dateOfBirth = universalDateString(
        parse(personal.dateOfBirth, "dd.MM.yyyy", new Date())
      );

      paymentData.personal.SNILS = personal.SNILS.replaceAll(/-|\s/g, "");

      return await PromoService.sendPaymentData(paymentData);
    },

    resetState({ commit }) {
      commit("RESET_STATE");
    },
  },
};
