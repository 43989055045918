var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "reference-help" },
    [
      _c("SupportBadge", {
        staticClass: "reference-help__support-badge mobile",
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "reference-help__column" },
        [
          _c("InstructionsList", {
            staticClass: "reference-help__instructions-list",
          }),
          _vm._v(" "),
          _c(
            "FadeTransition",
            { attrs: { mode: "out-in" } },
            [_c("router-view", { on: { update: _vm.onUpdate } })],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("SupportBadge", { staticClass: "reference-help__support-badge" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }