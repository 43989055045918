import { AuthService } from "./auth.service";

import axiosClient from "./axiosClient.js";

const CalendarService = {
  async fetchSchedule(orgId) {
    const url = `/organization/${orgId}/schedule`;

    const response = await axiosClient.get(url);

    return response.data;
  },

  async fetchTasks({ orgId, params }) {
    const url = `/organization/${orgId}/calendar/tasks`;

    const response = await axiosClient.get(url, { params });

    return response.data;
  },

  async fetchChannels() {
    const url = `/calendar/channels`;

    const response = await axiosClient.get(url);

    return response.data;
  },

  async createReminder(taskId, params /* { date, channels[], comment } */) {
    const url = `/calendar/tasks/${taskId}/reminder`;

    const csrf = await AuthService.fetchCSRF();

    const response = await axiosClient.post(url, undefined, {
      headers: {
        "x-csrf-token": csrf,
      },
      params,
    });

    return response.data;
  },

  async updateReminder(taskId, params /* { date, channels[], comment } */) {
    const url = `/calendar/tasks/${taskId}/reminder`;

    const csrf = await AuthService.fetchCSRF();

    const response = await axiosClient.put(url, undefined, {
      headers: {
        "x-csrf-token": csrf,
      },
      params,
    });

    return response.data;
  },

  async deleteReminder(taskId) {
    const url = `/calendar/tasks/${taskId}/reminder`;

    const csrf = await AuthService.fetchCSRF();

    const response = await axiosClient.delete(url, {
      headers: {
        "x-csrf-token": csrf,
      },
    });

    return response.data;
  },
};

export { CalendarService };
