<template>
  <div class="tabs">
    <div
      ref="overflow-container"
      class="tabs__overflow-container"
      :class="tabsClass"
    >
      <slot></slot>

      <div
        class="tabs__slider"
        :class="sliderClass"
        :style="{ left, width, transition }"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppTabs",

  props: {
    value: {
      type: Object,
      required: true,
      validator(value) {
        if (Object.keys(value).includes("key")) return true;
      },
    },

    sliderClass: {
      type: String,
      default: "",
    },

    tabsClass: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      transition: "0s",
      activeTabElement: undefined,
    };
  },

  computed: {
    left() {
      if (!this.activeTabElement) return "0px";
      return `${this.activeTabElement.offsetLeft}px`;
    },

    width() {
      if (!this.activeTabElement) return "0px";
      return `${this.activeTabElement.offsetWidth}px`;
    },
  },

  mounted() {
    setTimeout(() => {
      this.transition = undefined;
    });

    this.$slots.default.forEach((tab) => {
      if (tab.key === this.value.key) {
        this.activeTabElement = tab.componentInstance.$el;
      }

      tab.componentInstance.$on("select", (tab, vm) => {
        this.$emit("input", tab);

        this.activeTabElement = vm.$el;
        this.scrollElementIntoView(vm.$el);
      });
    });
  },

  methods: {
    scrollElementIntoView(element) {
      const containerWith = this.$el.offsetWidth;

      const left = element.offsetLeft;
      const width = element.offsetWidth;

      const x = left - (containerWith - width) / 2;

      this.$refs["overflow-container"].scrollTo({
        left: x,
        top: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style scoped lang="scss">
.tabs {
  display: flex;
  width: 100%;

  &__overflow-container {
    position: relative;
    z-index: 2;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }

  &__slider {
    position: absolute;
    bottom: 0;
    display: block;
    height: 4px;
    background: $yellow-500;
    transition: $short;
  }

  @media (max-width: 600px) {
    &__slider {
      z-index: 2;
    }
  }
}
</style>
