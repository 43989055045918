var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "success-request" },
    [
      _c("div", { staticClass: "success-request__image" }, [
        _vm.image === "password-restore"
          ? _c("img", {
              attrs: {
                src: require("../../assets/images/password-restore.svg"),
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.image === "service-connection"
          ? _c("img", {
              attrs: {
                src: require("../../assets/images/service-connection.svg"),
              },
            })
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("h2", { staticClass: "success-request__title" }, [
        _vm._v(_vm._s(_vm.title)),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "success-request__description" }, [
        _vm._v("\n    " + _vm._s(_vm.description) + "\n  "),
      ]),
      _vm._v(" "),
      _c(
        "RouterLink",
        {
          staticClass: "success-request__button",
          attrs: { to: { name: "Login" } },
        },
        [_vm._v("\n    Продолжить\n  ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }