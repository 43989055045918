import head from "lodash-es/head";
import property from "lodash-es/property";
import groupBy from "lodash-es/groupBy";
import mapValues from "lodash-es/mapValues";
import reduce from "lodash-es/reduce";

import Vue from "vue";

import { nanoid } from "nanoid";

import Participants from "./chat-participants";
import Sidebar from "./chat-sidebar";
import Filters from "./chat-filters";
import InputPanel from "./chat-input-panel";
import ActionsMenu from "./chat-actions-menu";
import AssignmentRequest from "./chat-assignment-request";

function resetState() {
  return {
    _update: undefined,
    message: {
      all: [],
      threads: [],
    },
  };
}

export default {
  namespaced: true,

  state: resetState(),

  getters: {
    getMessages: (state) => (type) => {
      state._update;

      let messages = state.message[type].map(Vue.observable);

      return messages.slice().reverse();
    },

    messagesGroupedByDay: (state, getters) => (type) => {
      const messages = getters.getMessages(type);

      return groupBy(messages, byDateString);

      function byDateString(message) {
        const date = new Date(message.date);

        return new Date(date.getFullYear(), date.getMonth(), date.getDate()).getTime();
      }
    },

    groupedMessages:
      (state, getters) =>
      ({ id, type }) => {
        const messagesGroupedByDay = getters.messagesGroupedByDay(type);

        let groupKey;

        // {
        //   day_1: {
        //     group_1: [ message_1, message_2, ...],
        //     group_2: [ message_15, message_16, ...],
        //     ...
        //   },
        //   day_2: { ... },
        //   ..
        // };
        // keys for groups calculated as: group[0].date;
        return mapValues(messagesGroupedByDay, reduceGroupByUserAndTemplate);

        function reduceGroupByUserAndTemplate(group) {
          return reduce(group, reducer, {});
        }

        function reducer(result, value, i, group) {
          if (i == 0) {
            // timestamp guaranteed to be unique
            groupKey = group[0].timestamp;

            result[groupKey] = [group[0]];
          } else {
            if (group[i].id == id) {
              groupKey = group[i].timestamp;

              result[groupKey] = [group[i]];
            }
            // group by same author
            else if (group[i].author.id != group[i - 1].author.id) {
              groupKey = group[i].timestamp;

              result[groupKey] = [group[i]];
            }
            // group by fact of movement
            else if (!!group[i].moved) {
              groupKey = group[i].timestamp;

              result[groupKey] = [group[i]];
            }
            // group by same template
            else if (group[i].template != group[i - 1].template) {
              groupKey = group[i].timestamp;

              result[groupKey] = [group[i]];
            }
            // group by email type
            else if (group[i].type == "email" && group[i - 1].type != "email") {
              groupKey = group[i].timestamp;

              result[groupKey] = [group[i]];
            } else if (group[i].type != "email" && group[i - 1].type == "email") {
              groupKey = group[i].timestamp;

              result[groupKey] = [group[i]];
            }
            // group by messenger type
            else if (
              checkMessengerTypeDifference(group[i], group[i - 1], "telegram") ||
              checkMessengerTypeDifference(group[i], group[i - 1], "vk") ||
              checkMessengerTypeDifference(group[i], group[i - 1], "viber") ||
              checkMessengerTypeDifference(group[i], group[i - 1], "facebook") ||
              checkMessengerTypeDifference(group[i], group[i - 1], "skype")
            ) {
              groupKey = group[i].timestamp;

              result[groupKey] = [group[i]];
            } else {
              result[groupKey].push(group[i]);
            }
          }

          return result;

          function checkMessengerTypeDifference(curr, prev, type) {
            return (
              (curr.message?.type == type && prev.message?.type != type) ||
              (curr.message?.type != type && prev.message?.type == type)
            );
          }
        }
      },

    getMessageIds: (state) => (type) => {
      state._update;

      return state.message[type].map(property("id"));
    },

    getHeadMessageDate: (state) => (type) => {
      state._update;

      const date = head(state.messages[type])?.date || "Tue Oct 02 2096 00:00:00 GMT";

      return new Date(date);
    },
  },

  mutations: {
    UPDATE(state) {
      state._update = nanoid();
    },

    RESET_STATE(state) {
      Object.assign(state, resetState());
    },
    UPDATE_MESSAGE(state, payload) {
      state.message[payload.type] = payload.messages;
      state._update = nanoid();
    },
  },

  actions: {
    setMessages({ commit }, data) {
      commit("UPDATE_MESSAGE", data);
      commit("UPDATE");
    },

    resetState({ commit, dispatch }) {
      dispatch("Participants/resetState");
      dispatch("Sidebar/resetState");
      dispatch("Filters/resetState");
      dispatch("InputPanel/resetState");
      dispatch("ActionsMenu/resetState");
      dispatch("AssignmentRequest/resetState");

      commit("RESET_STATE");
    },
  },

  modules: {
    Participants,
    Sidebar,
    Filters,
    InputPanel,
    ActionsMenu,
    AssignmentRequest,
  },
};
