var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.inTable
        ? [
            _c("HighlightInput", {
              attrs: {
                label: "",
                disabled: _vm.disabled,
                "highlight-text":
                  _vm.disabled && _vm.changedAll && _vm.showChanges,
                name: "name_" + Math.random(),
                maxlength: "500",
                autocomplete: "off",
              },
              on: {
                input: function ($event) {
                  return _vm.$emit("input", _vm.modelValue)
                },
                focus: function ($event) {
                  return _vm.$emit("focus")
                },
              },
              scopedSlots: _vm._u(
                [
                  _vm.disabled && _vm.changedAll && _vm.showChanges
                    ? {
                        key: "highlight",
                        fn: function () {
                          return [
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "hint",
                                    rawName: "v-hint",
                                    value: {
                                      text: "Аутсорсер внес исправление",
                                      strategy: "fixed",
                                    },
                                    expression:
                                      "{\n            text: 'Аутсорсер внес исправление',\n            strategy: 'fixed',\n          }",
                                  },
                                ],
                                staticClass: "question-mark",
                              },
                              [_c("span", [_vm._v(_vm._s(_vm.value))])]
                            ),
                          ]
                        },
                        proxy: true,
                      }
                    : null,
                ],
                null,
                true
              ),
              model: {
                value: _vm.modelValue,
                callback: function ($$v) {
                  _vm.modelValue = $$v
                },
                expression: "modelValue",
              },
            }),
          ]
        : [
            _c("HighlightTextarea", {
              staticClass: "question-textarea",
              attrs: {
                label: "Ваш ответ",
                rows: "1",
                maxlength: "500",
                name: "name_" + Math.random(),
                disabled: _vm.disabled,
                "highlight-text":
                  _vm.disabled && _vm.changed && _vm.showChanges,
                "error-message": _vm.errorMessage,
              },
              on: {
                input: function ($event) {
                  return _vm.$emit("input", _vm.modelValue)
                },
                focus: function ($event) {
                  return _vm.$emit("focus")
                },
              },
              scopedSlots: _vm._u(
                [
                  _vm.disabled && _vm.changed && _vm.showChanges
                    ? {
                        key: "highlight",
                        fn: function () {
                          return [
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "hint",
                                    rawName: "v-hint",
                                    value: {
                                      text: "Аутсорсер внес исправление",
                                      strategy: "fixed",
                                    },
                                    expression:
                                      "{\n            text: 'Аутсорсер внес исправление',\n            strategy: 'fixed',\n          }",
                                  },
                                ],
                                staticClass: "question-mark",
                              },
                              [_c("span", [_vm._v(_vm._s(_vm.value))])]
                            ),
                          ]
                        },
                        proxy: true,
                      }
                    : null,
                ],
                null,
                true
              ),
              model: {
                value: _vm.modelValue,
                callback: function ($$v) {
                  _vm.modelValue = $$v
                },
                expression: "modelValue",
              },
            }),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }