import { ThreadService } from "@/services/thread.service";
import * as vue from "vue";
import { nanoid } from "nanoid";
import Vue from "vue";
import UploadingFiles from "@/store/modules/common/uploading-files";

function resetState() {
  return {
    isPopup: {
      type: null,
      data: null,
    },
    threads: {},
    _update: null,

    isLoadingEvaluation: "",
  };
}

export default {
  namespaced: true,

  state: resetState(),

  getters: {
    getPopup(state) {
      return state.isPopup;
    },
    getThreads(state) {
      return state.threads;
    },
    getSpecificThread: (state) => (payload) => {
      state._update;
      return state.threads[payload];
    },
    getLoadingEvaluation(state) {
      return state.isLoadingEvaluation;
    },
  },

  mutations: {
    SET_IS_POPUP(state, payload) {
      state.isPopup = payload;
    },

    SET_LOADING_EVALUATION(state, payload) {
      state.isLoadingEvaluation = payload;
    },

    UPDATE_THREAD(state, payload) {
      if (payload.thread_id in state.threads) {
        const thread = { ...state.threads[payload.thread_id], ...payload };
        vue.set(state.threads, payload.thread_id, thread);
      } else {
        vue.set(state.threads, payload.thread_id, payload);
      }
    },

    SET_THREADS(state, payload) {
      state._update = nanoid();
      payload.forEach((thread) => {
        vue.set(state.threads, thread.id, thread);
      });
    },
    RESET_STATE(state) {
      Object.assign(state, resetState());
    },
  },

  actions: {
    resetStateThreads({ commit }) {
      commit("RESET_STATE");
    },

    async getThread({ commit }, data) {
      try {
        const response = await ThreadService.fetchGetThread(data);

        commit("SET_THREADS", response);

        return response;
      } catch (e) {
        Vue.notify({
          type: "error",
          text: "Ошибка при получении списка тредов",
        });
      }
    },

    updateThread({ commit }, data) {
      data.forEach((thread) => {
        commit("UPDATE_THREAD", thread);
      });
    },

    updatePopup({ commit }, payload) {
      commit("SET_IS_POPUP", payload);
    },

    async updateEvaluation({ commit }, data) {
      try {
        commit("SET_LOADING_EVALUATION", data.name);

        const response = await ThreadService.fetchUpdateEvaluation(data);
        commit("SET_LOADING_EVALUATION", "");
        return response;
      } catch (e) {
        Vue.notify({
          type: "error",
          text: e,
        });
      }
    },
  },

  modules: {
    UploadingFiles: UploadingFiles(),
  },
};
