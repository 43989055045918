import isEqual from "lodash-es/isEqual";
import cloneDeep from "lodash-es/cloneDeep";

import { UserService } from "../../services";

import qs from "qs";

function minutesToUTCString(minutes) {
  const date = new Date(0);

  date.setUTCHours(0, minutes);

  return date.toUTCString();
}

function resetState() {
  return {
    current_settings: undefined,
    changed_settings: undefined,
    loading: false,
  };
}

export default {
  namespaced: true,

  state: resetState(),

  getters: {
    isDirty({ current_settings, changed_settings }) {
      return !isEqual(current_settings, changed_settings);
    },

    isInvalid({ changed_settings }) {
      return changed_settings.timezone_city.id.length == 0;
    },

    isLoading({ loading }) {
      return loading;
    },

    getCurrentSettings({ current_settings }) {
      return current_settings;
    },

    getChangedSettings({ changed_settings }) {
      return changed_settings;
    },

    getTimezoneCityId({ changed_settings }) {
      return changed_settings.timezone_city.id;
    },

    getTimezoneOffset({ changed_settings }) {
      return changed_settings.timezone.offset;
    },

    getStart({ changed_settings }) {
      return changed_settings.start;
    },

    getEnd({ changed_settings }) {
      return changed_settings.end;
    },

    getWeekdays({ changed_settings }) {
      return changed_settings.weekdays.split("");
    },

    getSettingsObjectForUpdateRequest({ changed_settings }) {
      const transformed = {
        notifications: {
          start: minutesToUTCString(changed_settings.start),
          end: minutesToUTCString(changed_settings.end),
          timezone_city_id: changed_settings.timezone_city.id,
          weekdays: changed_settings.weekdays,
        },
      };

      const queryString = qs.stringify(transformed, { encode: false });
      const newSetting = qs.parse(queryString, { depth: 0 });

      return newSetting;
    },
  },

  mutations: {
    SET_CURRENT_SETTINGS(state, settings) {
      state.current_settings = settings;
    },

    SET_CHANGED_SETTINGS(state, settings) {
      state.changed_settings = settings;
    },

    SET_TIMEZONE_CITY_ID(state, id) {
      state.changed_settings.timezone_city.id = id;
    },

    SET_START(state, minutes) {
      state.changed_settings.start = minutes;
    },

    SET_END(state, minutes) {
      state.changed_settings.end = minutes;
    },

    SET_WEEKDAYS(state, weekdaysString) {
      state.changed_settings.weekdays = weekdaysString;
    },

    SET_IS_LOADING(state, value) {
      state.loading = value;
    },

    RESET_STATE(state) {
      Object.assign(state, resetState());
    },
  },

  actions: {
    init({ commit }, settings) {
      if (!settings?.timezone_city)
        // to reactively detect changes
        settings.timezone_city = {
          id: "",
          name: "",
        };

      commit("SET_CURRENT_SETTINGS", cloneDeep(settings));
      commit("SET_CHANGED_SETTINGS", cloneDeep(settings));
    },

    async update({ commit }, newSettings) {
      commit("SET_IS_LOADING", true);

      try {
        const responseSettings = await UserService.updateNotificationsSettings(
          newSettings
        );

        const currentSettings = cloneDeep(responseSettings.notifications);
        const changedSettings = cloneDeep(responseSettings.notifications);

        commit("SET_CURRENT_SETTINGS", currentSettings);
        commit("SET_CHANGED_SETTINGS", changedSettings);
      } finally {
        commit("SET_IS_LOADING", false);
      }
    },

    resetState({ commit }) {
      commit("RESET_STATE");
    },
  },
};
