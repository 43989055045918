<template>
  <div class="success-request">
    <div class="success-request__image">
      <img
        v-if="image === 'password-restore'"
        src="../../assets/images/password-restore.svg"
      />
      <img
        v-if="image === 'service-connection'"
        src="../../assets/images/service-connection.svg"
      />
    </div>

    <h2 class="success-request__title">{{ title }}</h2>

    <div class="success-request__description">
      {{ description }}
    </div>

    <RouterLink class="success-request__button" :to="{ name: 'Login' }">
      Продолжить
    </RouterLink>
  </div>
</template>

<script>
export default {
  name: "SuccessRequest",

  props: {
    image: {
      type: String,
      required: true,
    },

    title: {
      type: String,
      required: true,
    },

    description: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
.success-request {
  display: flex;
  max-width: 332px;
  flex-direction: column;
  align-items: center;

  &__image {
    margin-bottom: 40px;
  }

  &__title {
    margin-bottom: 18px;
    font-size: 24px;
    font-weight: 500;
    text-align: center;
  }

  &__description {
    margin-bottom: 22px;
    color: $grey-600;
    text-align: center;
  }

  &__button {
    @include gradient-button;

    color: $black;
  }
}
</style>
