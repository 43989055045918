var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "change-password" }, [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "form",
      [
        _c("CabTextField", {
          staticClass: "change-password__input",
          attrs: {
            autocomplete: "new-password",
            label: "Пароль",
            name: "change-password",
            placeholder: " Введите пароль",
            type: _vm.passwordType,
          },
          on: {
            input: function ($event) {
              _vm.errorMessage = ""
            },
          },
          scopedSlots: _vm._u([
            {
              key: "appendedIcon",
              fn: function () {
                return [
                  _c("EyeIcon", {
                    staticClass: "login-form__password-icon",
                    attrs: { "is-striked": _vm.passwordType == "password" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.onIconClick.apply(null, arguments)
                      },
                    },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.password,
            callback: function ($$v) {
              _vm.password = $$v
            },
            expression: "password",
          },
        }),
        _vm._v(" "),
        _c("CabTextField", {
          staticClass: "change-password__input",
          attrs: {
            autocomplete: "new-password",
            label: "Подтверждение пароля",
            name: "change-password-repeat",
            placeholder: " Введите пароль",
            type: _vm.passwordType,
            "error-message": _vm.errorMessage,
          },
          on: {
            input: function ($event) {
              _vm.errorMessage = ""
            },
          },
          scopedSlots: _vm._u([
            {
              key: "appendedIcon",
              fn: function () {
                return [
                  _c("EyeIcon", {
                    staticClass: "login-form__password-icon",
                    attrs: { "is-striked": _vm.passwordType == "password" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.onIconClick.apply(null, arguments)
                      },
                    },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.repeatPassword,
            callback: function ($$v) {
              _vm.repeatPassword = $$v
            },
            expression: "repeatPassword",
          },
        }),
        _vm._v(" "),
        _c(
          "ButtonWithLoader",
          {
            staticClass: "modal__send",
            attrs: { "is-loading": _vm.isLoading, "is-blocked": _vm.isBlocked },
            on: { click: _vm.changePassword },
          },
          [_vm._v("\n      Установить\n    ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "change-password__header" }, [
      _c("h2", { staticClass: "change-password__title" }, [
        _vm._v("Введите новый пароль:"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }