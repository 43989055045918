var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "block" },
    [
      _c("div", { staticClass: "block__faux-input faux-input" }, [
        _c("div", { staticClass: "faux-input__label" }, [_vm._v("Имя")]),
        _vm._v(" "),
        _c("div", { staticClass: "faux-input__value" }, [
          _vm._v(_vm._s(_vm.name)),
        ]),
        _vm._v(" "),
        _c("img", {
          staticClass: "faux-input__icon",
          attrs: { src: require("@icons/edit.svg") },
          on: { click: _vm.openNameModal },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "block__faux-input faux-input" }, [
        _c("div", { staticClass: "faux-input__label" }, [_vm._v("Должность")]),
        _vm._v(" "),
        _c("div", { staticClass: "faux-input__value" }, [
          _vm._v(_vm._s(_vm.position)),
        ]),
        _vm._v(" "),
        _c("img", {
          staticClass: "faux-input__icon",
          attrs: { src: require("@icons/edit.svg") },
          on: { click: _vm.openPositionModal },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "block__faux-input faux-input" }, [
        _c("div", { staticClass: "faux-input__label" }, [
          _vm._v("Адрес электронной почты"),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "faux-input__value" }, [
          _vm._v(_vm._s(_vm.email)),
        ]),
        _vm._v(" "),
        _c("img", {
          staticClass: "faux-input__icon",
          attrs: { src: require("@icons/edit.svg") },
          on: { click: _vm.openEmailModal },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "block__faux-input faux-input" }, [
        _c("div", { staticClass: "faux-input__label" }, [
          _vm._v("Номер телефона"),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "faux-input__value" }, [
          _vm._v(_vm._s(_vm.phone)),
        ]),
        _vm._v(" "),
        _c("img", {
          staticClass: "faux-input__icon",
          attrs: { src: require("@icons/edit.svg") },
          on: { click: _vm.openPhoneModal },
        }),
      ]),
      _vm._v(" "),
      _c(
        "FadeTransition",
        [
          _vm.openedModal == "name"
            ? _c("AppModal", {
                attrs: { "max-width": 360 },
                on: { close: _vm.closeModal },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function () {
                        return [_vm._v("Изменение имени")]
                      },
                      proxy: true,
                    },
                    {
                      key: "body",
                      fn: function () {
                        return [
                          _c("CabTextField", {
                            staticClass: "modal__input",
                            attrs: {
                              label: "Новое имя",
                              name: "change-user-name",
                              placeholder: " Введите имя",
                              "error-message": _vm.nameErrorMessage,
                            },
                            on: {
                              input: function ($event) {
                                _vm.nameErrorMessage = ""
                              },
                            },
                            model: {
                              value: _vm.newName,
                              callback: function ($$v) {
                                _vm.newName =
                                  typeof $$v === "string" ? $$v.trim() : $$v
                              },
                              expression: "newName",
                            },
                          }),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "footer",
                      fn: function () {
                        return [
                          _c(
                            "div",
                            { staticClass: "modal__buttons" },
                            [
                              _c("div", { staticClass: "filler" }),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "modal__cancel",
                                  on: { click: _vm.closeModal },
                                },
                                [_vm._v("Отмена")]
                              ),
                              _vm._v(" "),
                              _c(
                                "ButtonWithLoader",
                                {
                                  staticClass: "modal__save",
                                  attrs: {
                                    "is-blocked": !_vm.$v.newName.required,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onClick("name")
                                    },
                                  },
                                },
                                [_vm._v("\n            Изменить\n          ")]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  344594528
                ),
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.openedModal == "position"
            ? _c("AppModal", {
                attrs: { "max-width": 360 },
                on: { close: _vm.closeModal },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function () {
                        return [_vm._v("Изменение должности")]
                      },
                      proxy: true,
                    },
                    {
                      key: "body",
                      fn: function () {
                        return [
                          _c("CabTextField", {
                            staticClass: "modal__input",
                            attrs: {
                              label: "Новая должность",
                              name: "change-user-name",
                              placeholder: " Введите должность",
                              "error-message": _vm.positionErrorMessage,
                            },
                            on: {
                              input: function ($event) {
                                _vm.positionErrorMessage = ""
                              },
                            },
                            model: {
                              value: _vm.newPosition,
                              callback: function ($$v) {
                                _vm.newPosition =
                                  typeof $$v === "string" ? $$v.trim() : $$v
                              },
                              expression: "newPosition",
                            },
                          }),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "footer",
                      fn: function () {
                        return [
                          _c(
                            "div",
                            { staticClass: "modal__buttons" },
                            [
                              _c("div", { staticClass: "filler" }),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "modal__cancel",
                                  on: { click: _vm.closeModal },
                                },
                                [_vm._v("Отмена")]
                              ),
                              _vm._v(" "),
                              _c(
                                "ButtonWithLoader",
                                {
                                  staticClass: "modal__save",
                                  attrs: {
                                    "is-blocked": !_vm.$v.newPosition.required,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onClick("position")
                                    },
                                  },
                                },
                                [_vm._v("\n            Изменить\n          ")]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  653757825
                ),
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.openedModal == "email"
            ? _c("AppModal", {
                attrs: { "max-width": 360 },
                on: { close: _vm.closeModal },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function () {
                        return [_vm._v("Изменение E-mail")]
                      },
                      proxy: true,
                    },
                    {
                      key: "body",
                      fn: function () {
                        return [
                          _c("CabTextField", {
                            staticClass: "modal__input",
                            attrs: {
                              label: "Новый E-mail",
                              name: "change-user-name",
                              placeholder: " Введите E-mail",
                              "error-message": _vm.emailErrorMessage,
                            },
                            on: {
                              input: function ($event) {
                                _vm.emailErrorMessage = ""
                              },
                            },
                            model: {
                              value: _vm.newEmail,
                              callback: function ($$v) {
                                _vm.newEmail =
                                  typeof $$v === "string" ? $$v.trim() : $$v
                              },
                              expression: "newEmail",
                            },
                          }),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "footer",
                      fn: function () {
                        return [
                          _c(
                            "div",
                            { staticClass: "modal__buttons" },
                            [
                              _c("div", { staticClass: "filler" }),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "modal__cancel",
                                  on: { click: _vm.closeModal },
                                },
                                [_vm._v("Отмена")]
                              ),
                              _vm._v(" "),
                              _c(
                                "ButtonWithLoader",
                                {
                                  staticClass: "modal__save",
                                  attrs: {
                                    "is-blocked": !_vm.$v.newEmail.required,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onClick("email")
                                    },
                                  },
                                },
                                [_vm._v("\n            Изменить\n          ")]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  2152674631
                ),
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.openedModal == "phone"
            ? _c("AppModal", {
                attrs: { "max-width": 360 },
                on: { close: _vm.closeModal },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function () {
                        return [_vm._v("Изменение номера телефона")]
                      },
                      proxy: true,
                    },
                    {
                      key: "body",
                      fn: function () {
                        return [
                          _c("CabTextField", {
                            staticClass: "modal__input",
                            attrs: {
                              label: "Новый номер телефона",
                              name: "change-user-name",
                              placeholder: " Введите номер телефона",
                              "error-message": _vm.phoneErrorMessage,
                            },
                            on: {
                              input: function ($event) {
                                _vm.phoneErrorMessage = ""
                              },
                            },
                            model: {
                              value: _vm.newPhone,
                              callback: function ($$v) {
                                _vm.newPhone =
                                  typeof $$v === "string" ? $$v.trim() : $$v
                              },
                              expression: "newPhone",
                            },
                          }),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "footer",
                      fn: function () {
                        return [
                          _c(
                            "div",
                            { staticClass: "modal__buttons" },
                            [
                              _c("div", { staticClass: "filler" }),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "modal__cancel",
                                  on: { click: _vm.closeModal },
                                },
                                [_vm._v("Отмена")]
                              ),
                              _vm._v(" "),
                              _c(
                                "ButtonWithLoader",
                                {
                                  staticClass: "modal__save",
                                  attrs: {
                                    "is-blocked": !_vm.$v.newPhone.required,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onClick("phone")
                                    },
                                  },
                                },
                                [_vm._v("\n            Изменить\n          ")]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  117889762
                ),
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }