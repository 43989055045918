var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "slider" },
    [
      _c(
        "div",
        {
          staticClass: "slider__button slider__prev",
          class: { blocked: _vm.isAtStart },
          on: {
            click: function ($event) {
              return _vm.$emit("slide-prev")
            },
          },
        },
        [
          _c(
            "svg",
            {
              attrs: {
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg",
                viewBox: "0 0 24 24",
              },
            },
            [
              _c("path", {
                attrs: {
                  "fill-rule": "evenodd",
                  "clip-rule": "evenodd",
                  d: "M15.36 4.32c.7.53.85 1.53.32 2.24L11.6 12l4.08 5.44a1.6 1.6 0 1 1-2.56 1.92l-4.8-6.4a1.6 1.6 0 0 1 0-1.92l4.8-6.4a1.6 1.6 0 0 1 2.24-.32Z",
                  fill: "currentColor",
                },
              }),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _vm._l(_vm.agents, function (agent, i) {
        return _c(
          "div",
          { key: agent.id, staticClass: "slider__agent-card agent-card" },
          [
            _c("div", { staticClass: "agent-card__avatar avatar" }, [
              agent.avatar && agent.avatar.thumb
                ? _c("img", {
                    staticClass: "avatar__image",
                    attrs: { src: agent.avatar.thumb.url },
                  })
                : _c("div", { staticClass: "avatar__image" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm._f("nameInitials")(agent.name)) +
                        "\n      "
                    ),
                  ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "agent-card__name" }, [
              _vm._v("\n      " + _vm._s(agent.name) + "\n    "),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "agent-card__position" }, [
              _vm._v("\n      " + _vm._s(agent.position) + "\n    "),
            ]),
            _vm._v(" "),
            agent.email
              ? _c(
                  "a",
                  {
                    staticClass: "agent-card__email",
                    attrs: { href: `mailto:${agent.email}` },
                  },
                  [_vm._v("\n      " + _vm._s(agent.email) + "\n    ")]
                )
              : _vm._e(),
            _vm._v(" "),
            agent.phone
              ? _c(
                  "a",
                  {
                    staticClass: "agent-card__phone",
                    attrs: { href: `tel:${agent.phone}` },
                  },
                  [_vm._v("\n      " + _vm._s(agent.phone) + "\n    ")]
                )
              : _vm._e(),
          ]
        )
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "slider__button slider__next",
          class: { blocked: _vm.isAtEnd },
          on: {
            click: function ($event) {
              return _vm.$emit("slide-next")
            },
          },
        },
        [
          _c(
            "svg",
            {
              attrs: {
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg",
                viewBox: "0 0 24 24",
              },
            },
            [
              _c("path", {
                attrs: {
                  "fill-rule": "evenodd",
                  "clip-rule": "evenodd",
                  d: "M8.64 19.68a1.6 1.6 0 0 1-.32-2.24L12.4 12 8.32 6.56a1.6 1.6 0 1 1 2.56-1.92l4.8 6.4a1.6 1.6 0 0 1 0 1.92l-4.8 6.4a1.6 1.6 0 0 1-2.24.32Z",
                  fill: "currentColor",
                },
              }),
            ]
          ),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }