<template>
  <div class="city-select">
    <CabTextField
      v-model="input"
      class="city-select__input"
      label="Город"
      name="city-select"
      autocomplete="off"
      @blur="onFormInputBlur"
    >
      <template #appendedIcon>
        <img
          v-if="selectableCitiesListIsEmpty"
          src="@icons/arrow-closed.svg"
          alt="arrow-closed.svg"
        />
        <img v-else src="@icons/arrow-open.svg" alt="arrow-open.svg" />
      </template>
    </CabTextField>

    <ItemsBox
      key-string="name"
      class="city-select__cities-list"
      :is-empty="selectableCitiesListIsEmpty"
      @select="onCitiesListSelect"
    >
      <div
        v-for="city in selectableCities"
        :key="city.id"
        tabindex="0"
        class="items-box__item"
        @click="selectCity(city)"
        @keyup.enter="selectCity(city)"
        @focus="onCitiesListFocus"
        @blur="onCitiesListBlur"
      >
        {{ city.name }}
      </div>
    </ItemsBox>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import CabTextField from "../common/CabTextField.vue";
import ItemsBox from "../common/ItemsBox.vue";

export default {
  name: "CitySelect",

  components: {
    CabTextField,
    ItemsBox,
  },

  data() {
    return {
      input: "",
      focusIsInCitiesList: false,
      blankCity: {
        name: "",
        id: "",
      },
    };
  },

  computed: {
    ...mapGetters("Info", ["getCitiesFilteredByName", "getCityMatchedByName"]),

    filteredCities() {
      return this.getCitiesFilteredByName(this.input);
    },

    matchedCity() {
      return this.getCityMatchedByName(this.input);
    },

    selectableCities() {
      if (this.input == "" || this.matchedCity) return [];

      return this.filteredCities;
    },

    selectableCitiesListIsEmpty() {
      return this.selectableCities.length === 0;
    },
  },

  watch: {
    matchedCity(val) {
      if (val == undefined) {
        this.$emit("select", this.blankCity);
      } else {
        this.$emit("select", val);
      }
    },
  },

  methods: {
    onFormInputBlur() {
      setTimeout(() => {
        if (this.focusIsInCitiesList) return;

        if (!this.matchedCity) {
          this.input = "";
        }
      });
    },

    onCitiesListSelect(city) {
      this.input = city;
    },

    onCitiesListFocus() {
      this.focusIsInCitiesList = true;
    },

    onCitiesListBlur() {
      this.focusIsInCitiesList = false;
    },

    selectCity(city) {
      this.input = city.name;
      this.$emit("select", city);
    },
  },
};
</script>

<style scoped lang="scss">
.city-select {
  height: 34px;

  &__cities-list {
    position: relative;
    z-index: 1;
    top: 14px;
  }
}
</style>
