import * as linkify from "linkifyjs";

linkify.registerCustomProtocol("topic");

linkify.registerPlugin("topic", ({ scanner, parser, utils }) => {
  const { OPENANGLEBRACKET, CLOSEANGLEBRACKET, POUND, DOMAIN } = scanner.tokens;

  let STATE = parser.start;
  const Topic = utils.createTokenClass("topic");

  STATE = STATE.tt(OPENANGLEBRACKET);
  STATE = STATE.tt(POUND);
  STATE = STATE.tt(DOMAIN);
  STATE.tt(CLOSEANGLEBRACKET, Topic);
});
