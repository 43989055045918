import { AuthService } from "./auth.service";

import axiosClient from "./axiosClient.js";

class ValidationError extends Error {
  constructor(errors, message) {
    super(message);
    this.name = this.constructor.name;
    this.message = message;
    this.errors = errors;
  }
}

const PromoService = {
  async fetchRegisteredClients() {
    let response;

    try {
      response = await axiosClient.get("/promo/invite/clients");
    } catch (error) {
      if (error.response) {
        if (error.response.data.error) {
          throw new Error(error.response.data.error);
        } else {
          throw error;
        }
      }
    }

    return response.data;
  },

  async registerNewClient(client) {
    const csrf = await AuthService.fetchCSRF();

    let response;

    try {
      response = await axiosClient.post("/promo/invite/clients", client, {
        headers: {
          "x-csrf-token": csrf,
        },
      });
    } catch (error) {
      if (error.response) {
        if (error.response.data.error) {
          throw new Error(error.response.data.error);
        } else {
          throw error;
        }
      }
    }

    return response.data;
  },

  async fetchPaymentData() {
    let response;

    try {
      response = await axiosClient.get("/promo/invite/payment-data");
    } catch (error) {
      if (error.response) {
        if (error.response.status == 404) {
          // it's ok, just ignore this response
        } else if (error.response.data.error) {
          throw new Error(error.response.data.error);
        } else {
          throw error;
        }
      }
    }

    return response?.data;
  },

  async sendPaymentData(data) {
    const csrf = await AuthService.fetchCSRF();

    let response;

    try {
      response = await axiosClient.post("/promo/invite/payment-data", data, {
        headers: {
          "x-csrf-token": csrf,
        },
      });
    } catch (error) {
      if (error.response) {
        if (error.response.data.error) {
          const errors = error.response.data.error;

          throw new ValidationError(errors, "Validation Error");
        } else {
          throw new Error(
            "Неизвестная ошибка в POST /promo/invite/payment-data"
          );
        }
      }
    }

    return response.data;
  },
};

export { PromoService, ValidationError };
