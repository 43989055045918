import findIndex from "lodash-es/findIndex";

import UploadingFiles from "./common/uploading-files";

import { DocumentsService, InfoService } from "../../services";
import Vue from "vue";

function resetState() {
  return {
    notifications_status: undefined,
    notifications_status_is_loading: false,
    notifications_status_is_updating: false,

    statements: [],
    statements_are_loading: false,
    clients: [],
    clients_are_loading: false,
    debits_are_loading: false,
    documents: [],
    // extendedClients: [],
  };
}

export default {
  namespaced: true,

  state: resetState(),

  getters: {
    getNotificationsStatus({ notifications_status }) {
      return notifications_status;
    },

    isNotificationsStatusLoading({ notifications_status_is_loading }) {
      return notifications_status_is_loading;
    },

    isNotificationsStatusUpdating({ notifications_status_is_updating }) {
      return notifications_status_is_updating;
    },

    getStatements({ statements }) {
      return statements;
    },

    areStatementsLoading({ statements_are_loading }) {
      return statements_are_loading;
    },

    getClients({ clients }) {
      return clients;
    },

    areClientsLoading({ clients_are_loading }) {
      return clients_are_loading;
    },

    areDebitsLoading({ debits_are_loading }) {
      return debits_are_loading;
    },

    getDocumentsList(state) {
      return state.documents;
    },
  },

  mutations: {
    SET_NOTIFICATIONS_STATUS(state, value) {
      state.notifications_status = value;
    },

    SET_NOTIFICATIONS_STATUS_IS_LOADING(state, value) {
      state.notifications_status_is_loading = value;
    },

    SET_NOTIFICATIONS_STATUS_IS_UPDATING(state, value) {
      state.notifications_status_is_updating = value;
    },

    SET_STATEMENTS(state, statements) {
      state.statements = statements;
    },

    REPLACE_STATEMENT(state, statement) {
      const index = findIndex(state.statements, ["account.id", statement.account.id]);

      if (index == -1) return;

      state.statements.splice(index, 1, statement);
    },

    SET_STATEMENTS_ARE_LOADING(state, value) {
      state.statements_are_loading = value;
    },

    RESET_STATE(state) {
      Object.assign(state, resetState());
    },

    SET_CLIENTS(state, clients) {
      state.clients = clients;
    },

    SET_CLIENTS_ARE_LOADING(state, value) {
      state.clients_are_loading = value;
    },

    SET_DEBITS_ARE_LOADING(state, value) {
      state.debits_are_loading = value;
    },

    SET_DOCUMENTS(state, value) {
      state.documents = value;
    },
  },

  actions: {
    async loadNotificationsStatus({ commit, rootGetters }, orgId) {
      commit("SET_NOTIFICATIONS_STATUS_IS_LOADING", true);

      try {
        const status = await DocumentsService.fetchNotificationsSuspension(orgId);

        if (rootGetters["Organizations/ActiveOne/getId"] != orgId) return;

        commit("SET_NOTIFICATIONS_STATUS", status);
      } finally {
        if (rootGetters["Organizations/ActiveOne/getId"] == orgId) {
          commit("SET_NOTIFICATIONS_STATUS_IS_LOADING", false);
        }
      }
    },

    async loadStatements({ commit, rootGetters }, orgId) {
      commit("SET_STATEMENTS_ARE_LOADING", true);

      try {
        const statements = await DocumentsService.fetchStatements(orgId);

        if (rootGetters["Organizations/ActiveOne/getId"] != orgId) return;

        commit("SET_STATEMENTS", statements);
      } finally {
        if (rootGetters["Organizations/ActiveOne/getId"] == orgId) {
          commit("SET_STATEMENTS_ARE_LOADING", false);
        }
      }
    },

    async updateNotificationsStatus({ commit }, { orgId, update }) {
      commit("SET_NOTIFICATIONS_STATUS_IS_UPDATING", true);

      try {
        const status = await DocumentsService.updateNotificationsSuspension({
          orgId,
          update,
        });

        commit("SET_NOTIFICATIONS_STATUS", status);
      } finally {
        commit("SET_NOTIFICATIONS_STATUS_IS_UPDATING", false);
      }
    },

    async uploadStatementFiles({ commit }, { bankAccountId, statementFilesIds }) {
      const statement = await DocumentsService.addStatementFiles({
        bankAccountId,
        statementFilesIds,
      });

      commit("REPLACE_STATEMENT", statement);
    },

    async updateNoCashPeriod({ commit }, { bankAccountId, period }) {
      const statement = await DocumentsService.updateNoCashPeriod({
        bankAccountId,
        period,
      });

      commit("REPLACE_STATEMENT", statement);
    },

    async loadClients({ commit, rootGetters }, orgId) {
      commit("SET_CLIENTS_ARE_LOADING", true);

      try {
        let clients = [];

        if (orgId) {
          clients = await DocumentsService.fetchClients(orgId);
        }
        if (rootGetters["Organizations/ActiveOne/getId"] !== orgId) return;

        commit("SET_CLIENTS", clients);

        return clients;
      } finally {
        if (rootGetters["Organizations/ActiveOne/getId"] === orgId) {
          commit("SET_CLIENTS_ARE_LOADING", false);
        }
      }
    },

    async fetchDebitsBatch(ctx, options) {
      const debits = await DocumentsService.fetchDebitsBatch(options);

      return debits;
    },

    async fetchDebitsByContract({ commit }, options) {
      commit("SET_DEBITS_ARE_LOADING", true);

      try {
        const debits = await DocumentsService.fetchDebitsByContract(options);

        return debits;
      } finally {
        commit("SET_DEBITS_ARE_LOADING", false);
      }
    },

    async fetchDebit(ctx, debitId) {
      const debit = await DocumentsService.fetchDebit(debitId);

      return debit;
    },

    async debitsSuspension(ctx, options) {
      const result = await DocumentsService.debitsSuspension(options);

      return result;
    },

    async uploadClosingFiles(ctx, options) {
      const upload = await DocumentsService.addClosingFiles(options);

      return upload;
    },

    async fetchDocumentsList({ commit }, orgId) {
      try {
        const response = await InfoService.fetchDocuments(orgId);

        commit("SET_DOCUMENTS", response);
      } catch (e) {
        Vue.notify({
          type: "error",
          text: "Ошибка при получении списка документов",
        });
      }
    },

    resetState({ commit, dispatch }) {
      dispatch("UploadingFiles/resetState");

      commit("RESET_STATE");
    },
  },

  modules: {
    UploadingFiles: UploadingFiles(),
  },
};
