var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "downloads-link" }, [
    _vm._v("\n  " + _vm._s(_vm.name) + "\n  "),
    _c("div", { staticClass: "downloads-link__description-wrapper" }, [
      _c("img", {
        directives: [
          {
            name: "hint",
            rawName: "v-hint.clickable",
            value: {
              text: _vm.description,
              boundarySelector: ".app__main",
            },
            expression:
              "{\n        text: description,\n        boundarySelector: '.app__main',\n      }",
            modifiers: { clickable: true },
          },
        ],
        staticClass: "downloads-link__description-activator",
        attrs: { src: require("@icons/reference/question-mark.svg") },
      }),
    ]),
    _vm._v(" "),
    _c(
      "a",
      {
        staticClass: "downloads-link__download-link",
        attrs: { href: _vm.url_download, target: "_blank" },
      },
      [_c("img", { attrs: { src: require("@icons/reference/download.svg") } })]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }