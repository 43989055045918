var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "reference-instructions-list" }, [
    _c("div", { staticClass: "reference-instructions-list__title" }, [
      _vm._v("Инструкции"),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "reference-instructions-list__list" },
      _vm._l(_vm.instructions, function (instruction, i) {
        return _c(
          "router-link",
          {
            key: instruction,
            staticClass: "reference-instructions-list__link",
            attrs: {
              to: { name: "ReferenceInstructions", params: { id: i + 1 } },
            },
          },
          [
            _c("img", {
              attrs: {
                src: require("@icons/instruction.svg"),
                alt: "instruction.svg",
              },
            }),
            _vm._v("\n      " + _vm._s(instruction) + "\n    "),
          ]
        )
      }),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }