import {
  // overviewIcon,
  chatIcon,
  calendarIcon,
  journalsIcon,
  documentsIcon,
  tariffIcon,
  promoIcon,
  referenceIcon,
} from "../assets/icons/raw-svg/navigation-drawer";

import contractIcon from "../assets/icons/raw-svg/tabs/contract.svg";
import responsibleIcon from "../assets/icons/raw-svg/tabs/responsible.svg";
import outsourcerIcon from "../assets/icons/raw-svg/tabs/outsourcer.svg";

import helpIcon from "../assets/icons/raw-svg/tabs/help.svg";
import appsIcon from "../assets/icons/raw-svg/tabs/apps.svg";

import allIcon from "../assets/icons/raw-svg/tabs/all.svg";
import personalIcon from "../assets/icons/raw-svg/tabs/personal.svg";
import assignmentsIcon from "../assets/icons/raw-svg/tabs/assignments.svg";
import cogIcon from "@/assets/icons/colorless/cog.svg";
import fileIcon from "@/assets/icons/colorless/file.svg";

const navigationItems = {
  // overview: {
  //   path: "/overview",
  //   text: "Обзор",
  //   icon: overviewIcon,
  // },

  chat: {
    path: "/chat",
    text: "Чат с аутсорсером",
    icon: chatIcon,
  },

  calendar: {
    path: "/calendar",
    text: "График взаимодействия",
    icon: calendarIcon,
  },

  journals: {
    path: "/journals",
    text: "Журналы",
    icon: journalsIcon,
  },

  documents: {
    path: "/documents",
    text: "Документы",
    icon: documentsIcon,
  },

  tariff: {
    path: "/contract",
    text: "Договор",
    icon: tariffIcon,
  },

  promo: {
    path: "/promo",
    text: "Акции",
    icon: promoIcon,
  },

  reference: {
    path: "/reference",
    text: "Справка",
    icon: referenceIcon,
  },
};

const tariffTabs = [
  {
    key: "document",
    text: "Договор",
    icon: contractIcon,
  },
  {
    key: "responsible",
    text: "Уполномоченные представители",
    icon: responsibleIcon,
  },
  {
    key: "outsourcer",
    text: "Аутсорсер",
    icon: outsourcerIcon,
  },
];

const referenceTabs = [
  {
    key: "help",
    name: "ReferenceHelp",
    text: "Помощь",
    icon: helpIcon,
  },
  {
    key: "downloads",
    name: "ReferenceDownloads",
    text: "Приложения",
    icon: appsIcon,
  },
];

const calendarTabs = [
  {
    key: "all",
    text: "Все",
    icon: allIcon,
  },
  {
    key: "my",
    text: "Мои дела",
    icon: personalIcon,
  },
  {
    key: "outsourcer",
    text: "Аутсорсер",
    icon: outsourcerIcon,
  },
  {
    key: "assignment",
    text: "Поручения",
    icon: assignmentsIcon,
  },
];

const documentsTabs = [
  {
    key: "statements",
    text: "Выписки",
    // icon: allIcon,
  },
  {
    key: "closing",
    text: "Закрывающие документы",
    // icon: personalIcon,
  },
];

const accountTabs = [
  {
    key: "user-settings",
    text: "Настройки",
    icon: cogIcon,
  },
  {
    key: "tech-audit",
    text: "Анкета по используемым технологиям",
    icon: fileIcon,
  },
];

// eslint-disable-next-line no-undef
if (VUE_APP_HIDE_ASSIGNMENTS_IN_CALENDAR_SECTION) {
  calendarTabs.pop();
}

// eslint-disable-next-line no-undef
if (VUE_APP_HIDE_AGENTS_TAB_IN_TARIFF_SECTION) {
  tariffTabs.splice(1, 1);
}

// eslint-disable-next-line no-undef
if (VUE_APP_HIDE_AUDIT_TAB_IN_ACCOUNT_SECTION) {
  accountTabs.pop();
}

// eslint-disable-next-line no-undef
if (VUE_APP_HIDE_DOCUMENTS_SECTION) {
  delete navigationItems.documents;
}

export {
  navigationItems,
  tariffTabs,
  referenceTabs,
  calendarTabs,
  documentsTabs,
  accountTabs,
};
