var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("notifications", {
    attrs: { position: "bottom right", duration: 3000, width: "none", max: 3 },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function ({ item, close }) {
          return [
            _c("div", { staticClass: "notification", on: { click: close } }, [
              _c("div", { staticClass: "notification__left" }, [
                item.type == "reminder-create"
                  ? _c("img", {
                      staticClass: "notification__icon",
                      attrs: {
                        src: require("@icons/notifications/reminder-create.svg"),
                      },
                    })
                  : item.type == "reminder-delete"
                  ? _c("img", {
                      staticClass: "notification__icon",
                      attrs: {
                        src: require("@icons/notifications/reminder-delete.svg"),
                      },
                    })
                  : item.type == "error"
                  ? _c("img", {
                      staticClass: "notification__icon",
                      attrs: { src: require("@icons/notifications/error.svg") },
                    })
                  : _c("img", {
                      staticClass: "notification__icon",
                      attrs: {
                        src: require("@icons/notifications/success.svg"),
                      },
                    }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "notification__right" }, [
                _c("div", { staticClass: "notification__title" }, [
                  _vm._v("\n          " + _vm._s(item.title) + "\n        "),
                ]),
                _vm._v(" "),
                item.text
                  ? _c("div", {
                      staticClass: "notification__text",
                      domProps: { innerHTML: _vm._s(item.text) },
                    })
                  : _vm._e(),
              ]),
            ]),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }