<template>
  <div class="block">
    <div
      class="block__button button"
      :class="{ blocked: !ableToCreateNewResponsible }"
      @click="openModal"
    >
      <div class="button__text">Добавить представителя</div>

      <div class="button__icon">
        <svg
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 11 10"
          width="11"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M6 4.5h4.5v1H6V10H5V5.5H.5v-1H5V0h1v4.5Z"
            fill="#6B6B6B"
          />
        </svg>
      </div>
    </div>

    <FadeTransition>
      <AppModal v-if="isModalOpened" :max-width="516" @close="closeModal">
        <template #header>Добавление представителя</template>

        <template #body>
          <CabTextField
            v-model.trim="fullName"
            class="modal__input"
            label="ФИО*"
            name="add-new-agent-block-full-name"
            placeholder=" Введите полное имя"
            :error-message="fullNameErrorMessage"
            @input="fullNameErrorMessage = ''"
          />

          <CabTextField
            v-model.trim="position"
            class="modal__input"
            label="Должность*"
            name="add-new-agent-block-position"
            placeholder=" Введите должность"
            :error-message="positionErrorMessage"
            @input="positionErrorMessage = ''"
          />

          <CabTextField
            v-model.trim="phone"
            class="modal__input"
            label="Номер телефона*"
            name="add-new-agent-block-phone"
            placeholder=" Введите номер телефона"
            :error-message="phoneErrorMessage"
            @input="phoneErrorMessage = ''"
          />

          <CabTextField
            v-model.trim="email"
            class="modal__input"
            label="Адрес электронной почты*"
            name="add-new-agent-block-email"
            placeholder=" Введите адрес электронной почты"
            :error-message="emailErrorMessage"
            @input="emailErrorMessage = ''"
          />

          <CabTextarea
            v-model.trim="comment"
            name="add-new-agent-block-comment"
            label="Комментарий"
          />
        </template>

        <template #footer>
          <div class="modal__buttons">
            <!-- <div class="fill-test-data" @click="fill">test</div> -->

            <div class="filler"></div>

            <div class="modal__cancel" @click="closeModal">Отмена</div>

            <ButtonWithLoader
              :is-loading="agentIsLoading"
              class="modal__save"
              @click="addAgent"
            >
              Добавить представителя
            </ButtonWithLoader>
          </div>
        </template>
      </AppModal>
    </FadeTransition>
  </div>
</template>

<script>
import property from "lodash-es/property";
import zipObject from "lodash-es/zipObject";
import matchesProperty from "lodash-es/matchesProperty";

import { useMutation, useQueryClient } from "@tanstack/vue-query";

import { required, email, maxLength } from "vuelidate/dist/validators.min";

import { mapGetters } from "vuex";

import { InfoService } from "../../services";

import FadeTransition from "../transitions/Fade.vue";
import AppModal from "../common/Modal.vue";
import CabTextField from "../common/CabTextField.vue";
import CabTextarea from "../common/CabTextarea.vue";
import ButtonWithLoader from "../common/ButtonWithLoader.vue";

export default {
  name: "TariffAddNewAgent",

  components: {
    FadeTransition,
    AppModal,
    CabTextField,
    CabTextarea,
    ButtonWithLoader,
  },

  props: {
    agents: {
      type: Array,
      required: true,
    },

    rights: {
      type: Object,
      required: true,
    },
  },

  setup() {
    const queryClient = useQueryClient();

    const { mutate: addNewAgent, isLoading: agentIsLoading } = useMutation({
      mutationFn: ({ orgId, agent }) => InfoService.addAgent(orgId, agent),
    });

    return {
      queryClient,
      addNewAgent,
      agentIsLoading,
    };
  },

  data() {
    return {
      isModalOpened: false,

      fullName: "",
      position: "",
      phone: "",
      email: "",
      comment: "",

      fullNameErrorMessage: "",
      positionErrorMessage: "",
      phoneErrorMessage: "",
      emailErrorMessage: "",
      commentErrorMessage: "",
    };
  },

  validations: {
    fullName: {
      required,
      maxLength: maxLength(100),
    },

    position: {
      required,
      maxLength: maxLength(100),
    },

    phone: {
      required,
      maxLength: maxLength(40),
    },

    email: {
      required,
      email,
      maxLength: maxLength(40),
    },
  },

  computed: {
    ...mapGetters("User", {
      userId: "getUserId",
    }),

    ...mapGetters("Organizations/ActiveOne", { orgId: "getId" }),

    idToUserIdMap() {
      const ids = this.agents.map(property("id"));
      const userIds = this.agents.map(property("user_id"));

      return zipObject(ids, userIds);
    },

    ableToCreateNewResponsible() {
      // TODO: fix bug - always forbidden when current user outside of the table
      const ids =
        this.rights?.authority?.find(
          matchesProperty(
            "name",
            "Согласовывает изменения договора бухгалтерского обслуживания"
          )
        )?.list || [];

      const userIds = ids.map(byIdToUserIdMap.bind(this));

      return userIds.includes(this.userId);

      function byIdToUserIdMap(id) {
        return this.idToUserIdMap[id];
      }
    },
  },

  methods: {
    fill() {
      this.fullName = "Тест";
      this.position = "Тестовый";
      this.phone = "+999-999-99-99";
      this.email = "test@mail.com";
      this.comment = "Просто тест";
    },

    clearForm() {
      this.fullName = "";
      this.position = "";
      this.phone = "";
      this.email = "";
      this.comment = "";

      this.fullNameErrorMessage = "";
      this.positionErrorMessage = "";
      this.phoneErrorMessage = "";
      this.emailErrorMessage = "";
      this.commentErrorMessage = "";
    },

    openModal() {
      this.isModalOpened = true;
    },

    closeModal() {
      this.isModalOpened = false;
      this.clearForm();
    },

    async addAgent() {
      if (!this.$v.fullName.required) {
        this.fullNameErrorMessage = "Требуется заполнить ФИО";
      }
      if (!this.$v.fullName.maxLength) {
        this.fullNameErrorMessage =
          "Максимальная длина этого поля: 100 символов";
      }
      if (!this.$v.position.required) {
        this.positionErrorMessage = "Требуется заполнить должность";
      }
      if (!this.$v.position.maxLength) {
        this.positionErrorMessage =
          "Максимальная длина этого поля: 100 символов";
      }
      if (!this.$v.phone.required) {
        this.phoneErrorMessage = "Требуется заполнить номер телефона";
      }
      if (!this.$v.phone.maxLength) {
        this.phoneErrorMessage = "Максимальная длина этого поля: 40 символов";
      }
      if (!this.$v.email.required) {
        this.emailErrorMessage = "Требуется заполнить адрес электронной почты";
      }
      if (!this.$v.email.maxLength) {
        this.emailErrorMessage = "Максимальная длина этого поля: 40 символов";
      }
      if (!this.$v.email.email) {
        this.emailErrorMessage = "Неверный формат адреса электронной почты";
      }

      if (this.$v.$invalid) return;

      const agent = {
        name: this.fullName,
        position: this.position,
        phone: this.phone,
        email: this.email,
        comment: this.comment,
      };

      this.addNewAgent(
        { orgId: this.orgId, agent },
        {
          onError: () => {
            this.$notify({
              type: "error",
              text: "При добавлении представителя возникла ошибка",
            });
          },

          onSuccess: () => {
            this.$notify({
              text: "Представитель добавлен",
            });

            this.queryClient.invalidateQueries({ queryKey: ["agents"] });
          },

          onSettled: () => {
            this.closeModal();
            this.clearForm();
          },
        }
      );
    },
  },
};
</script>

<style scoped lang="scss">
.block {
  &__button {
    display: flex;
    width: 232px;
    align-items: center;
    justify-content: space-between;
    padding: 16px 20px;
    border: 1px solid $grey-200;
    border-radius: 6px;

    &.blocked {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  @media (max-width: 960px) {
    &__button {
      width: 300px;
    }
  }

  @media (max-width: 600px) {
    &__button {
      width: 340px;
      padding: 10px 20px;
    }
  }
}

.button {
  cursor: pointer;
  user-select: none;

  &__text {
    color: $grey-600;
    font-weight: 500;
    text-transform: uppercase;
  }

  &__icon {
    display: flex;
    width: 32px;
    min-width: 32px;
    height: 32px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid $grey-200;
    border-radius: 50%;
    background-color: white;
  }

  &:hover {
    background: linear-gradient(to right, #ffdb33, #ffba00);
  }

  &:hover &__text {
    color: $black;
  }
}

.modal {
  &__buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
    margin-right: 16px;
  }

  &__input {
    margin-top: 20px;
    margin-bottom: 35px;
  }

  &__cancel,
  &__save {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    text-transform: uppercase;
  }

  &__cancel {
    width: 130px;
    margin-right: 8px;
    color: $grey-700;
    cursor: pointer;
    font-weight: 500;

    &:hover {
      color: $grey-800;
    }
  }

  &__save {
    width: 270px;
    height: 40px;
  }

  @media (max-width: 450px) {
    &__buttons {
      flex-direction: column-reverse;
    }

    &__cancel,
    &__save {
      width: 100%;
    }

    &__save {
      margin-bottom: 20px;
    }
  }
}
</style>
