<script>
import Contract from "./Contract.vue";

export default {
  name: "ContractWrapper",

  render: function (h) {
    return h(Contract);
  },
};
</script>
