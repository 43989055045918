import { MONTH_NAMES_ACCUSATIVE } from "../../utils/constants";

export default function (value) {
  const date = new Date(value);

  if (isNaN(date)) return "NOT A DATE";

  return `От ${date.getDate()} ${MONTH_NAMES_ACCUSATIVE[
    date.getMonth()
  ].toLowerCase()} ${date.getFullYear()}`;
}
