var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "root" }, [
    _vm.oldAvatarURL
      ? _c("img", {
          staticClass: "root__image",
          class: {
            "root__image--loading": _vm.isLoading,
          },
          attrs: { src: _vm.oldAvatarURL },
        })
      : _c(
          "div",
          {
            staticClass: "root__initials",
            class: {
              "root__initials--loading": _vm.isLoading,
            },
          },
          [_vm._v("\n    " + _vm._s(_vm.initials) + "\n  ")]
        ),
    _vm._v(" "),
    _vm.isLoading
      ? _c("img", {
          staticClass: "root__loader",
          attrs: { src: require("@icons/bubble-loader.svg") },
        })
      : _vm._e(),
    _vm._v(" "),
    _c(
      "label",
      { staticClass: "root__input", attrs: { for: "avatar-select" } },
      [
        _c("img", { attrs: { src: require("@icons/avatar-select.svg") } }),
        _vm._v(" "),
        _c("input", {
          ref: "hidden-input",
          staticClass: "root__hidden-input",
          attrs: { id: "avatar-select", type: "file" },
          on: { change: _vm.handleInput },
        }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }