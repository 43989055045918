import { FeedService } from "@/services";

export function startsWith(first, second) {
  const loweredFirst = first.toLowerCase();
  const loweredSecond = second.toLowerCase();
  return loweredFirst.startsWith(loweredSecond);
}

import isWithinInterval from "date-fns/isWithinInterval";

const safeInterval = {
  start: new Date(2010, 0),
  end: new Date(2030, 0),
};

export function isWithinSafeInterval(timestamp) {
  return isWithinInterval(timestamp, safeInterval);
}

export function getInitials(name) {
  function notEmptyString(value) {
    return value !== "";
  }

  function getFirstLetter(string) {
    return string[0];
  }

  const nameParts = name.split(/\s/).filter(notEmptyString);

  const firstLetters = nameParts.map(getFirstLetter);

  return firstLetters.slice(0, 2).join("");
}

import matches from "lodash-es/matches";
import negate from "lodash-es/negate";

const emptyOperation = {
  client: {
    free: 0,
    tariff: 0,
    beyond: 0,
  },
  booker: {
    free: 0,
    tariff: 0,
    beyond: 0,
  },
};

export function convertTimeFromNow(nowTime, endTime) {
  let time = (nowTime - endTime) / 1000;

  if (time / 60 < 59) {
    return `${(time / 60) | 1} мин. назад`;
  } else if (time / 3600 < 24) {
    return `${(time / 3600) | 1} ч. назад`;
  } else {
    return `${(time / 86400) | 1} д. назад`;
  }
}

export async function loadTopic(org, orgId) {
  let topics = [];

  if (org?.show_topics) {
    topics = orgId ? await FeedService.fetchOrgTopics(orgId) : await FeedService.fetchTopicsList();

    if (!org.can_change_topic && org.only_default_topic) {
      topics = topics.filter((topic) => topic.id === org.default_topic.id);
    }
  } else {
    topics.push({
      id: "00000000-0000-0000-0000-000000000000",
      name: "Единая",
      link: "Единая",
      active: true,
      icon: 47,
      main: true,
      code: "Единая",
    });
  }

  return topics;
}

export function convertedText(words, counter) {
  const value = Math.abs(counter) % 100;
  const num = value % 10;
  if (value > 10 && value < 20) return words[2];
  if (num > 1 && num < 5) return words[1];
  if (num === 1) return words[0];
  return words[2];
}

export const isOperationEmpty = matches(emptyOperation);
export const isOperationNotEmpty = negate(matches(emptyOperation));
