<template>
  <input
    :id="name"
    ref="input"
    v-mask="mask"
    :value="value"
    :class="classesForInput"
    :type="type == 'date' ? 'text' : type"
    :name="name"
    :maxlength="maxlength"
    :placeholder="$attrs.placeholder"
    :autocomplete="$attrs.autocomplete"
    @focus="onFocus"
    @blur="onBlur"
    @input="onInput"
    @paste="onPaste"
  />
</template>

<script>
import { VueMaskDirective } from "v-mask";

import Cleave from "cleave.js";

export default {
  name: "CabInput",

  directives: {
    mask: VueMaskDirective,
  },

  inheritAttrs: false,

  props: {
    value: {
      type: String,
      default: "", // не делать required: true - чтобы если в модели придет null, то преобразовать его в ""
    },

    type: {
      type: String,
      default: "text",
      validator(value) {
        const types = ["text", "password", "date"];
        return types.includes(value);
      },
    },

    name: {
      type: String,
      required: true,
    },

    mask: {
      type: String,
      default: "",
    },

    maxlength: {
      type: [String, Number],
      default: "",
    },
  },

  data() {
    return {
      cleave: undefined,

      placeholderIsVisible: false,
    };
  },

  computed: {
    classesForInput() {
      return {
        "cab-input": true,
        "cab-input--visible-placeholder": this.placeholderIsVisible,
      };
    },
  },

  mounted() {
    if (this.type == "date") {
      this.cleave = new Cleave(this.$refs["input"], {
        date: true,
        delimiter: ".",
        datePattern: ["d", "m", "Y"],
      });
    }
  },

  destroyed() {
    this.cleave?.destroy();
  },

  methods: {
    onFocus() {
      this.placeholderIsVisible = true;
      this.$emit("focus");
    },

    onBlur() {
      this.placeholderIsVisible = false;
      this.$emit("blur");
    },

    onInput(event) {
      if (this.type == "date") {
        requestAnimationFrame(() => {
          this.$emit("input", this.cleave.properties.result);
        });
      } else {
        this.$emit("input", event.target.value);
      }
    },

    onPaste(event) {
      if (this.mask) event.preventDefault();
    },
  },
};
</script>

<style scoped lang="scss">
.cab-input {
  width: 100%;
  height: 100%;
  padding: 0 2px 4px;
  background: transparent;
  outline: none;

  &::placeholder {
    opacity: 0;
    transition: opacity $short;
  }

  &--visible-placeholder {
    &::placeholder {
      opacity: 1;
    }
  }
}
</style>
