var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    { staticClass: "login-form" },
    [
      _c("img", {
        staticClass: "login-form__logo",
        attrs: { src: require("@icons/logo.svg"), alt: "logo.svg" },
      }),
      _vm._v(" "),
      _c("CabTextField", {
        staticClass: "login-form__username-input",
        attrs: {
          label: "Логин",
          name: "login-form-username",
          placeholder: " Введите логин",
          "error-message": _vm.errorMessage,
        },
        on: {
          input: function ($event) {
            _vm.errorMessage = ""
          },
        },
        model: {
          value: _vm.$v.username.$model,
          callback: function ($$v) {
            _vm.$set(_vm.$v.username, "$model", $$v)
          },
          expression: "$v.username.$model",
        },
      }),
      _vm._v(" "),
      _c("CabTextField", {
        staticClass: "login-form__password-input",
        attrs: {
          label: "Пароль",
          name: "login-form-password",
          placeholder: " Введите пароль",
          type: _vm.passwordType,
          "error-message": _vm.errorMessage,
        },
        on: {
          input: function ($event) {
            _vm.errorMessage = ""
          },
        },
        scopedSlots: _vm._u([
          {
            key: "appendedIcon",
            fn: function () {
              return [
                _c("EyeIcon", {
                  staticClass: "login-form__password-icon",
                  attrs: { "is-striked": _vm.isPasswordVisible },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.onIconClick.apply(null, arguments)
                    },
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.password,
          callback: function ($$v) {
            _vm.password = $$v
          },
          expression: "password",
        },
      }),
      _vm._v(" "),
      _c(
        "RouterLink",
        {
          staticClass: "login-form__password-restore",
          attrs: { to: { name: "PasswordRestore" } },
        },
        [_vm._v("\n    Забыли пароль?\n  ")]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          class: _vm.classesForLoginButton,
          attrs: { tabindex: "0" },
          on: {
            click: function ($event) {
              return _vm.loginUser({
                username: _vm.username,
                password: _vm.password,
              })
            },
          },
        },
        [
          _vm._v("\n    Войти\n    "),
          _c("FadeTransition", [
            _vm.isInProcessOfLogin
              ? _c("div", { staticClass: "login-form__loader" }, [
                  _c("img", {
                    attrs: {
                      src: require("@icons/bubble-loader.svg"),
                      width: "24",
                    },
                  }),
                ])
              : _vm._e(),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }