var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "table" }, [
    _c(
      "div",
      {
        staticClass: "table__head",
        on: {
          click: function ($event) {
            _vm.opened = !_vm.opened
          },
        },
      },
      [
        _vm._v("\n    " + _vm._s(_vm.title) + "\n    "),
        _c("EyeIcon", { attrs: { "is-striked": !_vm.opened } }),
      ],
      1
    ),
    _vm._v(" "),
    _vm.opened
      ? _c(
          "div",
          { staticClass: "table__content" },
          _vm._l(_vm.rights, function (right, i) {
            return _c(
              "div",
              { key: i, staticClass: "table__row" },
              [
                _c("div", { staticClass: "table__row--key" }, [
                  _vm._v(_vm._s(right.name)),
                ]),
                _vm._v(" "),
                _vm._l(_vm.agents, function (agent) {
                  return _c(
                    "div",
                    { key: agent.id, staticClass: "table__row--value" },
                    [
                      right.list.includes(agent.id)
                        ? _c(
                            "svg",
                            {
                              staticClass: "table__icon",
                              attrs: {
                                fill: "none",
                                xmlns: "http://www.w3.org/2000/svg",
                                viewBox: "0 0 24 24",
                              },
                            },
                            [
                              _c("path", {
                                attrs: {
                                  "fill-rule": "evenodd",
                                  "clip-rule": "evenodd",
                                  d: "M12 23a11 11 0 1 1 0-22 11 11 0 0 1 0 22Zm0-2a9 9 0 1 0 0-18 9 9 0 0 0 0 18Zm3.3-12.7L10 13.58l-2.3-2.3-1.4 1.42 3.7 3.7 6.7-6.7-1.4-1.42Z",
                                  fill: "#04C200",
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                    ]
                  )
                }),
              ],
              2
            )
          }),
          0
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }