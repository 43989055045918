var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "app-screen-loader" }, [
      _c("img", {
        attrs: { src: require("@icons/bubble-loader.svg"), width: "64" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }