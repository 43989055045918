var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "label",
    {
      staticClass: "input-radio",
      class: { "input-radio--disabled": _vm.disabled },
    },
    [
      _c("input", {
        staticClass: "input-radio__input",
        attrs: {
          type: "radio",
          required: _vm.required,
          name: _vm.name,
          disabled: _vm.disabled,
        },
        domProps: { checked: _vm.isChecked, value: _vm.value },
        on: {
          change: function ($event) {
            return _vm.updateValue($event.target.checked)
          },
        },
      }),
      _vm._v(" "),
      _c("div", {
        staticClass: "input-radio__radio",
        class: { "input-radio__radio--checked": _vm.isChecked },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "input-radio__text" }, [_vm._t("default")], 2),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }