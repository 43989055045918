<template>
  <div class="tariff-contract">
    <div v-if="hasContract" class="tariff-contract-wrapper">
      <div class="tariff-contract-aside">
        <a :href="archiveDownloadLink" target="_blank" class="tariff-contract-documents-all" download>
          <img src="../../assets/icons/documents/download.svg" alt="download.svg" />
          <span>Скачать комплект документов</span>
        </a>
        <div
          class="tariff-contract-documents scroll scroll--rounded"
          :class="{ 'tariff-contract-documents--disabled': documentLoading }"
        >
          <div
            v-for="document in documents"
            :key="document.guid"
            class="tariff-contract-document"
            @click="showDocument(document.doc_id, document.form_id, document.contract_id, document.guid)"
          >
            <div
              class="tariff-contract-document__body"
              :class="{
                'tariff-contract-document--active': document.guid === activeDocumentId,
              }"
            >
              <div class="tariff-contract-document__body__title">
                {{ document?.name }}
                <span v-if="document?.subtitle" class="tariff-contract-document__body__subtitle">{{
                  document?.subtitle
                }}</span>
              </div>
              <FileDownloadIcon
                :contract-id="document.contract_id"
                :form-id="document.form_id"
                :doc-id="document.doc_id"
                class="tariff-contract-document-icon"
              />
            </div>

            <div
              v-for="item in document.next_doc"
              :key="item.guid"
              class="tariff-contract-document__details"
              :class="{ 'tariff-contract-document--active': item.guid === activeDocumentId }"
              @click.stop="showDocument(item.doc_id, item.form_id, item.contract_id, item.guid)"
            >
              <span class="tariff-contract-document__details__notify" :class="{ visible: !item.actualy }">НОВ</span>
              <span class="tariff-contract-document__details__title">
                Ред. № {{ item.version }}, вступ. в силу с {{ formatDate(item.date, "dd.MM.yy") }}
              </span>
              <FileDownloadIcon
                :contract-id="item.contract_id"
                :form-id="item.form_id"
                :doc-id="item.doc_id"
                class="tariff-contract-document-icon"
              />
            </div>
          </div>

          <a href="https://1cbo.ru/rates_and_services/offer2/" class="tariff-contract-document all" target="_blank">
            <span>Правила обслуживания</span>
            <img src="@icons/external-link.svg" alt="external-link.svg" />
          </a>

          <img
            v-if="isDocumentsLoading"
            class="contract-history__loader-icon"
            src="@icons/bubble-loader.svg"
            width="40"
          />
        </div>

        <MarginConfirmationBlock v-if="contractStatus === 'concluding'" class="tariff-contract-aside__confirmation" />
      </div>

      <div class="tariff-contract-main scroll">
        <div class="tariff-contract-info">
          <div class="tariff-contract-status">
            <AppIcon :src="statuses[contractStatus].icon" class="tariff-contract-status-icon" />

            <div class="tariff-contract-status-right">
              <div class="tariff-contract-status-right__column">
                <div
                  class="tariff-contract-status-text"
                  :style="{
                    color: statuses[contractStatus].color,
                    'background-color': statuses[contractStatus].background,
                  }"
                >
                  <span v-if="contractStatus === 'suspended' || contractStatus === 'terminated'">
                    {{ statuses[contractStatus].title }}
                    <span v-if="contractStatusDate"> с {{ contractStatusDate | localDate }} </span>
                  </span>
                  <span v-else>{{ statuses[contractStatus].title }}</span>
                </div>
                <div class="relative">
                  <div class="tariff-contract-history-link" @click="openContractHistory">История действий</div>

                  <FadeTransaction>
                    <div
                      v-if="Object.keys(isOpenContractHistory).length"
                      class="history-box"
                      :style="isOpenContractHistory"
                    >
                      <div class="flex justify-between">
                        <div class="history-title">История действий</div>
                        <AppIcon
                          src="raw-svg/cross.svg"
                          class="history-icon"
                          @click.native="isOpenContractHistory = {}"
                        />
                      </div>

                      <ContractHistory
                        class="history-list scroll"
                        :style="{ 'max-height': isOpenContractHistory.heightContent }"
                      />
                    </div>
                  </FadeTransaction>
                </div>
              </div>

              <div v-if="contractStatus !== 'concluding'" class="tariff-contract-status-dates">
                <div class="column-status-date">
                  <div class="column-status-date__title">Дата договора</div>
                  <div class="tariff-contract-status-date">
                    {{ contractStartDate | localDate }}
                  </div>
                </div>

                <div v-if="contractServiceDate" class="column-status-date">
                  <div class="column-status-date__title">Начало оказания услуг</div>
                  <div class="tariff-contract-status-date">
                    {{ contractServiceDate | localDate }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="tariff-contract-history-block">
            <div class="tariff-contract-history-block-col">
              <div class="">Тариф</div>
              <div class="tariff-contract-history-block-black">
                {{ contractTariffName }}
              </div>
            </div>

            <div class="tariff-contract-history-block-col">
              <div class="">СНО</div>
              <div class="tariff-contract-history-block-black">
                {{ taxation?.name }}
              </div>
            </div>

            <div class="tariff-contract-history-block-col">
              <div class="">Вид бизнеса</div>
              <div class="tariff-contract-history-block-black">
                {{ businessForm?.name }}
              </div>
            </div>
          </div>
        </div>

        <div class="tariff-contract-doc">
          <div ref="docText" class="tariff-contract-text"></div>
          <img v-if="firstPageLoading" src="@icons/bubble-loader.svg" width="40" />
        </div>
      </div>
    </div>

    <div v-if="!hasContract" class="tariff-contract-none">
      <div class="tariff-contract-none__icon-wr">
        <AppIcon name="file-clock" class="tariff-contract-none__icon" />
      </div>
      <div class="tariff-contract-none__title">Договор не заключен</div>
      <div class="tariff-contract-none__description">Для заключения договора обратитесь к аутсорсеру</div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { CONTRACT_STATUS_NAMES, API_URL, STATUSES_CONTRACT } from "../../utils/constants";
import * as pdfjsLib from "pdfjs-dist/webpack";
import debounce from "lodash-es/debounce";
import AppIcon from "@/components/common/Icon.vue";
import FileDownloadIcon from "../../components/common/FileDownloadIcon.vue";
import ContractHistory from "../../components/Tariff/ContractHistory.vue";
import FadeTransaction from "../../components/transitions/Fade.vue";
import formatDate from "@/lib/vue-filters/formatDate";
import MarginConfirmationBlock from "@/components/Tariff/MarginConfirmationBlock.vue";

export default {
  name: "TariffContract",

  components: {
    AppIcon,
    FileDownloadIcon,
    ContractHistory,
    FadeTransaction,
    MarginConfirmationBlock,
  },

  data() {
    return {
      isDocumentsLoading: true,
      documentLoading: false,
      firstPageLoading: false,
      activeDocumentId: null,
      isOpenContractHistory: {},
      pdfPages: [],
      statuses: STATUSES_CONTRACT,
      collectionDocuments: {},
      firstDocument: null,
    };
  },

  computed: {
    ...mapGetters("Organizations/ActiveOne", {
      contractTariffName: "contractTariffName",
      contractStatus: "contractStatus",
      contractStartDate: "contractStartDate",
      contractServiceDate: "contractServiceDate",
      contractStatusDate: "contractStatusDate",
      businessForm: "businessForm",
      taxation: "taxation",
      orgId: "getId",
    }),

    ...mapGetters({
      documents: "Documents/getDocumentsList",
    }),

    statusName() {
      return CONTRACT_STATUS_NAMES[this.contractStatus];
    },

    /**
     * Есть договор в статусе service(Активен)|suspended(Приостановлен)|terminated(Расторгнут)|concluding(Заключение договора)
     */
    hasContract() {
      return this.contractStatus && this.contractStatus !== "none";
    },

    archiveDownloadLink() {
      return `${API_URL}/organization/${this.orgId}/document/archive?contract_id=${this.firstDocument?.contract_id}&doc_id=${this.firstDocument?.doc_id}`;
    },
  },

  watch: {
    documents(val) {
      // при заходе на таб показываем первый документ
      this.firstDocument = val?.[0];
      this.showDocument(
        this.firstDocument?.doc_id,
        this.firstDocument?.form_id,
        this.firstDocument?.contract_id,
        this.firstDocument?.guid
      );
    },

    orgId() {
      this.isDocumentsLoading = true;
      this.fetchDocumentsList(this.orgId).finally((this.isDocumentsLoading = false));
    },
  },

  created() {
    this.isDocumentsLoading = true;
    this.fetchDocumentsList(this.orgId).finally((this.isDocumentsLoading = false));
  },

  methods: {
    formatDate,
    ...mapActions({
      fetchDocumentsList: "Documents/fetchDocumentsList",
    }),

    showDocument(doc_id, form_id, contract_id, guid) {
      if (!doc_id || !this.$refs.docText || this.documentLoading) {
        return;
      }

      this.pdfPages = [];
      window.removeEventListener("resize", this.rerenderPages);
      window.addEventListener("resize", this.rerenderPages);

      this.documentLoading = true;
      this.firstPageLoading = true;
      this.activeDocumentId = guid;

      const docUrl = `${API_URL}/organization/${this.orgId}/document/file?contract_id=${contract_id}&doc_id=${doc_id}&form_id=${form_id}`;
      let loadingTask = null;
      if (guid in this.collectionDocuments) {
        loadingTask = this.collectionDocuments[guid];
      } else {
        loadingTask = pdfjsLib.getDocument({
          url: docUrl,
          withCredentials: true,
        });
        this.collectionDocuments[guid] = loadingTask;
      }

      this.$refs.docText.innerHTML = "";

      loadingTask.promise
        .then((doc) => {
          const numPages = doc.numPages;

          let lastPromise = doc.getMetadata(); // return promise

          const loadPage = (pageNum) => {
            return doc
              .getPage(pageNum)
              .then((page) => {
                let renderTask = null;
                let initialViewport = page.getViewport({ scale: 1 });
                let initialWidth = initialViewport.width;
                const canvas = document.createElement("canvas");
                const context = canvas.getContext("2d");
                canvas.style.display = "block";

                if (pageNum === 1) {
                  this.firstPageLoading = false;
                }

                const pageObj = {
                  initialWidth: initialWidth,
                  page: page,
                  renderTask: renderTask,
                  canvas: canvas,
                  context: context,
                };

                this.pdfPages.push(pageObj);

                this.renderPage(pageObj);

                this.$refs.docText.appendChild(canvas);
              })
              .catch((error) => {
                console.log(error, "error getPage");
              });
          };

          for (let i = 1; i <= numPages; i++) {
            lastPromise = lastPromise.then(loadPage.bind(null, i));
          }

          return lastPromise;
        })
        .then(
          () => {
            this.documentLoading = false;
          },
          (err) => {
            this.documentLoading = false;
            console.error("Error: " + err);
          }
        )
        .catch((error) => {
          console.log(error, "error loadPage");
        });
    },

    renderPage(pageObj) {
      const scale = (this.$refs.docText.offsetWidth - 20) / pageObj.initialWidth;
      const viewport = pageObj.page.getViewport({ scale: scale });
      pageObj.canvas.height = viewport.height;
      pageObj.canvas.width = viewport.width;

      if (pageObj.renderTask) {
        pageObj.renderTask.cancel();
        pageObj.renderTask = null;
      }

      pageObj.renderTask = pageObj.page.render({
        canvasContext: pageObj.context,
        viewport: viewport,
      });

      pageObj.renderTask.promise
        .then(() => {
          pageObj.renderTask = null;
        })
        .catch((error) => {
          if (error.name === "RenderingCancelledException") {
            return;
          }
        });
    },

    openContractHistory(event) {
      let maxHeightBlock = this.$el.getBoundingClientRect().bottom - event.clientY - 50;
      let heightBlock = maxHeightBlock > 488 ? 488 : maxHeightBlock;

      this.isOpenContractHistory = {
        top: `${event.clientY + 30}px`,
        left: `${event.clientX - 220}px`,
        "max-height": (maxHeightBlock > 488 ? 488 : maxHeightBlock) + "px",
        heightContent: heightBlock - 88 + "px",
      };
    },

    rerenderPages: debounce(function () {
      if (!this.$refs.docText) {
        return;
      }

      for (const pageObj of this.pdfPages) {
        this.renderPage(pageObj);
      }
    }, 300),
  },
};
</script>

<style scoped lang="scss">
/* stylelint-disable selector-class-pattern */
.tariff-contract {
  display: flex;
  flex-wrap: wrap;
  padding: 12px 24px 24px;
}

.tariff-contract-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
}

.tariff-contract-aside {
  width: 285px;
  flex: none;
  margin-right: 24px;

  &__confirmation {
    margin-bottom: 24px;
  }
}

.tariff-contract-documents {
  overflow: auto;
  max-height: 470px;
  padding: 15px 10px;
  border-radius: 10px;
  margin-bottom: 15px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12);
}

.tariff-contract-documents--disabled {
  opacity: 0.7;
  pointer-events: none;
}

.tariff-contract-document {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #f0f0f0;
  color: #1c1c1c;
  cursor: pointer;
  font-size: 18px;
  line-height: 22px;

  &.all {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 12px 14px 12px 20px;
    border-radius: 10px;
    border-bottom: none;
    transition: 0.3s;

    &:hover {
      background-color: #f6f6f6;
    }
  }

  img {
    margin-left: 5px;
  }

  &__body {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 12px 14px 12px 20px;
    border-radius: 10px;
    transition: 0.3s;

    &:hover {
      background-color: #f6f6f6;
    }

    &__title {
      display: flex;
      flex-direction: column;
    }

    &__subtitle {
      color: #6b6b6b;
      font-size: 12px;
      font-weight: 400;
      line-height: 14.06px;
    }
  }

  &__details {
    display: flex;
    width: 100%;
    align-items: flex-start;
    justify-content: space-between;
    padding: 12px 14px 12px 20px;
    border-radius: 10px;
    transition: 0.3s;

    &__notify {
      padding: 2px;
      border-radius: 2px;
      margin-right: 8px;
      background: #04c200;
      color: #fff;
      font-size: 10px;
      font-weight: 400;
      line-height: 10px;
      visibility: hidden;

      &.visible {
        visibility: visible;
      }
    }

    &__title {
      color: #6b6b6b;
      font-size: 12px;
      font-weight: 400;
      line-height: 14.06px;
    }

    &:hover {
      background-color: #f6f6f6;
    }
  }
}

.tariff-contract-document--active {
  border-bottom: none;
  background-color: #f6f6f6;
  font-weight: 700;
}

.tariff-contract-document-icon {
  width: 16px;
  margin-left: 5px;
  color: #848484;

  &:hover {
    color: #6b6b6b;
  }
}

.tariff-contract-documents-all {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 10px;
  border: 1px solid #e9e9e9;
  border-radius: 10px;
  margin-bottom: 15px;
  color: #1c1c1c;
  font-size: 14px;
  transition: 0.3s;

  &:hover {
    background-color: #eee;
  }

  img {
    margin-right: 6px;
    filter: brightness(0.2);
  }
}

.tariff-contract-main {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: -8px;
}

.tariff-contract-info {
  display: flex;
  flex: none;
  flex-wrap: wrap;
  // margin-top: -16px;
  // margin-bottom: 20px;
  // margin-left: -16px;
  margin: -8px 8px 20px -8px;
}

.tariff-contract-status {
  display: flex;
  min-width: 450px;
  max-width: 740px;
  align-items: center;
}

.tariff-contract-status-icon {
  width: 64px;
  height: 64px;
  flex: none;
  margin-right: 40px;
}

.tariff-contract-status-right {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;

  @media (max-width: 1800px) {
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 10px;
  }
}

.tariff-contract-status-text {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 4px;
  margin-right: 15px;
  font-size: 14px;
  font-weight: 700;

  @media (max-width: 1085px) {
    & {
      margin-left: -8px;
    }
  }
}

.tariff-contract-status-dates {
  display: flex;
  justify-content: space-between;
  margin-right: 15px;
  color: #848484;
  font-size: 14px;
  line-height: 14px;

  & > div:nth-child(2) {
    margin-left: 40px;
  }
}

.tariff-contract-status-date {
  margin-top: 8px;
  color: #1c1c1c;
  font-weight: 700;
  line-height: 17px;
}

.tariff-contract-history-block {
  display: flex;
  min-width: 350px;
  max-width: 740px;
  align-items: center;
  color: #848484;
  font-size: 14px;
  line-height: 14px;
}

.tariff-contract-status,
.tariff-contract-history-block {
  flex: 1 1 0%;
  padding: 11px 24px;
  border-radius: 8px;
  margin-top: 16px;
  margin-left: 16px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12);
}

.tariff-contract-history-link {
  position: relative;
  color: #528fc9;
  cursor: pointer;
  font-size: 14px;
  line-height: 16px;
  text-decoration: underline;
  transition: 0.3s;

  &:hover {
    color: #044291;
    text-decoration: none;
  }
}

.history-box {
  position: fixed;
  z-index: 9;
  overflow: hidden;
  width: 400px;
  padding: 15px 5px 15px 24px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.12);
  color: #1c1c1c;
  cursor: default;
}

.history-title {
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
}

.history-icon {
  margin-right: 20px;
  cursor: pointer;

  ::v-deep svg path {
    transition: 0.3s;
  }

  ::v-deep &:hover svg path {
    fill: #ccc;
  }
}

.history-list {
  padding-right: 15px;
  margin-top: 30px;
}

.tariff-contract-history-block-top {
  display: flex;
  justify-content: space-between;
  margin-top: 11px;
  margin-bottom: 16px;
}

.tariff-contract-history-block-bottom {
  display: flex;
  margin-top: auto;
  margin-bottom: 11px;
}

.tariff-contract-history-block-col {
  flex: 1 1 0%;
  margin-right: 10px;
}

.tariff-contract-history-block-black {
  margin-top: 8px;
  color: #1c1c1c;
}

.tariff-contract-doc {
  position: relative;
  display: flex;
  flex: 1 1 0%;
  align-items: center;
  justify-content: center;

  & > img {
    position: absolute;
  }
}

.tariff-contract-text {
  width: 100%;

  ::v-deep canvas {
    margin: 6px auto;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.12);
  }
}

.tariff-contract-none {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__icon-wr {
    display: flex;
    width: 190px;
    height: 190px;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    margin-bottom: 30px;
    background-color: #ebebeb;
  }

  &__icon {
    color: #b7b7b7;
  }

  &__title {
    margin-bottom: 10px;
    color: #333;
    font-size: 26px;
    font-weight: 500;
    line-height: 34px;
    text-align: center;
  }

  &__description {
    max-width: 400px;
    color: #848484;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
  }
}

.scroll--rounded {
  &::-webkit-scrollbar-thumb {
    border-top: 10px solid #fff;
    border-bottom: 10px solid #fff;
  }
}

.relative {
  position: relative;
  margin-top: 8px;
  margin-left: 4px;

  @media (max-width: 1085px) {
    & {
      margin-left: 0;
    }
  }
}

.flex {
  display: flex;
}

.column-status-date {
  &__title {
    padding: 5px 0;
  }
}

.justify-between {
  justify-content: space-between;
}
</style>
