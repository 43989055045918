var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "block" },
    [
      _c("EventTypesSelection", { staticClass: "block__event-type-selection" }),
      _vm._v(" "),
      _c(
        "div",
        {
          ref: "timezoneSelect",
          staticClass: "block__timezone-select n-timezone-select",
        },
        [
          _c("span", { staticClass: "n-timezone-select__title" }, [
            _vm._v(" Часовой пояс "),
          ]),
          _vm._v(" "),
          !_vm.timezonesInfoIsLoading
            ? _c("TimezoneSelect", {
                staticClass: "n-timezone-select__select",
                attrs: { "timezone-city-id": _vm.timezoneCityId },
                on: { select: _vm.onTimezoneSelect },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "block__time-range time-range" }, [
        _c("div", { staticClass: "time-range__text" }, [_vm._v("Уведомлять")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "time-range__inputs" },
          [
            _c("span", [_vm._v("с")]),
            _vm._v(" "),
            _c("SelectTime", {
              staticClass: "time-range__select",
              model: {
                value: _vm.startDate,
                callback: function ($$v) {
                  _vm.startDate = $$v
                },
                expression: "startDate",
              },
            }),
            _vm._v(" "),
            _c("span", [_vm._v("по")]),
            _vm._v(" "),
            _c("SelectTime", {
              staticClass: "time-range__select",
              model: {
                value: _vm.endDate,
                callback: function ($$v) {
                  _vm.endDate = $$v
                },
                expression: "endDate",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "block__week-days week-days" }, [
        _c("div", { staticClass: "week-days__text" }, [_vm._v("Дни недели")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "week-days__days" },
          _vm._l(_vm.weekdays, function (weekday, key) {
            return _c(
              "MultipleCheckbox",
              {
                key: key,
                staticClass: "week-days__day",
                attrs: { value: key },
                model: {
                  value: _vm.selectedWeekdays,
                  callback: function ($$v) {
                    _vm.selectedWeekdays = $$v
                  },
                  expression: "selectedWeekdays",
                },
              },
              [_vm._v("\n        " + _vm._s(weekday) + "\n      ")]
            )
          }),
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { class: _vm.classesForButton, on: { click: _vm.update } },
        [
          _vm._v("\n    Сохранить\n    "),
          _c("FadeTransition", [
            _vm.formIsLoading
              ? _c("div", { staticClass: "block__loader" }, [
                  _c("img", {
                    attrs: {
                      src: require("@icons/bubble-loader.svg"),
                      width: "24",
                    },
                  }),
                ])
              : _vm._e(),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }