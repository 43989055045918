var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Tab",
    {
      class: _vm.classesForTab,
      attrs: { tab: _vm.tab },
      on: { select: _vm.onSelect },
    },
    [
      _vm.tab.icon
        ? _c("div", {
            staticClass: "app-tab__icon",
            domProps: { innerHTML: _vm._s(_vm.tab.icon) },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "app-tab__text" }, [
        _vm._v("\n    " + _vm._s(_vm.tab.text) + "\n  "),
      ]),
      _vm._v(" "),
      _vm.info
        ? _c("div", { staticClass: "app-tab__value" }, [
            _vm._v(" (" + _vm._s(_vm.info) + ")"),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }