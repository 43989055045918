var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", {
    staticClass: "switch",
    class: { switch__checked: _vm.checked },
    on: {
      click: function ($event) {
        return _vm.$emit("change", !_vm.checked)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }