<template>
  <div class="reference-view">
    <Portal to="header-reference-tabs" slim :disabled="disabled">
      <ViewTabs v-model="activeTab">
        <AppTab
          v-for="tab in referenceTabs"
          :key="tab.key"
          :tab="tab"
          :is-active="isActive(tab)"
          @click.native="navigate"
        />
      </ViewTabs>
    </Portal>

    <div class="reference-view__main">
      <div
        class="reference-view__content-overflow-top-border"
        :class="{ visible: !containerScrollIsInTopPosition }"
      ></div>

      <FadeTransition mode="out-in">
        <router-view class="reference-view__content" @scroll.native="onScroll">
        </router-view>
      </FadeTransition>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import { referenceTabs } from "../utils/layout";

import ViewTabs from "../components/common/ViewTabs.vue";
import AppTab from "../components/AppLayout/AppTab.vue";
import FadeTransition from "../components/transitions/Fade.vue";

export default {
  name: "ReferenceView",

  components: {
    ViewTabs,
    AppTab,
    FadeTransition,
  },

  inject: ["screenSize"],

  data() {
    return {
      containerScrollIsInTopPosition: true,
    };
  },

  computed: {
    ...mapGetters("Layout", {
      activeReferenceTab: "getActiveReferenceTab",
    }),

    activeTab: {
      get() {
        return this.activeReferenceTab;
      },

      set(value) {
        this.$store.commit("Layout/SET_ACTIVE_REFERENCE_TAB", value);
      },
    },

    disabled() {
      return this.screenSize.greater("xs").value;
    },

    referenceTabs() {
      return referenceTabs;
    },
  },

  watch: {
    $route() {
      this.containerScrollIsInTopPosition = true;
    },
  },

  created() {
    this.loadDownloadsList();
  },

  methods: {
    ...mapActions({
      loadDownloadsList: "Info/loadDownloadsList",
    }),

    onScroll(event) {
      if (this.containerScrollIsInTopPosition) {
        this.containerScrollIsInTopPosition = false;
      } else if (event.target.scrollTop == 0) {
        this.containerScrollIsInTopPosition = true;
      }
    },

    isActive(tab) {
      return tab.key == this.activeTab.key;
    },

    navigate() {
      this.$router.push({ name: this.activeTab.name });
    },
  },
};
</script>

<style scoped lang="scss">
.reference-view {
  --margin: 24px;
  --padding: 40px;

  position: relative;
  height: calc(100% - 2 * var(--margin));
  margin: var(--margin);

  &__main {
    position: relative;
    height: calc(100% - 56px);
    padding-top: 12px;
    border-radius: 0 10px 10px;
    background: white;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
  }

  &__content-overflow-top-border {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 1px;
    transition: background-color $shortest;

    &.visible {
      background-color: $grey-200;
    }
  }

  &__content {
    position: relative;
    overflow: auto;
    width: 100%;
    height: 100%;
    border-radius: 0 10px 10px;
    background: white;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background: $grey-300;
    }
  }

  @media (max-width: 1264px) {
    --margin: 20px;
    --padding: 36px;
  }

  @media (max-width: 960px) {
    --margin: 16px;
    --padding: 32px;
  }

  @media (max-width: 600px) {
    /* stylelint-disable-next-line length-zero-no-unit */
    --margin: 0px;
    --padding: 24px;

    &__main {
      height: 100%;
      padding-top: 0;
    }

    &__content {
      padding: 12px;
      box-shadow: none;
    }
  }
}

@media (max-width: 600px) {
  ::v-deep .tabs__overflow-container {
    box-shadow: none !important;
  }
}
</style>
