<template>
  <div class="not-found">404</div>
</template>

<script>
export default {
  name: "NotFound",
};
</script>

<style scoped lang="scss">
.not-found {
  display: flex;
  justify-content: center;
  font-size: 36px;
}
</style>
