<template>
  <div :class="{ 'checkbox--disabled': disabled }" class="checkbox">
    <input
      :id="id"
      v-model="selected"
      :value="value"
      type="checkbox"
      :name="id"
      :disabled="disabled"
    />

    <label :for="id">
      <slot></slot>
    </label>
  </div>
</template>

<script>
import { nanoid } from "nanoid";

export default {
  name: "MultipleCheckbox",

  model: {
    prop: "options",
  },

  props: {
    value: {
      type: String,
      required: true,
    },

    options: {
      type: Array,
      default: () => [], // не делать required: true - чтобы если в модели придет null, то преобразовать его в []
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      id: nanoid(),
    };
  },

  computed: {
    selected: {
      get() {
        return this.options || [];
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>

<style scoped lang="scss">
.checkbox {
  display: flex;
  user-select: none;

  &#{&}--disabled {
    & * {
      cursor: default;
      filter: grayscale(1);
      opacity: 0.8;
    }
  }

  input {
    position: relative;
    width: 16px;
    min-width: 16px;
    height: 16px;
    border: 1px solid $grey-300;
    border-radius: 4px;
    margin-top: 2px;
    margin-right: 10px;
    appearance: none;
    cursor: pointer;
    outline: none;

    &:checked {
      border: none;
    }

    &:checked::after {
      position: absolute;
      width: 100%;
      height: 100%;
      content: url("../../assets/icons/checkbox.svg");
    }
  }

  label {
    cursor: pointer;
  }
}
</style>
