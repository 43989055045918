var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "select" }, [
    _c(
      "div",
      {
        staticClass: "select__selected-option",
        class: _vm.classesForSelectedOption,
        on: { click: _vm.showOptions },
      },
      [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.hours,
              expression: "hours",
            },
            {
              name: "mask",
              rawName: "v-mask",
              value: _vm.hoursMask,
              expression: "hoursMask",
            },
          ],
          staticClass: "select__input",
          attrs: { type: "text" },
          domProps: { value: _vm.hours },
          on: {
            change: _vm.onChange,
            input: function ($event) {
              if ($event.target.composing) return
              _vm.hours = $event.target.value
            },
          },
        }),
        _vm._v("\n    :\n    "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.minutes,
              expression: "minutes",
            },
            {
              name: "mask",
              rawName: "v-mask",
              value: _vm.minutesMask,
              expression: "minutesMask",
            },
          ],
          staticClass: "select__input",
          attrs: { type: "text" },
          domProps: { value: _vm.minutes },
          on: {
            change: _vm.onChange,
            input: function ($event) {
              if ($event.target.composing) return
              _vm.minutes = $event.target.value
            },
          },
        }),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        ref: "target",
        staticClass: "select__options",
        class: { "select__options--opened": _vm.isOpened },
      },
      _vm._l(_vm.optionKeys, function (key) {
        return _c(
          "div",
          {
            key: key,
            staticClass: "select__option",
            class: _vm.classesForOption(key),
            on: {
              click: function ($event) {
                return _vm.selectOption(+key)
              },
            },
          },
          [_vm._v("\n      " + _vm._s(_vm.options[key]) + "\n    ")]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }