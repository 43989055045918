<template>
  <div class="login-layout">
    <div class="login-layout__poster">
      <img
        v-if="timeOfDay == 'night'"
        src="../assets/images/login/night.svg"
        alt="night.svg"
      />
      <img
        v-else-if="timeOfDay == 'morning'"
        src="../assets/images/login/morning.svg"
        alt="morning.svg"
      />
      <img
        v-else-if="timeOfDay == 'afternoon'"
        src="../assets/images/login/afternoon.svg"
        alt="afternoon.svg"
      />
      <img v-else src="../assets/images/login/evening.svg" alt="evening.svg" />
    </div>

    <div class="login-layout__subviews">
      <FadeTransition mode="out-in">
        <keep-alive :exclude="['ChangePassword', 'NewPassword']">
          <router-view />
        </keep-alive>
      </FadeTransition>
    </div>

    <AppNotifications />
  </div>
</template>

<script>
import AppNotifications from "../components/AppLayout/Notifications.vue";
import FadeTransition from "../components/transitions/Fade.vue";

export default {
  name: "LoginLayout",

  components: {
    AppNotifications,
    FadeTransition,
  },

  data() {
    return {
      timeOfDay: undefined,
    };
  },

  mounted() {
    const now = new Date();
    const hour = now.getHours();

    if (hour < 5) {
      this.timeOfDay = "night";
    } else if (hour < 11) {
      this.timeOfDay = "morning";
    } else if (hour < 17) {
      this.timeOfDay = "afternoon";
    } else {
      this.timeOfDay = "evening";
    }
  },
};
</script>

<style scoped lang="scss">
.login-layout {
  display: flex;
  width: 100vw;
  height: 100vh;
  background-color: white;

  &__poster {
    width: 50vw;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: right;
    }
  }

  &__subviews {
    display: flex;
    overflow: auto;
    width: 50vw;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & > * {
      max-height: 100%;
    }
  }

  @media (max-width: 960px) {
    &__poster {
      display: none;
    }

    &__subviews {
      width: 100vw;
    }
  }
}
</style>
