import Account from "@/views/Account.vue";
import UserSettings from "@/views/Account/UserSettings.vue";
import TechAudit from "@/views/Account/TechAudit.vue";

export default {
  path: "account",
  name: "Account",
  component: Account,
  // redirect: {
  //   name: "UserSettings",
  // },

  meta: {
    withTabs: true,
  },

  children: [
    {
      path: "user-settings",
      name: "UserSettings",
      component: UserSettings,

      meta: {
        withTabs: true,
      },
    },
    {
      path: "tech-audit",
      name: "TechAudit",
      component: TechAudit,

      meta: {
        withTabs: true,
      },
    },
  ],
};
