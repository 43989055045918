import zipObject from "lodash-es/zipObject";
import property from "lodash-es/property";

import { nanoid } from "nanoid";

import { InfoService } from "../../services";

import nameInitials from "../../lib/vue-filters/nameInitials";

import robotImage from "../../assets/icons/chat/robot.svg";

let users = JSON.parse(localStorage.getItem("cab-users-list")) || [];
// import users from "../../dev-utils/data/users.json";

function resetState() {
  return {
    _update: undefined,
  };
}

export default {
  namespaced: true,

  state: resetState(),

  getters: {
    getUsersAvatars(state) {
      state._update;

      return Object.assign(
        {},
        ...users.map((user) => {
          if (user.avatar && user.avatar.thumb) {
            return { [user.user_id]: user.avatar.thumb.url };
          }

          if (user.service) {
            return { [user.user_id]: robotImage };
          }

          return { [user.user_id]: generateDataURLForName(user.name) };
        })
      );

      function generateDataURLForName(name) {
        let canvas = document.createElement("canvas");
        canvas.width = canvas.height = 40;

        let ctx = canvas.getContext("2d");

        let background = ctx.createLinearGradient(0, 0, 40, 40);

        background.addColorStop(0, "#FEE25F");
        background.addColorStop(1, "#FFC528");

        ctx.beginPath();
        ctx.rect(0, 0, 40, 40);
        ctx.fillStyle = background;
        ctx.fill();

        ctx.font = "bold 14px Arial";
        ctx.fillStyle = "Black";
        ctx.textAlign = "center";
        ctx.fillText(nameInitials(name), 20, 25);

        return canvas.toDataURL();
      }
    },

    getUsersNames(state) {
      state._update;

      const ids = users.map(property("user_id"));
      const names = users.map(property("name"));

      const map = zipObject(ids, names);
      map.here = "Участники";
      map.everyone = "Все";

      return map;
    },
  },

  mutations: {
    UPDATE(state) {
      state._update = nanoid();
    },

    RESET_STATE(state) {
      localStorage.removeItem("cab-users-list");

      Object.assign(state, resetState());
    },
  },

  actions: {
    async loadAllUsers({ commit }) {
      users = await InfoService.fetchAllUsersInDomain();

      localStorage.setItem("cab-users-list", JSON.stringify(users));

      commit("UPDATE");
    },

    resetState({ commit }) {
      commit("RESET_STATE");
    },
  },
};
