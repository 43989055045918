var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "login-layout" },
    [
      _c("div", { staticClass: "login-layout__poster" }, [
        _vm.timeOfDay == "night"
          ? _c("img", {
              attrs: {
                src: require("../assets/images/login/night.svg"),
                alt: "night.svg",
              },
            })
          : _vm.timeOfDay == "morning"
          ? _c("img", {
              attrs: {
                src: require("../assets/images/login/morning.svg"),
                alt: "morning.svg",
              },
            })
          : _vm.timeOfDay == "afternoon"
          ? _c("img", {
              attrs: {
                src: require("../assets/images/login/afternoon.svg"),
                alt: "afternoon.svg",
              },
            })
          : _c("img", {
              attrs: {
                src: require("../assets/images/login/evening.svg"),
                alt: "evening.svg",
              },
            }),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "login-layout__subviews" },
        [
          _c(
            "FadeTransition",
            { attrs: { mode: "out-in" } },
            [
              _c(
                "keep-alive",
                { attrs: { exclude: ["ChangePassword", "NewPassword"] } },
                [_c("router-view")],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("AppNotifications"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }