import Vue from "vue";

if (process.env.NODE_ENV == "development")
  import(/* webpackMode: "lazy" */ "./dev-utils/dev-server");

// import { inspect } from "@xstate/inspect";

// if (process.env.NODE_ENV == "development") {
//   inspect({
//     iframe: false,
//   });
// }

import App from "./App.vue";

import "./assets/scss/index.scss";

import store from "./store";
import router from "./router";

import * as Sentry from "@sentry/vue";

import { API_URL } from "./utils/constants";

Sentry.init({
  Vue,
  dsn: "https://3c92138d111d466bb4a2ec4f40a67b51@o4504922837745664.ingest.sentry.io/4504922870513664",

  // eslint-disable-next-line no-undef
  // release: __COMMIT_HASH__,

  tunnel: `${API_URL}/sentry-tunnel`,
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,

  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracePropagationTargets: ["localhost", "my-site-url.com", /^\//],
    }),

    // TODO: постоянно делает запросы на backend - от чего там возникает ошибка Client error: `POST https://o4504922837745664.ingest.sentry.io/api/4504922870513664/envelope/` resulted in a `429 Too Many Requests`
    // TODO: пока отключаем
    // new Sentry.Replay({
    //   // Additional SDK configuration goes in here, for example:
    //   maskAllText: false,
    //   blockAllMedia: false,
    // }),
  ],

  // eslint-disable-next-line no-undef
  environment: VUE_APP_ENVIRONMENT,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

// throw "krk";

import Vuelidate from "vuelidate";
Vue.use(Vuelidate);

import Notifications from "vue-notification";
Vue.use(Notifications);

import "./lib/vue-filters";

import "./lib/linkify/topics-plugin";
import "./lib/linkify/mentions-plugin";

import hint from "./lib/vue-directives/hint";
Vue.directive("hint", hint);

import PortalVue from "portal-vue";
Vue.use(PortalVue);

import { VueQueryPlugin } from "@tanstack/vue-query";
Vue.use(VueQueryPlugin);

Vue.config.productionTip = false;

Vue.config.errorHandler = function (error, vm, info) {
  Sentry.captureException(error);

  console.error(
    `%c[Vue.config.errorHandler]\n %c<${vm.$options.name}> %c(${info})\n`,
    "",
    "font-weight: 700",
    "",
    error
  );

  Vue.notify({
    type: "error",
    text: error.message,
    duration: -1,
  });
};

window.addEventListener("unhandledrejection", function (event) {
  Sentry.captureException(event.reason);

  const error = event.reason;
  console.error("unhandledrejection (global):", error);

  Vue.notify({
    type: "error",
    text: error.message,
    duration: -1,
  });
});

if (process.env.NODE_ENV == "development") {
  // miragejs needs a time to start intercepting requests
  // TODO: maybe we can determine exactly the moment when the mirage
  // will be able to intercept requests and generate some kind of event?
  setTimeout(() => {
    new Vue({
      store,
      router,
      render: (h) => h(App),
    }).$mount("#app");
  }, 2000);
} else {
  new Vue({
    store,
    router,
    render: (h) => h(App),
  }).$mount("#app");
}
