<template>
  <div class="account-view">
    <Portal to="header-account-tabs" slim :disabled="disabled">
      <ViewTabs v-model="activeTab">
        <AppTab
          v-for="tab in accountTabs"
          :key="tab.key"
          :tab="tab"
          :info="tabInfo(tab)"
          :is-active="isActive(tab)"
          @click.native="navigate"
        />
      </ViewTabs>
    </Portal>

    <div class="account-view__main">
      <div
        class="account-view__content-overflow-top-border"
        :class="{ visible: !containerScrollIsInTopPosition }"
      ></div>

      <FadeTransition mode="out-in">
        <router-view class="account-view__content" @scroll.native="onScroll">
        </router-view>
      </FadeTransition>
    </div>

    <ForceAppInstallationStub
      class="account-view__force-app-installation-stub"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import { accountTabs } from "@/utils/layout";

import ViewTabs from "@/components/common/ViewTabs.vue";
import AppTab from "@/components/AppLayout/AppTab.vue";
import FadeTransition from "@/components/transitions/Fade.vue";
import ForceAppInstallationStub from "@/components/common/ForceAppInstallationStub.vue";

export default {
  name: "AccountsView",

  components: {
    ViewTabs,
    AppTab,
    FadeTransition,
    ForceAppInstallationStub,
  },

  data() {
    return {
      containerScrollIsInTopPosition: true,
    };
  },

  computed: {
    ...mapGetters({
      activeAccountTab: "Layout/getActiveAccountTab",
    }),

    activeTab: {
      get() {
        return this.activeAccountTab;
      },

      set(value) {
        this.setActiveTabForSection({ section: "account", tab: value });
      },
    },

    disabled() {
      if (this.screenSizeCode == "XS") {
        return false;
      }
      return true;
    },

    accountTabs() {
      return accountTabs;
    },
  },

  watch: {
    $route: {
      handler(to) {
        this.containerScrollIsInTopPosition = true;

        this.setActiveTab(to.path);
      },
      immediate: true,
    },
  },

  methods: {
    ...mapActions({
      setActiveTabForSection: "Layout/setActiveTabForSection",
    }),

    onScroll(event) {
      if (this.containerScrollIsInTopPosition) {
        this.containerScrollIsInTopPosition = false;
      } else if (event.target.scrollTop == 0) {
        this.containerScrollIsInTopPosition = true;
      }
    },

    tabInfo() {
      return "";
    },

    setActiveTab(path) {
      const activeTab = this.accountTabs.find((tab) => {
        return path.endsWith(tab.key);
      });

      if (activeTab) {
        this.activeTab = activeTab;
      }
    },

    isActive(tab) {
      return tab.key == this.activeTab.key;
    },

    navigate() {
      this.$router.push(`/account/${this.activeTab.key}`).catch(() => {});
    },
  },
};
</script>

<style scoped lang="scss">
.account-view {
  --margin: 24px;
  --padding: 40px;

  position: relative;
  height: calc(100% - 2 * var(--margin));
  margin: var(--margin);

  &__main {
    position: relative;
    height: calc(100% - 56px);
    padding-top: 12px;
    border-radius: 0 10px 10px;
    background: white;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
  }

  &__content-overflow-top-border {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 1px;
    transition: background-color $shortest;

    &.visible {
      background-color: $grey-200;
    }
  }

  &__content {
    position: relative;
    overflow: auto;
    width: 100%;
    height: 100%;
    border-radius: 0 10px 10px;
    background: white;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background: $grey-300;
    }
  }

  &__force-app-installation-stub {
    display: none;
  }

  @media (max-width: 1264px) {
    --margin: 20px;
    --padding: 36px;
  }

  @media (max-width: 960px) {
    --margin: 16px;
    --padding: 32px;
  }

  @media (max-width: 600px) {
    /* stylelint-disable-next-line length-zero-no-unit */
    --margin: 0px;
    --padding: 24px;

    position: relative;

    &__main {
      display: none;
    }

    &__force-app-installation-stub {
      display: flex;
    }
  }
}
</style>
