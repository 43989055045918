import { API_URL } from "../utils/constants";

import axiosClient from "./axiosClient.js";

const AuthService = {
  parser: new DOMParser(),

  async fetchCSRF() {
    const response = await axiosClient.get("/csrf-token");

    return response.data.csrf_token;
  },

  async login({ username, password, nonce }) {
    let response;

    const body = { username, password, nonce };
    const csrf = await this.fetchCSRF();

    response = await axiosClient.post("/login", body, {
      headers: {
        "x-csrf-token": csrf,
      },
      params: {
        clients_only: true,
      },
    });

    // redirection logic for different kinds of users:
    if (!response.data.redirect) return;
    location.href = response.data.redirect;
    // have to throw an Error to avoid setting User.isAuthorized to true;
    throw new Error("redirect");
  },

  async logout() {
    const response = await this.oid_logout();

    if (!response.ok) {
      throw Error("OpenID logout error.");
    }

    localStorage.removeItem("isUserAuthorized");

    // after a localStorage.isUserAuthorized reset
    // we don't care about any results of next requests:
    try {
      const csrf = await this.fetchCSRF();
      await axiosClient.post("/logout", undefined, {
        headers: {
          "x-csrf-token": csrf,
        },
      });
    } catch (error) {
      console.error("LOGOUT ERROR:", error);
    }
  },

  async restorePassword(email) {
    const body = { email };
    const csrf = await this.fetchCSRF();

    await axiosClient.post("/restore-password", body, {
      headers: {
        "x-csrf-token": csrf,
      },
    });
  },

  async changePassword({ restoreCode, newPassword }) {
    const body = { restoreCode, newPassword };
    const csrf = await this.fetchCSRF();

    await axiosClient.put("/change-password-with-code", body, {
      headers: {
        "x-csrf-token": csrf,
      },
    });
  },

  async getOpenIDRelyingPartyURL() {
    const url = `${API_URL}/openid-init`;

    const csrf = await this.fetchCSRF();

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "x-csrf-token": csrf,
      },
      credentials: "include",
    });

    if (!response.ok) throw new Error("getOpenIDRelyingPartyURL");

    const json = await response.json();

    return json.initUrl;
  },

  async oid_init(relyingPartyURL) {
    if (!relyingPartyURL) throw new Error("oid_init null relyingPartyURL");

    const response = await fetch(`${relyingPartyURL}?cmd=init`, {
      credentials: "include",
    });

    const dom = this.parser.parseFromString(await response.text(), "text/html");

    const form = dom.forms[0];

    localStorage.setItem("OpenIDProviderURL", form.action);

    return form;
  },

  async oid_check_immediate(redirectForm) {
    const response = await fetch(redirectForm.getAttribute("action"), {
      method: "POST",
      body: new URLSearchParams(new FormData(redirectForm)),
      credentials: "include",
    });

    const dom = this.parser.parseFromString(await response.text(), "text/html");

    return dom.forms[0];
  },

  async oid_setup_needed(redirectForm) {
    const response = await fetch(redirectForm.getAttribute("action"), {
      method: "POST",
      body: new URLSearchParams(new FormData(redirectForm)),
      credentials: "include",
    });

    const dom = this.parser.parseFromString(await response.text(), "text/html");

    return dom.forms[0];
  },

  async oid_checkid_setup(redirectForm) {
    const response = await fetch(redirectForm.getAttribute("action"), {
      method: "POST",
      body: new URLSearchParams(new FormData(redirectForm)),
      credentials: "include",
    });

    const dom = this.parser.parseFromString(await response.text(), "text/html");

    return dom.forms[0];
  },

  async oid_authenticate(authFrom) {
    const response = await fetch(authFrom.getAttribute("action"), {
      method: "POST",
      body: new URLSearchParams(new FormData(authFrom)),
      credentials: "include",
    });

    const dom = this.parser.parseFromString(await response.text(), "text/html");

    const form = dom.forms[0];

    const successfulAuthentication =
      form.elements["openid.mode"].value == "id_res";

    if (!successfulAuthentication) throw new Error();

    return form;
  },

  async oid_id_res(redirectForm) {
    const response = await fetch(redirectForm.getAttribute("action"), {
      method: "POST",
      body: new URLSearchParams(new FormData(redirectForm)),
      credentials: "include",
    });

    return response.json();
  },

  async oid_logout() {
    const OpenIDProviderURL = localStorage.getItem("OpenIDProviderURL");

    if (!OpenIDProviderURL) return;

    return await fetch(`${OpenIDProviderURL}?cmd=logout`, {
      credentials: "include",
    });
  },

  async getLaravelSessionUsingOID() {
    let redirectForm;

    const relyingPartyURL = await this.getOpenIDRelyingPartyURL();

    redirectForm = await this.oid_init(relyingPartyURL);

    redirectForm = await this.oid_check_immediate(redirectForm);

    let mode = redirectForm.elements["openid.mode"].value;

    const isAuthenticatedInOID = mode == "id_res";

    if (!isAuthenticatedInOID) throw new Error();

    const result = await this.oid_id_res(redirectForm);

    await this.login({ nonce: result.value });
  },

  async authenticateInOID({ username, password }) {
    let redirectForm;

    const relyingPartyURL = await this.getOpenIDRelyingPartyURL();

    redirectForm = await this.oid_init(relyingPartyURL);

    redirectForm = await this.oid_check_immediate(redirectForm);

    let mode = redirectForm.elements["openid.mode"].value;

    const isSetupNeeded = mode == "setup_needed";

    if (!isSetupNeeded) throw new Error();

    redirectForm = await this.oid_setup_needed(redirectForm);

    const authForm = await this.oid_checkid_setup(redirectForm);

    authForm.elements["openid.auth.user"].value = username;
    authForm.elements["openid.auth.pwd"].value = password;

    redirectForm = await this.oid_authenticate(authForm);

    const result = await this.oid_id_res(redirectForm);

    if (result.key != "nonce" && !result.value) throw new Error();

    const nonce = result.value;

    return nonce;
  },
};

export { AuthService };
