export default function (value, ignoreTimezone) {
  const date = new Date(value);

  if (ignoreTimezone) date.setSeconds(60 * date.getTimezoneOffset());

  if (isNaN(date)) return "NOT A DATE";

  return (
    `${date.toLocaleDateString("ru-RU")}` +
    " " +
    `${date.getHours()}`.padStart(2, "0") +
    ":" +
    `${date.getMinutes()}`.padStart(2, "0")
  );
}
