import { AuthService } from "./auth.service";

import axiosClient from "./axiosClient.js";

const TechAuditService = {
  async fetchQuestionnaire(orgId) {
    const url = `/organization/${orgId}/questionnaire`;

    const response = await axiosClient.get(url);

    return response.data;
  },

  async sendQuestionnaire({ orgId, answers }) {
    const url = `/organization/${orgId}/questionnaire`;

    const csrf = await AuthService.fetchCSRF();

    const response = await axiosClient.post(url, answers, {
      headers: {
        "x-csrf-token": csrf,
      },
    });

    return response.data;
  },
};

export { TechAuditService };
