var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "reference-instructions" },
    [
      _c(
        "router-link",
        {
          staticClass: "reference-instructions__back",
          attrs: { to: { name: "ReferenceHelp" } },
        },
        [_vm._v("\n    вернуться назад\n  ")]
      ),
      _vm._v(" "),
      _c("FadeGroupTransition", [
        _vm.$route.params.id == 1
          ? _c("div", { key: "1" }, [
              _c("h1", [_vm._v("Ручная настройка списка информационных баз")]),
              _vm._v(" "),
              _c("ul", [
                _c("li", [
                  _vm._v(
                    "\n          В список информационных баз ТК могут быть добавлены как приложения\n          сервиса 1С:БухОбслуживание, так и другие информационные базы,\n          доступные пользователю. По вопросам подключения информационных баз,\n          расположенных вне сервиса 1С:БухОбслуживание, следует обращаться к\n          администраторам этих информационных баз.\n        "
                  ),
                ]),
                _vm._v(" "),
                _c("li", [
                  _vm._v(
                    "\n          Для добавления в список информационной базы — приложения\n          сервиса 1С:БухОбслуживание\n          "
                  ),
                  _c("ul", [
                    _c("li", [
                      _vm._v(
                        "\n              Откройте ваше приложение в браузере (так, как вы обычно\n              работаете с ним в сервисе)\n            "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _vm._v(
                        "\n              Скопируйте интернет-адрес приложения в буфер обмена\n              "
                      ),
                      _c("img", {
                        attrs: {
                          src: require("../../assets/images/reference/1/pic-1.png"),
                          alt: "",
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _vm._v(
                        "\n              В окне запуска 1С:Предприятия нажмите кнопку\n              «Добавить» и выберите «Добавление в список\n              существующей информационной базы». Нажмите\n              «Далее».\n              "
                      ),
                      _c("img", {
                        attrs: {
                          src: require("../../assets/images/reference/1/pic-2.png"),
                          alt: "",
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _vm._v(
                        "\n              В поле «Наименование информационной базы» укажите\n              понятное Вам название добавляемого приложения.\n            "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _vm._v(
                        "\n              Выберите тип расположения информационной базы «На\n              веб-сервере» и вставьте в поле «Адрес информационной\n              базы» ранее скопированный Вами из браузера адрес\n              приложения. Удалите окончание «/ru_RU/»\n            "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _vm._v(
                        "\n              Нажмите «Далее», «Готово».\n              Информационная база добавлена в список, Вы можете выполнить ее\n              запуск и начать работу\n            "
                      ),
                    ]),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("h3", [
                _vm._v("Автоматическая настройка списка информационных баз"),
              ]),
              _vm._v(" "),
              _c("ul", [
                _c("li", [
                  _vm._v(
                    "\n          Сервис 1С:БухОбслуживание предоставляет механизм, выполняющий\n          автоматическую регистрацию и обновление списка доступных\n          информационных баз на компьютере пользователя.\n        "
                  ),
                ]),
                _vm._v(" "),
                _c("li", [
                  _vm._v(
                    "\n          Порядок подключения к службе списка информационных баз\n          "
                  ),
                  _c("ul", [
                    _c("li", [
                      _vm._v(
                        "\n              В окне списка информационных баз нажмите кнопку\n              «Настройка».\n            "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _vm._v(
                        "\n              В открывшемся окне настроек установите флаг «Отображать в\n              виде дерева».\n            "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _vm._v(
                        "\n              В разделе «Адреса интернет-сервисов и списки общих\n              информационных баз» нажмите кнопку «Добавить».\n            "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _vm._v(
                        "\n              В поле «Интернет-сервис» введите\n              https://1cbo.1c.ru/a/wcib/hs и нажмите «ОК» в\n              открытых окнах настроек.\n            "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _vm._v(
                        "\n              В случае возникновения проблем с вводом логина и пароля\n              попробуйте изменить ссылку на:\n              https://1cbo.1c.ru/a/wcib-noopenid/hs\n              "
                      ),
                      _c("img", {
                        attrs: {
                          src: require("../../assets/images/reference/1/pic-4.png"),
                          alt: "",
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _vm._v(
                        "\n              Через некоторое время — обычно две-три секунды —\n              система запросит имя пользователя и пароль для подключения к\n              веб-серверу. Введите те учетные данные, с которыми Вы входите в\n              приложения 1С:БухОбслуживание. После успешной авторизации список\n              информационных баз будет содержать доступные Вам информационные\n              базы сервиса 1С:БухОбслуживание\n              "
                      ),
                      _c("img", {
                        attrs: {
                          src: require("../../assets/images/reference/1/pic-5.png"),
                          alt: "",
                        },
                      }),
                    ]),
                  ]),
                ]),
              ]),
            ])
          : _vm.$route.params.id == 2
          ? _c("div", { key: "2" }, [
              _c("h1", [
                _vm._v(
                  "\n        Инструкция по подключению сервиса 1C-Отчетность в приложении\n        «1С: Предприниматель 2015»\n      "
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        Подключение к сервису «1С-Отчетность» доступно при наличии\n        оплаченного периода использования программы «1С: Предприниматель\n        2015» в сервисе 1С: БухОбслуживание на 6 или 12 месяцев.\n      "
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        Перед стартом подключения к сервису 1С-Отчетность зайдите в ваш Личный\n        кабинет на сайте https://1cbo.ru и убедитесь, что приложение 1С:\n        Предприниматель оплачено.\n      "
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        С использованием сервиса 1С-Отчетность доступна отправка через\n        Интернет отчетов в ФНС, ПФР, ФСС, Росстат, Росалкогольрегулирование.\n      "
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _c("strong", [
                  _vm._v(
                    "Подключение к сервису 1С-Отчетность из приложения 1С:\n          Предприниматель 2015 включает 3 шага"
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("p", [
                _c("strong", [_vm._v("Шаг 1.")]),
                _vm._v(
                  " Заявление на подключение к сервису\n        «1С-Отчетность».\n      "
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _c("strong", [_vm._v("Шаг 2.")]),
                _vm._v(
                  " Запрос на подтверждение заявления о\n        подключении к сервису «1С-Отчетность», отправка скан-копий\n        документов, подтверждающих полномочия руководителя организации/ИП.\n      "
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _c("strong", [_vm._v("Шаг 3.")]),
                _vm._v(
                  " Одобрение заявления на подключение к\n        «1С-Отчетости» спецоператором связи «Калуга\n        Астрал». Установка сертификата, автоматическая настройка\n        документооборота.\n      "
                ),
              ]),
              _vm._v(" "),
              _c("h3", [
                _vm._v("\n        Шаг 1 "),
                _c("br"),
                _vm._v(
                  "\n        Подготовить заявление на подключение к сервису 1С-Отчетность\n      "
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v("Шаг 1 включает "),
                _c("strong", [_vm._v("три действия")]),
              ]),
              _vm._v(" "),
              _c("ol", [
                _c("li", [
                  _vm._v(
                    "\n          Подготовить в программе 1С: Предприниматель заявление на подключение\n          к сервису электронного документооборота с контролирующими органами\n          — 1С-Отчетность.\n        "
                  ),
                ]),
                _vm._v(" "),
                _c("li", [
                  _vm._v("Выбор и установка программы-криптопровайдера;"),
                ]),
                _vm._v(" "),
                _c("li", [_vm._v("Настройка электронной подписи (ЭП).")]),
              ]),
              _vm._v(" "),
              _c("p", [
                _c("strong", [_vm._v("Действие 1. Оформление заявления")]),
                _c("br"),
                _vm._v(
                  "\n        В форме элемента справочника «Организации» выберите пункт\n        «Подключение к 1С-Отчетности».\n        "
                ),
                _c("img", {
                  attrs: {
                    src: require("../../assets/images/reference/2/pic-1.png"),
                    alt: "",
                  },
                }),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        Открывшийся Помощник поможет заполнить и отправить заявление на\n        подключение. Обратите внимание: оформлять заявление на подключение\n        рекомендуется именно с того компьютера, с которого в будущем\n        планируется осуществлять документооборот.\n      "
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        В случае работы через браузер потребуется установить внешнюю\n        компоненту для работы с криптографией.\n      "
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        Для установки компоненты необходимо в открывшемся окне нажать кнопку\n        Далее. "
                ),
                _c("img", {
                  attrs: {
                    src: require("../../assets/images/reference/2/pic-2.png"),
                    alt: "",
                  },
                }),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        Убедитесь в том, что внешняя компонента установлена, о чем будет\n        свидетельствовать соответствующий диалог, в котором нужно нажать\n        кнопку ОК.\n        "
                ),
                _c("img", {
                  attrs: {
                    src: require("../../assets/images/reference/2/pic-3.png"),
                    alt: "",
                  },
                }),
              ]),
              _vm._v(" "),
              _c("p", [
                _c("strong", [
                  _vm._v(
                    "Действие 2 Выбор и установка программы криптопровайдера"
                  ),
                ]),
                _c("br"),
                _vm._v(
                  "\n        Для работы с сервисом «1С-Отчетность» требуется программа\n        для защиты информации — криптопровайдер.\n      "
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        Помощник проверяет наличие установленных на компьютере программ\n        — криптопровайдеров. Если не установлен ни один криптопровайдер,\n        то Помощник подключения предложит скачать с сайта производителя и\n        установить такую программу. В настоящее время программой\n        поддерживаются криптопровайдеры ViPNet CSP (ОАО\n        «ИнфотеКС») и CryptoPro CSP («КРИПТО-ПРО»).\n      "
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        С условиями распространения средств криптографической защиты\n        информации можно ознакомиться на сайтах их разработчиков, ссылки на\n        которые приведены на форме.\n        "
                ),
                _c("img", {
                  attrs: {
                    src: require("../../assets/images/reference/2/pic-4.png"),
                    alt: "",
                  },
                }),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        Для завершения установки программы-криптопровайдера компьютер\n        потребуется перезагрузить.\n      "
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        В случае если происходит закрытие Мастера (из-за установки\n        криптопровайдера и перезагрузки компьютера), то при последующем\n        запуске программы помощник откроется на этом же шаге автоматически.\n        "
                ),
                _c("br"),
                _vm._v(
                  "\n        После установки криптопровайдера второй шаг будет выглядеть так:\n        "
                ),
                _c("img", {
                  attrs: {
                    src: require("../../assets/images/reference/2/pic-5.png"),
                    alt: "",
                  },
                }),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        Если установлено два криптопровайдера, то будет выдано предупреждение\n        о необходимости удалить один из них.\n      "
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _c("strong", [
                  _vm._v(
                    "Возврат к Действию 1 «Оформление заявления на подключение к\n          сервису 1С-Отчетность»"
                  ),
                ]),
                _c("br"),
                _vm._v(
                  "\n        На следующем шаге необходимо ознакомиться с текстом соглашения о\n        присоединении к регламенту удостоверяющего центра — Калуги\n        Астрал. Для продолжения нужно подтвердить принятие условий соглашения.\n        "
                ),
                _c("img", {
                  attrs: {
                    src: require("../../assets/images/reference/2/pic-6.png"),
                    alt: "",
                  },
                }),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        Регистрационный номер программы «1С: Предприниматель 2015»\n        будет сформирован автоматически.\n      "
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        Далее необходимо выбрать организацию, которую планируется подключить,\n        сотрудника — владельца сертификата ключа и перечислить\n        контролирующие органы в которые планируется сдавать отчетность. При\n        установке флажка «Росстат» автоматически установится\n        флажок «ФНС» (подключение к Росстату невозможно без\n        подключения к ФНС).\n        "
                ),
                _c("img", {
                  attrs: {
                    src: require("../../assets/images/reference/2/pic-7.png"),
                    alt: "",
                  },
                }),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        Далее требуется убедиться в корректности заполнения сведений об\n        организации. Основные реквизиты организации можно отредактировать в\n        справочнике, открыв его по ссылке с названием организации.\n      "
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        Контактную информацию можно править непосредственно в форме мастера.\n        Здесь указываются и контактные телефоны, по которым представитель\n        специализированного оператора связи свяжется с пользователем для\n        встречи и оформления документов по подключению.\n        "
                ),
                _c("img", {
                  attrs: {
                    src: require("../../assets/images/reference/2/pic-8.png"),
                    alt: "",
                  },
                }),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        На следующем шаге необходимо проверить сведения о лице, на которое\n        будет издан сертификат квалифицированной цифровой подписи, необходимый\n        для осуществления документооборота. Обычно в качестве такого\n        сотрудника выступает руководитель организации. При выборе сотрудника\n        программа заполнит поля со сведениями о нем. Эти сведения необходимо\n        тщательно проверить и при необходимости заполнить (отредактировать по\n        ссылке) недостающие обязательные поля.\n        "
                ),
                _c("img", {
                  attrs: {
                    src: require("../../assets/images/reference/2/pic-9.png"),
                    alt: "",
                  },
                }),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        Далее требуется проверить и при необходимости отредактировать коды\n        инспекций ФНС, отделения ПФР, территориального органа Росстата. В\n        случае, если организация представляет отчетность не в один налоговый\n        орган, указывается несколько кодов ИФНС.\n        "
                ),
                _c("img", {
                  attrs: {
                    src: require("../../assets/images/reference/2/pic-10.png"),
                    alt: "",
                  },
                }),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        Далее будет предложено еще раз проверить правильность всех сведений,\n        указанных в заявлении.\n        "
                ),
                _c("img", {
                  attrs: {
                    src: require("../../assets/images/reference/2/pic-11.png"),
                    alt: "",
                  },
                }),
              ]),
              _vm._v(" "),
              _c("p", [
                _c("strong", [
                  _vm._v("Действие 3. Генерация ключа электронной подписи"),
                ]),
                _c("br"),
                _vm._v(
                  "\n        Ключ электронной подписи будет сгенерирован с помощью программы\n        — криптопровайдера.\n        "
                ),
                _c("img", {
                  attrs: {
                    src: require("../../assets/images/reference/2/pic-12.png"),
                    alt: "",
                  },
                }),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        Ключ, в зависимости от криптопровайдера, может быть размещен на\n        внешнем устройстве или на компьютере пользователя.\n        "
                ),
                _c("img", {
                  attrs: {
                    src: require("../../assets/images/reference/2/pic-13.png"),
                    alt: "",
                  },
                }),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        После выбора размещения электронного ключа будет предложено задать\n        пароль.\n        "
                ),
                _c("img", {
                  attrs: {
                    src: require("../../assets/images/reference/2/pic-14.png"),
                    alt: "",
                  },
                }),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        При создании ключа для работы генератора случайных чисел потребуется в\n        произвольном порядке нажимать клавиши на клавиатуре или двигать мышью.\n        "
                ),
                _c("img", {
                  attrs: {
                    src: require("../../assets/images/reference/2/pic-15.png"),
                    alt: "",
                  },
                }),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        По завершении создания ключа потребуется еще раз ввести пароль, и\n        заявление будет передано через Интернет спецоператору связи.\n        "
                ),
                _c("img", {
                  attrs: {
                    src: require("../../assets/images/reference/2/pic-16.png"),
                    alt: "",
                  },
                }),
                _vm._v(" "),
                _c("img", {
                  attrs: {
                    src: require("../../assets/images/reference/2/pic-17.png"),
                    alt: "",
                  },
                }),
              ]),
              _vm._v(" "),
              _c("h3", [
                _vm._v("\n        Шаг 2"),
                _c("br"),
                _vm._v(
                  "\n        Направить запрос на подтверждение заявления о подключении к сервису\n        1С-Отчетность.\n      "
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        Запрос на подтверждение заявления о подключении к сервису\n        1С-Отчетность направляется в фирму «1С», как представителя\n        спецоператора связи, на адрес bo@1c. ru и включает: "
                ),
                _c("br"),
                _vm._v(
                  "\n        1. Письмо с темой «1С-Отчетность для 1С: Предприниматля\n        2015» и текстом по шаблону:"
                ),
                _c("br"),
                _vm._v(
                  "\n        «Прошу подтвердить заявление о подключении\n        ¬¬___________________(ИНН/КПП)\n      "
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        К защищенному электронному документообороту. Скан-копии документов,\n        подтверждающих полномочия руководителя ______________________________\n        в приложении».\n      "
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        2. Скан-копии документов, подтверждающих полномочия руководителя\n        организации/ИП во вложении к письму."
                ),
                _c("br"),
                _vm._v("\n        a) для юридического лица:\n      "),
              ]),
              _vm._v(" "),
              _c("ul", [
                _c("li", [
                  _vm._v(
                    "\n          копия свидетельства о постановке на учет организации-клиента, с\n          указанием ИНН/КПП;\n        "
                  ),
                ]),
                _vm._v(" "),
                _c("li", [
                  _vm._v(
                    "\n          копия общегражданского паспорта руководителя (достаточно разворота с\n          ФИО);\n        "
                  ),
                ]),
                _vm._v(" "),
                _c("li", [
                  _vm._v(
                    "\n          заверенная копия приказа о назначении руководителя на должность (или\n          другой документ, подтверждающий, что этот человек —\n          руководитель)\n        "
                  ),
                ]),
                _vm._v(" "),
                _c("li", [
                  _vm._v(
                    "\n          копия Соглашения об ЭДО с ОПФР (в случае, если в заявке указано\n          такое направление сдачи отчетности).\n        "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("p", [_vm._v("b) для индивидуального предпринимателя:")]),
              _vm._v(" "),
              _c("ul", [
                _c("li", [
                  _vm._v(
                    "копия свидетельства о постановке на учет с указанием ИНН;"
                  ),
                ]),
                _vm._v(" "),
                _c("li", [
                  _vm._v(
                    "\n          копия общегражданского паспорта ИП (достаточно разворота с ФИО);\n        "
                  ),
                ]),
                _vm._v(" "),
                _c("li", [
                  _vm._v(
                    "\n          копия Соглашения об ЭДО с ОПФР (в случае, если в заявке указано\n          такое направление сдачи отчетности).\n        "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        После получения запроса сотрудник фирмы «1С» свяжется с\n        пользователем, используя данные, указанные в секции «Контактная\n        информация» заявления, для решения организационных вопросов по\n        подключению.\n      "
                ),
              ]),
              _vm._v(" "),
              _c("h3", [
                _vm._v("\n        Шаг 3. "),
                _c("br"),
                _vm._v(
                  "\n        Одобрение заявления на подключение к «1С-Отчетости»\n        спецоператором связи «Калуга Астрал». "
                ),
                _c("br"),
                _vm._v(
                  "\n        Установка сертификата, автоматическая настройка документооборота.\n      "
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        Для продолжения работы требуется дождаться одобрения заявления на\n        подключение к сервису 1С-Отчетность от спецоператора связи\n        Калуга-Астрал.\n      "
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        Увидеть статус заявления можно так: подсистема\n        Отчеты/Регламентированные отчеты/Настройки, далее «Список\n        заявлений».\n      "
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Из Списка заявлений можно перейти к конкретному заявлению."
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        Для проверки статуса заявления можно воспользоваться кнопкой\n        «Обновить статусы заявлений». Программа автоматически\n        предложит обновить статус заявления с сервера спецоператора, если с\n        момента отправки прошло больше суток.\n        "
                ),
                _c("img", {
                  attrs: {
                    src: require("../../assets/images/reference/2/pic-18.png"),
                    alt: "",
                  },
                }),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        В процессе подключения удостоверяющим центром будет издан сертификат\n        ключа электронной подписи на имя сотрудника, указанного при оформлении\n        заявки, и на сервере спецоператора для нового абонента будет создана\n        учетная запись, которая необходима для осуществления документооборота.\n      "
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        В случае если при подключении к сервису «1С-Отчетность»\n        возникла ошибка, то причину можно посмотреть в Помощнике.\n        "
                ),
                _c("img", {
                  attrs: {
                    src: require("../../assets/images/reference/2/pic-19.png"),
                    alt: "",
                  },
                }),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        Если заявление одобрено, можно приступать к завершению настройки\n        электронного документооборота с контролирующими органами, при этом на\n        экране будет показана следующая форма.\n        "
                ),
                _c("img", {
                  attrs: {
                    src: require("../../assets/images/reference/2/pic-20.png"),
                    alt: "",
                  },
                }),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        Потребуется еще раз ввести пароль.\n        "
                ),
                _c("img", {
                  attrs: {
                    src: require("../../assets/images/reference/2/pic-21.png"),
                    alt: "",
                  },
                }),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        Далее будет предложено выбрать пользователей, которые смогут\n        использовать 1С-Отчетность по организации. Если в системе только один\n        пользователь этот шаг будет пропущен.\n        "
                ),
                _c("img", {
                  attrs: {
                    src: require("../../assets/images/reference/2/pic-22.png"),
                    alt: "",
                  },
                }),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        И на заключительном шаге будет показан следующий диалог.\n        "
                ),
                _c("img", {
                  attrs: {
                    src: require("../../assets/images/reference/2/pic-23.png"),
                    alt: "",
                  },
                }),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        После того как все необходимые настройки произведены, пользователь\n        может незамедлительно приступить к документообороту (отправке\n        отчетности и обмену прочими документами).\n      "
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        В будущем, при изменении настроек документооборота (например, смене\n        сертификатов в контролирующем органе), эти изменения будут\n        автоматически отражаться в информационной базе — пользователю не\n        придется отслеживать и отражать изменения в настройках программы\n        самостоятельно.\n        "
                ),
                _c("img", {
                  attrs: {
                    src: require("../../assets/images/reference/2/pic-24.png"),
                    alt: "",
                  },
                }),
              ]),
            ])
          : _vm.$route.params.id == 3
          ? _c("div", { key: "3" }, [
              _c("h1", [_vm._v("Работа через тонкий клиент 1С")]),
              _vm._v(" "),
              _c("h3", [_vm._v("Назначение Тонкого клиента 1С:Предприятие")]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        Тонкий клиент 1С:Предприятие (ТК) — это специальная версия\n        программ 1С:Предприятие, позволяющая работать с информационными\n        базами, размещенными в облачных сервисах или на сервере\n        1С:Предприятие, в частности — с информационными базами в сервисе\n        1С:БухОбслуживание.\n      "
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        Для работы в приложениях сервиса 1С:БухОбслуживание рекомендуется\n        использовать именно ТК в связи с его большей устойчивостью, скоростью\n        работы и диагностируемостью по сравнению с браузерами.\n      "
                ),
              ]),
              _vm._v(" "),
              _c("h3", [_vm._v("Системные требования")]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        Минимальные системные требования ТК соответствуют таковым для\n        платформы 1С:Предприятие 8.3 и опубликованы в составе документации по\n        платформе 1С:Предприятие:\n      "
                ),
              ]),
              _vm._v(" "),
              _c("ul", [
                _c("li", [
                  _vm._v(
                    "\n          Операционная система Windows XP Service Pack 2, Windows Server 2003\n          и выше, Fedora 17, Mint 12, Ubuntu 12.04 LTS, Alt Linux СПТ 6.0\n        "
                  ),
                ]),
                _vm._v(" "),
                _c("li", [
                  _vm._v("Процессор Intel Pentium/Celeron 1800 МГц и выше"),
                ]),
                _vm._v(" "),
                _c("li", [_vm._v("Оперативная память 1 Гбайт и выше")]),
                _vm._v(" "),
                _c("li", [
                  _vm._v(
                    "Жесткий диск (при установке используется около 70 Мбайт)"
                  ),
                ]),
                _vm._v(" "),
                _c("li", [_vm._v("SVGA-дисплей")]),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        Рекомендуемые параметры оборудования, обеспечивающие комфортную работу\n        в Тонком клиенте 1С:Предприятие, следующие:\n      "
                ),
              ]),
              _vm._v(" "),
              _c("ul", [
                _c("li", [
                  _vm._v(
                    "\n          Операционная система Windows 7 и выше, Windows Server 2008 и выше\n        "
                  ),
                ]),
                _vm._v(" "),
                _c("li", [
                  _vm._v("Двухъядерный процессор Intel Core2 Duo и выше"),
                ]),
                _vm._v(" "),
                _c("li", [_vm._v("Оперативная память 2 Гбайт и выше")]),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        При несоблюдении рекомендуемых параметров возможно снижение\n        быстродействия и стабильности работы ТК.\n      "
                ),
              ]),
              _vm._v(" "),
              _c("h3", [_vm._v("Вопросы лицензирования")]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        Клиентам сервиса 1С:БухОбслуживание предоставляется право\n        использования программного обеспечения «Тонкий клиент\n        1С:Предприятие» в соответствии с лицензионной политикой 1С.\n      "
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        Сервис 1С:БухОбслуживание предоставляет пользователю необходимые\n        лицензии 1С:Предприятие при отсутствии таковых у пользователя.\n        Лицензии предоставляются только для работы в приложениях сервиса\n        1С:БухОбслуживание, на любом компьютере, путем выделения запущенному\n        приложению лицензии из пула лицензий сервиса на период сеанса работы.\n      "
                ),
              ]),
              _vm._v(" "),
              _c("h3", [_vm._v("Установка ТК")]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        Установочный пакет Тонкого клиента 1С:Предприятие для Windows Вы\n        можете скачать по следующей ссылке: https://1cbo.1c.\n        ru/download/setuptc. zip. Версия ТК, расположенная по приведенной\n        ссылке, всегда совпадает с версией платформы 1С:Предприятие,\n        используемой в сервисе 1С:БухОбслуживание.\n      "
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        Для установки ТК требуются права администратора операционной системы.\n      "
                ),
              ]),
              _vm._v(" "),
              _c("p", [_vm._v("Порядок установки ТК:")]),
              _vm._v(" "),
              _c("ul", [
                _c("li", [
                  _vm._v(
                    "\n          Скачайте установочный пакет Тонкого клиента по ссылке, приведенной\n          выше. Сохраните архивный файл установочного пакета на Ваш компьютер.\n        "
                  ),
                ]),
                _vm._v(" "),
                _c("li", [
                  _vm._v(
                    "\n          Распакуйте сохраненный архив и двойным кликом запустите файл setup.\n          exe. Расширение «.exe» может не отображаться в\n          зависимости от настроек Вашего компьютера, в этом случае Вы можете\n          опознать требуемый файл по виду иконки\n          "
                  ),
                  _c("img", {
                    attrs: {
                      src: require("../../assets/images/reference/3/pic-1.png"),
                      alt: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("img", {
                    attrs: {
                      src: require("../../assets/images/reference/3/pic-2.png"),
                      alt: "",
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("li", [
                  _vm._v(
                    "\n          Следуйте рекомендациям программы установки, нажимая на кнопку\n          «Далее». Для завершения установки нажмите кнопку\n          «Готово». Какие-либо изменения параметров установки, как\n          правило, не требуются.\n          "
                  ),
                  _c("img", {
                    attrs: {
                      src: require("../../assets/images/reference/3/pic-3.png"),
                      alt: "",
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("li", [
                  _vm._v(
                    "\n          После завершения установки требуется настроить список информационных\n          баз вручную либо при помощи службы списка информационных баз\n          1С:БухОбслуживание.\n        "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("h3", [_vm._v("Обновление ТК")]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        Процесс обновления тонкого клиента 1С:Предприятие представляет собой\n        установку новой версии программы. При этом удаление старых версий не\n        обязательно, старые версии сохраняют работоспособность. В случае\n        отсутствия нужды в старых версиях они могут быть удалены обычными\n        методами без нарушения функциональности актуальных версий.\n      "
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        Для установки новых версий тонкого клиента 1С:Предприятие могут\n        применяться следующие сценарии:\n      "
                ),
              ]),
              _vm._v(" "),
              _c("ul", [
                _c("li", [_vm._v("Обновление вручную")]),
                _vm._v(" "),
                _c("li", [
                  _vm._v(
                    "Автоматическое обновление средствами платформы 1С:Предприятие"
                  ),
                ]),
                _vm._v(" "),
                _c("li", [
                  _vm._v(
                    "Автоматическое обновление средствами администрирования ОС"
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        Выбор конкретного сценария определяется прежде всего требованиями к\n        безопасности локальной информационной системы. Ключевым критерием\n        безопасности является уровень прав пользователя, в общем случае\n        представляемый как ограниченный либо административный.\n      "
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        В случае ограниченного набора прав пользователь, как правило, не имеет\n        права на установку программного обеспечения. В этом случае установка\n        программного обеспечения может проводиться пользователем с временным\n        повышением уровня прав либо операционной системой. Сценарий установки\n        должен предусматривать такую потребность."
                ),
                _c("br"),
                _vm._v(
                  "\n        Если же пользователь обладает административными привилегиями, то\n        вопрос достаточности прав для установки программного обеспечения не\n        стоит и допустимо использовать любой сценарий.\n      "
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        Обновление вручную применяется, как правило, на незначительном\n        количестве рабочих мест. При этом процесс установки программы должен\n        быть запущен с повышенными привилегиями.\n      "
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v("Обновление вручную выполняется идентично установке ТК"),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Автоматическое обновление средствами платформы 1С:Предприятие"
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        При запуске информационной базы, подключенной в режиме «На\n        веб-сервере» тонкий клиент 1С:Предприятие получает информацию о\n        требуемой версии платформы и о расположении в интернете файла\n        дистрибутива платформы, если на веб-сервере опубликован такой\n        дистрибутив. Сервис 1С:БухОбслуживание всегда публикует дистрибутив\n        актуальной версии тонкого клиента.\n      "
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        При наличии информации о расположении дистрибутива тонкий клиент\n        автоматически скачивает файл и запускает процедуру быстрой установки\n        (т. е. установка производится без запроса каких-либо параметров у\n        пользователя — используются параметры предыдущей установки),\n        после чего перезапускается уже в нужной версии.\n      "
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        Обновление происходит полностью автоматически и требует наличия у\n        пользователя права на установку программного обеспечения.\n      "
                ),
              ]),
              _vm._v(" "),
              _c("p", [_vm._v("Варианты реализации:")]),
              _vm._v(" "),
              _c("ul", [
                _c("li", [
                  _vm._v(
                    "Предоставление пользователю административных привилегий"
                  ),
                ]),
                _vm._v(" "),
                _c("li", [
                  _vm._v(
                    "\n          Включение элемента групповых политик «Всегда производить\n          установку с повышенными привилегиями» (англ. Always install\n          elevated)\n        "
                  ),
                ]),
                _vm._v(" "),
                _c("li", [
                  _vm._v(
                    "Автоматическое обновление средствами администрирования ОС"
                  ),
                ]),
                _vm._v(" "),
                _c("li", [
                  _vm._v(
                    "\n          Развертывание приложения осуществляется в среде Active Directory из\n          дистрибутива, опубликованного администратором. Процедура\n          развертывания приложения средствами групповых политик Active\n          Directory описана в документации Microsoft:\n          https://support.microsoft.com/en-us/kb/816102\n        "
                  ),
                ]),
                _vm._v(" "),
                _c("li", [
                  _vm._v(
                    "\n          Развертывание приложения рекомендуется выполнять в режиме\n          «Назначенный» для компьютеров, а не пользователей домена\n        "
                  ),
                ]),
                _vm._v(" "),
                _c("li", [
                  _vm._v(
                    "\n          В развертываемом пакете рекомендуется добавить следующие трансформы\n        "
                  ),
                ]),
                _vm._v(" "),
                _c("li", [
                  _vm._v(
                    "\n          1049.mst — обеспечивает русский язык установленного приложения\n        "
                  ),
                ]),
                _vm._v(" "),
                _c("li", [
                  _vm._v(
                    "\n          Adminstallrestart. mst — реализует предложение перезагрузить\n          компьютер для выполнения автоматической установки нужной версии\n        "
                  ),
                ]),
                _vm._v(" "),
                _c("li", [
                  _vm._v(
                    "\n          Указывать в развертываемом пакете обновляемые пакеты не требуется\n        "
                  ),
                ]),
              ]),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }